import React, {PureComponent} from "react";
import SvgSchool from "../icons/SvgSchool";
import SvgHomePin from "../icons/SvgHomePin";
import UntrackedStudentStatusComponent from "../recruitertracking/UntrackedStudentStatusComponent";
import SvgEdit from "../icons/SvgEdit";
import SvgZap from "../icons/SvgZap";

class ProfilePageTitleWidget extends PureComponent {
    render() {
        const user = this.props.user || {};
        const education = user.college || "";
        const coverPhoto = user.cover_image_url ? user.cover_image_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fcover_image%2Flinkedin-default.png?alt=media&token=0a8c871d-4809-455f-87d9-c0a2994e3642";
        const profilePic = user.profile_pic_url ? user.profile_pic_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8";
        return (
            <div className='card p-0'>
                <img src={coverPhoto} className='h-40 w-full rounded-t-[8px] object-cover'/>
                <div className='px-4 pb-4'>
                    <img src={profilePic}
                         className='h-28 w-28 rounded-full rounded-full mt-[-84px]  border-2 border-white relative'
                         style={{zIndex: 2}}/>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-neutral-900 title1 mt-0'>
                            {this.props.user.first_name} {this.props.user.last_name}
                        </p>
                        <div className='flex flex-row items-center gap-3'>
                            {/* {this.props.recruiter ? <UntrackedStudentStatusComponent student={this.props.user */}
                            
                            {this.props.studentProfilePage || this.props.recruiter ? null :
                                <div
                                    onClick={this.props.openEditProfileModal}
                                    className='primary cursor-pointer hover:text-indigo-800 body2-bold flex flex-row items-center gap-2'>
                                    <SvgEdit className={'w-4 h-4'}/> Edit
                                </div>
                            }
                        </div>
                    </div>
                    <p className='ml-1'>
                        {user.headline}
                    </p>
                    <div className='flex flex-row items center mt-1'>
                        {user.city ?
                            <div className='flex flex-row items-center body2 base-black-50 mr-5'>
                                <SvgHomePin width={20} height={20}/>
                                <p className='ml-1'>
                                    {user.city} {user.state && ", " + user.state}
                                </p>
                            </div>
                            : null}
                        {!!education ?
                            <div className='flex flex-row items-center body2 base-black-50 mr-5'>
                                <SvgSchool width={20} height={20}/>
                                <p className='ml-1'>
                                    {education}
                                </p>
                            </div>
                            : null}
                        {this.props.recruiter && !!user.gpa ?
                            <div className='flex flex-row items-center body2 base-black-50'>
                                <SvgZap width={20} height={20}/>
                                <p className='ml-1'>
                                    GPA: {user.gpa}
                                </p>
                            </div>
                            : null}
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfilePageTitleWidget
