import React, {PureComponent} from "react";
import {connect} from "react-redux";
import ProfilePageTitleWidget from "../profilepage/ProfilePageTitleWidget";
import ProfilePageAboutWidget from "../profilepage/ProfilePageAboutWidget";
import ProfilePageExperienceWidget from "../profilepage/ProfilePageExperienceWidget";
import ProfilePageEducationWidget from "../profilepage/ProfilePageEducationWidget";
import ProfilePageSeekingRoleWidget from "../profilepage/ProfilePageSeekingRoleWidget";
import ProfilePageClubsWidget from "../profilepage/ProfilePageClubsWidget";
import {editUser} from "../../api/student/users";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import PageLTRWrapper from "../wrappers/PageLTRWrapper";
import PageRightWrapper from "../wrappers/PageRightWrapper";
import PageLeftWrapper from "../wrappers/PageLeftWrapper";
import EditProfileModal from "../atoms/modals/EditProfileModal";
import EditExperienceModal from "../atoms/modals/EditExperienceModal";
import EditEducationModal from "../atoms/modals/EditEducationModal";
import EditProfileOtherModal from "../atoms/modals/EditProfileOtherModal";
import PersonalizationModal from "../atoms/modals/PersonalizationModal";
import ChooseClubsModal from "../atoms/modals/ChooseClubsModal";
import {logout} from "../../api/authentication";
import SvgLogout from "../icons/SvgLogout";
import {Button} from "../atoms/Button";
import SvgMessage from "../icons/SvgMessage";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

class ProfilePage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            club: {},
            editProfileModalOpen: false,
            editExperienceModalOpen: false,
            editEducationModalOpen: false,
            addingNew: false,
            editOtherModalOpen: false,
            chooseClubsModalOpen: false,
            personalizationModalOpen: false,
            i: null,
        };
    }

    editSeekingRole = async (value) => {
        const newUser = {...this.props.user};
        newUser.seeking_role = value;
        this.props.setUser(newUser);
        await editUser(this.props.authUser, {
            seeking_role: value
        })
    }

    removeExperience = async (i) => {
        const newUser = {...this.props.user};
        newUser.experiences.splice(i, 1);

        this.props.setUser(newUser)
        await editUser(this.props.authUser, {experiences: newUser.experiences});
    }

    removeEducation = async (i) => {
        const newUser = {...this.props.user};
        newUser.education.splice(i, 1);

        this.props.setUser(newUser)
        await editUser(this.props.authUser, {education: newUser.education});
    }

    openEditProfileModal = () => {
        this.setState({editProfileModalOpen: true})
    }

    closeEditProfileModal = () => {
        this.setState({editProfileModalOpen: false})
    }

    openEditExperienceModal = (addingNew, i = null) => {
        this.setState({addingNew: addingNew === 'add', i, editExperienceModalOpen: true})
    }

    closeEditExperienceModal = () => {
        this.setState({editExperienceModalOpen: false})
    }

    openEditEducationModal = (addingNew, i = null) => {
        this.setState({addingNew: addingNew === 'add', i, editEducationModalOpen: true})
    }

    closeEditEducationModal = () => {
        this.setState({editEducationModalOpen: false})
    }

    openEditOtherModal = () => {
        this.setState({editOtherModalOpen: true})
    }

    closeEditOtherModal = () => {
        this.setState({editOtherModalOpen: false})
    }

    togglePersonalizationModal = () => {
        this.setState({personalizationModalOpen: !this.state.personalizationModalOpen})
    }

    componentDidMount() {
        window.analytics.page("My Profile Viewed");
    }

    render() {
        const user = this.props.user || {};
        const experiences = user.experiences || [];
        return (
            <PageContentWrapper>
                <PageLTRWrapper>
                    <PageLeftWrapper className={'overflow-y-scroll hide-scrollbar pt-5'}>
                        <ProfilePageTitleWidget user={user} openEditProfileModal={this.openEditProfileModal}/>
                        <ProfilePageAboutWidget user={user} openEditOtherModal={this.openEditOtherModal}/>
                        <ProfilePageExperienceWidget experiences={experiences} user={this.props.user}
                                                     openEditExperienceModal={this.openEditExperienceModal}
                                                     removeExperience={this.removeExperience}/>
                        <ProfilePageEducationWidget education={user.education} user={this.props.user}
                                                    removeEducation={this.removeEducation}
                                                    openEditEducationModal={this.openEditEducationModal}/>
                    </PageLeftWrapper>
                    <PageRightWrapper open>
                        <div className='flex flex-col flex-1 overflow-y-scroll pt-5'>
                            <ProfilePageSeekingRoleWidget user={user} editSeekingRole={this.editSeekingRole}
                                                          togglePersonalizationModal={this.togglePersonalizationModal}
                                                          />
                            <ProfilePageClubsWidget user={user} openChooseClubsModal={() => this.setState({chooseClubsModalOpen: true})}/>
                            <a href={'mailto:hello@recruitu.io'} target={'_blank'} className='w-full mb-2'>
                                <Button
                                    className='w-full'
                                    variant={'secondary'}
                                    icon={SvgMessage}
                                >
                                    <p>
                                        Contact support
                                    </p>
                                </Button>
                            </a>
                            <Button
                                variant={'secondary'}
                                icon={SvgLogout}
                                onClick={() => logout()}>
                                <p>
                                    Log out
                                </p>
                            </Button>
                            {/* <JobPageMatchWidget listing={listing}/> */}
                            {/*<JobPageCTAWidget listing={{company: {name: "Morgan Stanley", sector: "Financial Services"}}}/>*/}
                        </div>
                    </PageRightWrapper>
                </PageLTRWrapper>
                <EditProfileModal
                    isOpen={this.state.editProfileModalOpen}
                    closeModal={this.closeEditProfileModal}/>
                <EditExperienceModal
                    addingNew={this.state.addingNew}
                    isOpen={this.state.editExperienceModalOpen}
                    closeModal={this.closeEditExperienceModal}
                    i={this.state.i}/>
                <EditEducationModal
                    addingNew={this.state.addingNew}
                    isOpen={this.state.editEducationModalOpen}
                    closeModal={this.closeEditEducationModal}
                    i={this.state.i}/>
                <EditProfileOtherModal
                    isOpen={this.state.editOtherModalOpen}
                    closeModal={this.closeEditOtherModal}
                />
                <PersonalizationModal isOpen={this.state.personalizationModalOpen}
                                      closeModal={this.togglePersonalizationModal}/>
                <ChooseClubsModal isOpen={this.state.chooseClubsModalOpen} close={() => {
                    this.setState({chooseClubsModalOpen: false});
                }}/>
            </PageContentWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
