const initialState = {
    user: {},
    authUser: {},
    applications: {},
    trackers: {},
    networks: {},
    companyNetworks: {},
    recruiterNetworks: {}, // going to move
    createJobModalOpen: false,
    createEventModalOpen: false,
    studentPeekOpen: false,
    clubAdminPortalOpen: false,
    studentPeekStudent: null,
    clubPeekOpen: false,
    clubPeekClub: null,
    path: "",
    domain: "",
    tier: null,
    imageViewerModalOpen: false,
    imageViewerImages: [],
    candidates: {},
    currentClubs: [],
    answersIds: [],
    constants: {},
    clubAdminClubId: null,
    isPersonalizationModalOpen: false,
    isPreApplyModalOpen: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                user: action.user
            }
        case "SET_PATH":
            return {
                ...state,
                path: action.path
            }
        case "SET_DOMAIN":
            return {
                ...state,
                domain: action.domain
            }
        case "SET_CONSTANTS":
            return {
                ...state,
                constants: action.constants
            }
        case "SET_TIER":
            return {
                ...state,
                tier: action.tier
            }
        case "SET_APPLICATIONS":
            const formattedApplications = {};
            const applications = action.applications;
            for (const application of applications) {
                formattedApplications[application.job_id] = application
            }
            return {
                ...state,
                applications: formattedApplications
            }
        case "UPDATE_APPLICATION":
            const newApplication = action.application;
            const currentApplications = {...state.applications};

            const user = {...state.user};
            if (newApplication.job_id in currentApplications && newApplication.personal_status === "Applied") {
                user.nux.apply_job = true;
            }

            currentApplications[newApplication.job_id] = newApplication;
            return {
                ...state,
                applications: {...currentApplications},
                user,
            }
        case "DELETE_APPLICATION":
            const curApplications = {...state.applications};
            delete curApplications[action.jobId];
            return {
                ...state,
                applications: curApplications
            }
        case "SET_TRACKERS":
            const formattedTrackers = {};
            const trackers = action.trackers;
            for (const tracker of trackers) {
                if (!!tracker.company_id) formattedTrackers[tracker.company_id] = tracker
                else if (!!tracker.event_id) formattedTrackers[tracker.event_id] = tracker
            }
            return {
                ...state,
                trackers: formattedTrackers
            }
        case "UPDATE_TRACKER":
            const newTracker = action.tracker;
            const currentTrackers = {...state.trackers};

            const newUser = {...state.user};
            if (newTracker.type === "company") {
                currentTrackers[newTracker.company_id] = newTracker;
                newUser.nux.add_company = true;
            }
            if (newTracker.type === "event") currentTrackers[newTracker.event_id] = newTracker;
            return {
                ...state,
                trackers: {...currentTrackers},
                user: newUser
            }
        case "DELETE_TRACKER":
            const curTrackers = {...state.trackers};
            delete curTrackers[action.jobId];
            return {
                ...state,
                trackers: curTrackers
            }
        case "SET_NETWORKS":
            const formattedNetworks = {};
            const networks = action.networks;
            for (const net of networks) {
                formattedNetworks[net.person_id] = net
            }
            return {
                ...state,
                networks: formattedNetworks
            }
        case "SET_COMPANY_NETWORKS":
            return {
                ...state,
                companyNetworks: action.companyNetworks
            }
        case "UPDATE_NETWORK":
            const newNetwork = action.network;
            const currentNetworks = {...state.networks};

            const updatedUser = {...state.user};
            if (newNetwork.person_id in currentNetworks) {
                updatedUser.nux.update_contact = true;
            } else {
                updatedUser.nux.add_contact = true;
            }

            currentNetworks[newNetwork.person_id] = newNetwork;

            // if (newNetwork.company_id in state.companyNetworks) {
            //     console.log("YESSS", state.companyNetworks, newNetwork)
            //     state.companyNetworks[newNetwork.company_id].networks.push(newNetwork.person_id)
            // } else {
            //     state.companyNetworks.other.networks.push(newNetwork.person_id)
            // }
            return {
                ...state,
                networks: {...currentNetworks},
                user: updatedUser,
            }
        case "DELETE_NETWORK":
            const curNetworks = {...state.networks};
            delete curNetworks[action.personId];
            return {
                ...state,
                networks: {...curNetworks}
            }
        case "SET_RECRUITER_NETWORKS":
            const formattedRecruiterNetworks = {};
            const recruiterNetworks = action.recruiterNetworks;
            for (const net of recruiterNetworks) {
                formattedRecruiterNetworks[net.candidate_id] = net;
            }
            return {
                ...state,
                recruiterNetworks: formattedRecruiterNetworks
            }
        case "UPDATE_RECRUITER_NETWORK":
            const newRecruiterNetwork = action.recruiterNetwork;
            const currentRecruiterNetworks = {...state.recruiterNetworks};
            currentRecruiterNetworks[newRecruiterNetwork.candidate_id] = newRecruiterNetwork;
            return {
                ...state,
                recruiterNetworks: {...currentRecruiterNetworks}
            }
        case "DELETE_RECRUITER_NETWORK":
            const curRecruiterNetworks = {...state.recruiterNetworks};
            delete curRecruiterNetworks[action.personId];
            return {
                ...state,
                recruiterNetworks: {...curRecruiterNetworks}
            }
        case "SET_CANDIDATES":
            const candidates = {...state.candidates};
            for (const candidate of action.candidates) {
                if (!(candidate.username in candidates)) {
                    candidates[candidate.username] = candidate;
                }
            }
            return {
                ...state,
                candidates: candidates
            }
        case "UPDATE_CANDIDATE":
            const newCandidate = action.candidate;
            const currentCandidates = {...state.candidates};
            currentCandidates[newCandidate.username] = newCandidate;

            return {
                ...state,
                candidates: {...currentCandidates}
            }
        case "TOGGLE_CREATE_JOB_MODAL":
            return {
                ...state,
                createJobModalOpen: !state.createJobModalOpen,
                currentClubs: action.clubs || []
            }
        case "TOGGLE_CREATE_EVENT_MODAL":
            return {
                ...state,
                createEventModalOpen: !state.createEventModalOpen,
                currentClubs: action.clubs || []
            }
        case "TOGGLE_STUDENT_PEEK":
            return {
                ...state,
                studentPeekOpen: !state.studentPeekOpen
            }
        case "CLOSE_STUDENT_PEEK":
            return {
                ...state,
                studentPeekOpen: false
            }
        case "SET_STUDENT_PEEK_STUDENT":
            return {
                ...state,
                studentPeekStudent: action.user
            }
        case "CLOSE_CLUB_PEEK":
            return {
                ...state,
                clubPeekOpen: false,
            }
        case "TOGGLE_CLUB_PEEK":
            return {
                ...state,
                clubPeekOpen: !state.clubPeekOpen
            }
        case "TOGGLE_CLUB_ADMIN_PORTAL":
            return {
                ...state,
                clubAdminPortalOpen: !state.clubAdminPortalOpen
            }
        case "SET_CLUB_PEEK_CLUB":
            return {
                ...state,
                clubPeekClub: action.user
            }
        case "SET_CLUB_ADMIN_CLUB_ID":
            return {
                ...state,
                clubAdminClubId: action.clubAdminClubId
            }
        case "SET_IMAGE_VIEWER_MODAL_OPEN":
            return {
                ...state,
                imageViewerModalOpen: true,
                imageViewerImages: action.images
            }
        case "SET_IMAGE_VIEWER_MODAL_CLOSED":
            return {
                ...state,
                imageViewerModalOpen: false
            }
        case "SET_AUTH_USER":
            return {
                ...state,
                authUser: action.user
            }
        case "CLEAR_USER":
            return {...initialState}
        case "SET_ANSWER_IDS":
            return {
                ...state,
                answerIds: action.answerIds
            }
        case "SET_PERSONALIZATION_MODAL_OPEN":
            return {
                ...state,
                isPersonalizationModalOpen: true,
            };
        case "SET_PERSONALIZATION_MODAL_CLOSED":
            return {
                ...state,
                isPersonalizationModalOpen: false,
            };
        case "SET_PRE_APPLY_MODAL_OPEN":
            return {
                ...state,
                isPreApplyModalOpen: true,
            };
        case "SET_PRE_APPLY_MODAL_CLOSED":
            return {
                ...state,
                isPreApplyModalOpen: false,
            };
        default:
            return state;
    }
};
