import React, {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import ToastComponent from "./components/atoms/ToastComponent";
import ImageViewerModal from "./components/modals/ImageViewerModal";
import ClubAdminNavbar from "./components-club-admin/ClubAdminNavbar";
import ClubAdminSearchPage from "./components-club-admin/pages/ClubAdminSearchPage";
import ClubAdminClubPage from "./components-club-admin/pages/ClubAdminClubPage";
import StudentProfilePage from "./components/pages/StudentProfilePage";
import EventPage from "./components/pages/EventPage";
import JobPage from "./components/pages/JobPage";
import CompanyPage from "./components/pages/CompanyPage";
import { getClub } from "./api/student/clubs";
import ClubAdminEngagePage from "./components-club-admin/pages/ClubAdminEngagePage";
import ClubApplicationPage from "./components-club-admin/pages/ClubApplicationPage";
import ClubAdminSettingsPage from "./components-club-admin/pages/ClubAdminSettingsPage";
import ClubStudentPeek from "./components-club-admin/peeks/ClubStudentPeek";
import ClubAdminAlumniPage from "./components-club-admin/pages/ClubAdminAlumniPage";
import DashboardLoading from "./components-recruiter/atoms/DashboardLoading";
import ClubAdminApplicantsPage from "./components-club-admin/pages/ClubAdminApplicantsPage";


function mapDispatchToProps(dispatch) {
    return {
        toggleCreateJobModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_JOB_MODAL",
            })
        },
        toggleCreateEventModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_EVENT_MODAL",
            })
        },
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        closeStudentPeek: () => {
            dispatch({
                type: "CLOSE_STUDENT_PEEK",
            })
        },
        closeClubPeek: () => {
            dispatch({
                type: "CLOSE_CLUB_PEEK",
            })
        },
        toggleClubPeek: () => {
            dispatch({
                type: "TOGGLE_CLUB_PEEK",
            })
        }
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        studentPeekOpen: state.userReducer.studentPeekOpen,
        studentPeekStudent: state.userReducer.studentPeekStudent,
        clubAdminClubId: state.userReducer.clubAdminClubId
    }
}

class ClubAdminPortal extends Component {
    state = {
        club: {},
    }

    componentDidMount = () => {
        this.getClubInfo();
    }

    getClubInfo = async () => {
        this.setState({loading: true});
        const clubId = !this.props.clubAdminClubId ? this.props.user.admin[0].id : this.props.clubAdminClubId;
        const result = await getClub(this.props.authUser, clubId);

        this.setState({
            club: result?.club,
            loading: false,
        });
    }


    componentDidUpdate = (prevProps) => {
        if (this.props.clubAdminClubId !== prevProps.clubAdminClubId) {
            this.getClubInfo();
        }
    }

    render() {
        const {club} = this.state
        if (this.state.loading) {
            return (
                <DashboardLoading/>
            )
        }
        return (
            <div className='w-full h-full flex flex-col relative'>
                <ClubAdminNavbar />
                <div className='flex flex-col flex-1 mt-[60px] bg-white overflow-y-scroll hide-scrollbar'>
                    <Switch>
                        <Route exact path="/clubadmin"
                               render={(routeProps) =>
                                   <ClubAdminSearchPage {...routeProps} club={club}/>
                               }/>
                        <Route exact path="/clubadmin/engage"
                               render={(routeProps) =>
                                   <ClubAdminEngagePage {...routeProps} club={club}/>
                               }/>
                        <Route exact path="/club/:clubId/application/:applicationId"
                               render={(routeProps) =>
                                   <ClubApplicationPage {...routeProps} club={club} />
                               }/>
                        <Route exact path="/clubadmin/applicants/:applicationId"
                               render={(routeProps) =>
                                   <ClubAdminApplicantsPage {...routeProps} club={club} />
                               }/>
                        <Route exact path="/clubadmin/alumni"
                               render={(routeProps) =>
                                   <ClubAdminAlumniPage {...routeProps} club={club}/>
                               }/>
                        <Route exact path="/clubadmin/club-page"
                               render={(routeProps) =>
                                   <ClubAdminClubPage {...routeProps} club={club}/>
                               }/>
                        <Route path="/clubadmin/settings"
                               render={(routeProps) =>
                                   <ClubAdminSettingsPage {...routeProps} club={club} getClubInfo={this.getClubInfo}/>
                               }/>
                        <Route exact path="/studentprofile"
                               render={(routeProps) =>
                                   <StudentProfilePage {...routeProps} clubAdmin/>
                               }/>
                        <Route path="/event/:eventId"
                               render={(routeProps) =>
                                   <EventPage {...routeProps}/>
                               }/>
                        <Route path="/job"
                               render={(routeProps) =>
                                   <JobPage {...routeProps}/>
                               }/>
                        <Route path="/company"
                               render={(routeProps) =>
                                   <CompanyPage {...routeProps}/>
                               }/>
                        <Redirect to="/clubadmin"/>
                    </Switch>
                </div>
                
                <ImageViewerModal/>
                <ToastComponent/>
                <ClubStudentPeek
                    authUser={this.props.authUser}
                    student={this.props.studentPeekStudent}
                    close={this.props.closeStudentPeek}
                    open={this.props.studentPeekOpen}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubAdminPortal);
