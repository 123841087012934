import React, {Component} from "react";
import {connect} from "react-redux";
import {Button} from "../../components/atoms/Button";
import SvgCheck from "../icons/SvgCheck";
import SvgCopy from "../icons/SvgCopy";
import HorizontalStepperList from "../atoms/HorizontalStepperList";
import { wait } from "@testing-library/user-event/dist/utils";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}


class ResourcesPagePlaceholder extends Component {
    state = {
        copied: false
    }

    copyLink = () => {
        this.setState({copied: true});
        navigator.clipboard.writeText("https://app.recruitu.com/authentication?activeTab=signup&referred=" + this.props.user.username);
        wait(1000).then(() =>
            this.setState({copied: false})
        )
    }

    render() {
        const numSteps = 3
        // Added this so progress bar doesn't go to zero
        const progressWidth = (this.props.user.num_referred+0.00000001 / numSteps) * 100;
        return (
            <div
                className='absolute h-screen top-16 left-0 right-0 bottom-0 bg-slate-400/20 backdrop-blur-sm flex flex-col items-center justify-center pb-80'>
                <div className='card w-[500px]'>
                    <img alt={'invite'}
                         src={require('../../img/illustrations/techny-secure-lock-and-key.gif')}
                         className='w-1/2 mx-auto scale-125'/>
                    <div className="flex flex-col justify-center items-center">
                        <p className='text-xl text-slate-800 font-semibold mb-2'>
                            Coming Soon!
                        </p>
                        <p className='text-md text-slate-500 mb-4 text-center'>
                            The resources tab will be a one-stop shop for interview prep personally tailored to you.
                            {/* Invite three users to RecruitU to unlock early access. */}
                        </p>
                        {/* <div className='bg-slate-200 h-3 mt-8 rounded-full overflow-hidden '>
                            <div className='bg-indigo-600 h-3'
                                 style={{width: progressWidth + '%', transition: 'width 600ms ease-in-out'}}/>
                            {progressWidth}
                        </div>
                        <p>{this.props.user.num_referred || 0} / 3</p>
                        <Button
                            variant='secondary'
                            className="basis-1/2 mt-16"
                            onClick={this.copyLink}
                        >
                            {this.state.copied ?
                                <SvgCheck className={'w-5 h-5'}/>
                                :
                                <SvgCopy className={'w-5 h-5'}/>
                            }
                            {this.state.copied ? 'Link copied!' : 'Copy your referral link'}
                        </Button> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ResourcesPagePlaceholder);
