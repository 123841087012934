export const numToMonth = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "June",
    7: "July",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
};

// john@vandy, rowan@yale, Wes (vanderbilt), Wes (recruit), Cory, Phifer, Brooks, Brooks (trin)
export const APP_ADMINS = ["G5ThkfzKWqRXfsDLp9IjjpDFckW2", "JED8e1fxzpx4shYgQRr1","uL6yM6V8DQW9cKuHh1S2IWGJd7I2", "rtcjnR5sjrdg185rgp0EykSoeR42", "9lsEUbCWCbco47zHZegGwkhFBCL2", "k4EwKbPnZUPx3p6V8lQhH2VAHDX2", "smJQo1mN1KMozaPqwV9mc3bz7Jq1", "PDCsoSx2jwNpy3qPihwV6fNgZK13"];

export const NUM_JOBS_SHOWN = 15;
export const NUM_CLUBS_SHOWN = 10;
export const NUM_STUDENTS_SHOWN = 20;
export const NUM_COMPANIES_SHOWN = 20;
export const NUM_PEOPLE_SHOWN = 20;
export const NUM_EVENTS_SHOWN = 10;
export const NUM_COLLEGES_SHOWN = 10;
export const NUM_QUESTIONS_SHOWN = 10;
export const NUM_LISTS_SHOWN = 3;
export const NUM_POSTS_SHOWN = 15;

export const page_h_padding_class = 'px-4 sm:px-5 md:px-5'

export const STUDENT_TRACKER_STATUSES = [{title: "Tracking",}, {title: "Contacted",}, {title: "Interviewing",}, {title: "Decision",}]

export const STUDENT_TRACKER_DECISIONS = [, {title: "Ongoing",}, {title: "Offered",}, {title: "Rejected",}, {title: "Hired",},]

export const TOAST_OPTIONS = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
}

export const STATES = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DC", "DE", "FL", "GA",
  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];

export const CURRENT_YEAR = 24;


export const COMPANY_FOLLOW_OPTIONS = [
  {title: "Morgan Stanley", value: "13661", content:'company_logo'},
  {title: "Goldman Sachs", value: "14072", content:'company_logo'},
  {title: "Guggenheim", value: "13871", content:'company_logo'},
  {title: "Evercore", value: "24986", content:'company_logo'},
  {title: "Citi", value: "22502", content:'company_logo'},
  {title: "Lazard", value: "25264", content:'company_logo'},
  {title: "JP Morgan", value: "18383", content:'company_logo'},
  {title: "Houilhan Lokey", value: "28461", content:'company_logo'},
  {title: "Wells Fargo", value: "570886", content:'company_logo'},
  {title: "Blackstone", value: "20187", content:'company_logo'},
  {title: "Bank of America", value: "12493", content:'company_logo'},
  {title: "Barclays", value: "37241", content:'company_logo'},
  {title: "Deutsche Bank", value: "17420", content:'company_logo'},
  {title: "Point72", value: "15819", content:'company_logo'},
  {title: "PJT", value: "42487", content:'company_logo'},
  {title: "Centerview", value: "26803", content:'company_logo'},
  {title: "RBC", value: "17236", content:'company_logo'},
  {title: "Moelis", value: "29595", content:'company_logo'},
  {title: "Piper Sandler", value: "17839", content:'company_logo'},
  {title: "TDCowen", value: "21520", content:'company_logo'},
  {title: "Raymond James", value: "8757", content:'company_logo'},
  {title: "UBS", value: "56420", content:'company_logo'},
  {title: "KKR", value: "22908", content:'company_logo'},
  {title: "Rothschild", value: "30881", content:'company_logo'},
  {title: "Perella Weinberg Partners", value: "17402", content:'company_logo'},
]

export const question_categories_array = [
    "Private Equity / Buyside Recruiting",
    "Leveraged Buyouts (LBOs)",
    "Precedent Transactions",
    "DCF",
    "Accounting",
    // 'Restructuring (Rx) / Distressed M&A',
    // 'Valuation',
    'Comparable Company Analysis',
    // 'Brain Teasers / Misc.'
];

export const DIVERSITY_OPTIONS = [
  {title: "Gender", value: "gender"},
  {title: "Race / Ethnicity", value: "race"},
  {title: "Requires sponsorship", value: "require_sponsorship"},
  {title: "Authorized to work", value: "authorized"},
  {title: "Disability", value: "disabled"},
  {title: "Veteran", value: "veteran"},
  {title: "First generation", value: "first_generation"},
];

export const CURRENT_STUDENT_COLLEGES = ['Alverno College', 'American University', 'Amherst College', 'Amity University', 'Appalachian State University', 'Arizona State University', 'Arkansas State University', 'Auburn University', 'Augustana College (IL)', 'Babson College', 'Bard College', 'Barnard College', 'Bates College', 'Baylor University', 'Beloit College', 'Bennington College', 'Bentley College', 'Berea College', 'Blinn College', 'Boston College',
'Boston University', 'Bowdoin College', 'Bowie State University', 'Brandeis University', 'Brigham Young University', 'Brock University', 'Brown University', 'Bryant College', 'Bryn Mawr College', 'Bucknell University', 'California Institute of Technology', 'California Polytechnic State University, San Luis Obispo', 'California State University, Long Beach', 'California State University, Los Angeles', 'California State University, Monterey Bay',
'Calvin College', 'Campbell University', 'Carleton College', 'Carnegie Mellon University', 'Carthage College', 'Case Western Reserve University', 'Central Michigan University', 'Centre College', 'Cerritos College', 'Chabot-Las Positas Community College District', 'Chapman University', 'City Colleges of Chicago', 'City University of New York', 'Claremont McKenna College', 'Clark Atlanta University', 'Clark University', 'Clemson University', 'Coastal Carolina University',
'Colby College', 'Colgate University', 'College of Charleston', 'College of William and Mary', 'College of Wooster', 'College of the Holy Cross', 'Collin County Community College District', 'Colorado College', 'Colorado State University', 'Columbia University', 'Concordia University', 'Connecticut College', 'Contra Costa Community College District', 'Cooper Union for the Advancement of Science and Art', 'Cornell College', 'Cornell University',
 'Creighton University', 'Dalhousie University', 'Dalton State College', 'Dartmouth College', 'Davidson College', 'DePaul University', 'DePauw University', 'Denison University', 'Dickinson College', 'Drexel University', 'Duke University', 'Eastern Connecticut State University', 'Eastern Michigan University', 'Ecole Supérieure de Commerce de Paris', 'Elon College', 'Emerson College', 'Emory University', 'Endicott College', 'Fairfield University',
  'Fisk University', 'Florida Atlantic University', 'Florida International University', 'Florida State University', 'Foothill College', 'Fordham University', 'Fort Valley State University', 'Frankfurt School of Finance & Management', 'George Mason University', 'George Washington University', 'Georgetown University', 'Georgia Gwinnett College', 'Georgia Institute of Technology', 'Georgia Southern University', 'Georgia State University', 'Gettysburg College',
  'Gonzaga University', 'Grambling State University', 'Grand Canyon University', 'Grenoble Ecole de Management', 'Grinnell College', 'Guilford College', 'Hamilton College', 'Hampden-Sydney College', 'Harper College', 'Harvard University', 'Harvey Mudd College', 'Haverford College in Pennsylvania', 'Hobart and William Smith Colleges', 'Hofstra University', 'Howard University', 'Humber College', 'IE University', 'Illinois State University', 'Illinois Wesleyan University',
  'Indiana University', "Institut d'Etudes Politiques de Paris (Sciences Po)", 'Iowa State University', 'Ithaca College', 'Jackson State University', 'Jacksonville State University', 'James Madison University', 'Johns Hopkins University', 'Juniata College', 'Kansas State University', 'Kean College', 'Kennesaw State University', 'Knox College', 'Lafayette College', 'Lake Forest College', 'Lawrence University', 'Lehigh University', 'Liberty University',
   'Longwood University', 'Loyola College', 'Loyola Marymount University', 'Loyola University Chicago', 'Lynchburg College', 'Macalester College', 'Macomb Community College', 'Marquette University', 'Massachusetts Institute of Technology', 'McGill University', 'McMaster University', 'McNeese State University', 'Mercy College', 'Miami University of Ohio', 'Michigan State University', 'Middlebury College', 'Montclair State University', 'Morehouse',
   'Morgan State University', 'Mount Holyoke College', 'Mount Saint Mary College', 'Mt. Hood Community College', 'Mt. San Antonio College', 'Narsee Monjee Institute of Management Studies', 'National University of Singapore', 'Nevada System of Higher Education', 'New Jersey Institute of Technology', 'New York University', 'North Carolina A&T State University', 'North Carolina State University', 'Northeastern University', 'Northern Arizona University',
    'Northern Kentucky University', 'Northwestern University', 'Oberlin College', 'Occidental College', 'Ohio State University - Columbus', 'Ohio University', 'Oklahoma State University', 'Old Dominion University', 'Oregon State University', 'Pace University', 'Pacific Lutheran University', 'Pennsylvania Institute of Technology', 'Pennsylvania State University', 'Pitzer College', 'Pomona College', 'Portland State University', 'Princeton University',
    'Providence College', 'Purdue University', "Queen's University", 'Quinnipiac College', 'RCSI-Medical University of Bahrain', 'Raritan Valley Community College', 'Rennes School of Business', 'Rhodes College', 'Rice University', 'Riverside Community College District', 'Rochester Institute of Technology', 'Roger Williams University', 'Rollins College', 'Rowan College', 'Rutgers University', 'Sacred Heart University', 'Saddleback College',
    "Saint John's University (NY)", "Saint Joseph's University", "Saint Michael's College", 'Saint Olaf College', 'Salisbury University', 'San Diego State University', 'San Jose State University', 'San Mateo County Community College District', 'Santa Barbara City College', 'Santa Clara University', 'Santa Monica College', 'Scripps College', 'Seattle University', 'Seton Hall University', 'Skidmore College', 'Smith College', 'Southern College',
     'Southern Methodist University', 'St. Francis College', 'St. Lawrence University', 'Stanford University', 'State University of New York at Albany', 'State University of New York at Binghamton', 'State University of New York at Buffalo', 'State University of New York at Stony Brook', 'Stetson University', 'Stevens Institute of Technology', 'Suffolk University', 'Swarthmore College', 'Sweet Briar College', 'Syracuse University', 'Temple University',
      'Tennessee State University', 'Texas A&M University - College Station', 'Texas A&M University - Kingsville', 'Texas Christian University', 'Texas State University', 'Texas Tech University', 'The College of New Jersey', 'The University of Alabama', 'The University of North Carolina at Greensboro', 'The University of Texas at Austin', 'The University of Texas at Rio Grande Valley', 'Toronto Metropolitan University', 'Trinity College (CT)', 'Trinity University',
      'Tufts University', 'Tulane University', 'Union College', 'Universitat Pompeu Fabra', 'University of Akron', 'University of Alabama at Birmingham', 'University of Alberta', 'University of Arizona', 'University of Arkansas - Fayetteville', 'University of British Columbia', 'University of California, Berkeley', 'University of California, Davis', 'University of California, Irvine', 'University of California, Los Angeles', 'University of California, Merced',
      'University of California, Riverside', 'University of California, San Diego', 'University of California, Santa Barbara', 'University of California, Santa Cruz', 'University of Central Arkansas', 'University of Central Florida', 'University of Chicago', 'University of Cincinnati', 'University of Colorado at Boulder', 'University of Connecticut', 'University of Delaware', 'University of Dublin, Trinity College', 'University of Florida', 'University of Georgia',
      'University of Hong Kong', 'University of Houston', 'University of Houston-Downtown', 'University of Idaho', 'University of Illinois at Chicago', 'University of Illinois, Urbana-Champaign', 'University of Iowa', 'University of Kentucky', 'University of La Verne', 'University of Louisiana at Monroe', 'University of Louisville', 'University of Maryland Eastern Shore', 'University of Maryland, Baltimore County', 'University of Maryland, College Park', 'University of Massachusetts Boston',
       'University of Massachusetts at Amherst', 'University of Massachusetts at Lowell', 'University of Miami', 'University of Michigan - Ann Arbor', 'University of Minnesota', 'University of Missouri', 'University of Nebraska, Lincoln', 'University of New Hampshire', 'University of New Mexico', 'University of New Orleans', 'University of North Carolina at Chapel Hill', 'University of North Carolina at Wilmington', 'University of North Texas',
        'University of Notre Dame', 'University of Oklahoma', 'University of Oregon', 'University of Ottawa', 'University of Pennsylvania', 'University of Pittsburgh', 'University of Rochester', 'University of San Diego', 'University of San Francisco', 'University of South Carolina', 'University of South Florida', 'University of Southern California', 'University of Tampa', 'University of Tennessee, Knoxville', 'University of Texas at Arlington',
         'University of Texas at Dallas', 'University of Toronto', 'University of Utah', 'University of Vermont', 'University of Victoria', 'University of Virginia, Charlottesville', 'University of Washington', 'University of Waterloo', 'University of Western Ontario', 'University of Wisconsin - Madison', 'University of Wisconsin - Whitewater', 'Université Libre de Bruxelles', 'Université du Québec à Montréal', 'Utah State University', 'Vanderbilt University',
         'Vassar College', 'Villanova University', 'Virginia Commonwealth University', 'Virginia Community College System', 'Virginia Tech', 'Wake Forest University', 'Wake Technical Community College', 'Washington & Lee University', 'Washington University, Saint Louis', 'Weber State University', 'Wellesley College', 'Wesleyan University', 'West Virginia University', 'Western Michigan University', 'Westminster College of Fulton',
          'Wichita State University', 'Wilfrid Laurier University', 'William Paterson University', 'Williams College', 'Worcester Polytechnic Institute', 'Yale University', 'Yeshiva University', 'York University', 'École des Hautes Études Commerciales']
