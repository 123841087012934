import React, {PureComponent} from "react";
import SvgBedtimeOff from "../icons/SvgBedtimeOff";
import SvgCelebration from "../icons/SvgCelebration";
import SvgSeeking from "../icons/SvgSeeking";
import SvgEdit from "../icons/SvgEdit";
import {primary} from "../../utils/colors";
import {Button} from "../atoms/Button";

class ProfilePageSeekingRoleWidget extends PureComponent {

    render() {
        const user = this.props.user || {};
        if (user.seeking_role) {
            return (
                <div className='card flex-col flex border-2' style={{borderColor: primary}}>
                    <h3 className='mt-0 title2 primary mb-2'>
                        Good luck on the search!
                    </h3>
                    <p className='body2 base-black-70 mb-5'>
                        Recruiters will be able to see you're searching for a role.
                    </p>
                    <div className='flex-row wrap gap-2 flex flex-1'>
                        <Button icon={SvgCelebration} className='w-full'
                                onClick={() => this.props.editSeekingRole(!user.seeking_role)}>
                            Found a job
                        </Button>
                        <Button variant={'secondary'} icon={SvgBedtimeOff} className={'w-full'}
                                onClick={() => this.props.editSeekingRole(!user.seeking_role)}
                        >
                            Toggle status
                        </Button>
                    </div>
                    <div>
                        <div
                            onClick={this.props.togglePersonalizationModal}
                            className='primary cursor-pointer hover:text-indigo-800 body2-bold flex flex-row items-center justify-center gap-2 text-center pt-5 w-full'>
                            <SvgEdit className={'w-4 h-4'}/> Edit Demographics and Preferences
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className='card flex-col flex items-start  border-2' style={{borderColor: primary}}>
                <h3 className='mt-0 title2 text-neutral-900 mb-2'>
                    Are you seeking a role?
                </h3>
                <p className='text-sm text-slate-700'>
                    Stand out to recruiters and let them know you're looking by showing this badge on your profile:
                </p>
                <div
                    className='flex flex-row items-center mr-5 primary my-3 border rounded-full border-purple-100 px-3 py-1 mb-8'>
                    <SvgSeeking className={'h-5 w-5'}/>
                    <p className='text-md font-semibold ml-1'>
                        Currently seeking role
                    </p>
                </div>
                <div className='flex-row flex flex-1 wrap'>
                    <Button onClick={() => this.props.editSeekingRole(!user.seeking_role)}
                    >
                        I'm looking!
                    </Button>
                    <div className='h-full flex flex-col justify-center flex-1'>
                        <p className='text-xs text-slate-500 ml-2'>
                            You can toggle at any time. It is not
                            tied to any application process.
                        </p>
                    </div>
                </div>
                <div>
                    <div
                        onClick={this.props.togglePersonalizationModal}
                        className='primary cursor-pointer hover:text-indigo-800 body2-bold flex flex-row items-center justify-center gap-2 text-center pt-5 w-full'>
                        <SvgEdit className={'w-4 h-4'}/> Edit Demographics and Preferences
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfilePageSeekingRoleWidget
