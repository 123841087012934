import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {wait} from "@testing-library/user-event/dist/utils";
import CreateJobQualificationStep from "./create-job/CreateJobQualificationStep";
import {editEvent} from "../../api/recruiter/events";
import CreateEventDetailsStep from "./create-event/CreateEventDetailsStep";
import CreateEventDateAndLocationStep from "./create-event/CreateEventDateAndLocationStep";
import SectionTabs from "../../components/atoms/SectionTabs";

const ZERO_STATE = {
    step: 1,
    eventTitle: '',
    eventDescription: '',
    industry: null,
    city: '',
    state: '',
    date: null,
    classYears: [],
    qualifiedOnly: false,
    registerOnRecruit: true,
    majors: [],
    clubs: [],
    schools: [],
    startTime: '10:00',
    endTime: '11:00',
    virtual: false,
    meetingLink: '',
    activeTab: 'Event Details',
    gender: null,
    race: [],
    veteran: false,
    lgbtq: false,
    disabled: false,
    authorized: false,
    role: [],
};

class RecruiterEditEventModal extends Component {
    state = ZERO_STATE;

    submit = async () => {
        const params = {
            name: this.state.eventTitle,
            description: this.state.eventDescription,
            location: this.state.virtual ? "Remote" : "In-Person",
            event_date: this.state.date,
            start_time: this.state.startTime,
            end_time: this.state.endTime,
            city: !!this.state.city ? [this.state.city] : [],
            state: !!this.state.state ? [this.state.state] : [],
            class_years: this.state.classYears,
            clubs: this.state.clubs.map(club => club.id),
            schools: this.state.schools,
            link: this.state.meetingLink,
            industry: this.state.industry,
            majors: this.state.majors,
            gpa: this.state.gpa,
            qualified_only: this.state.qualifiedOnly,
            register_direct: this.state.registerOnRecruit,
            diversity: {
                gender: this.state.gender,
                race: this.state.race,
                veteran: this.state.veteran,
                lgbtq: this.state.lgbtq,
                disabled: this.state.disabled,
                authorized: this.state.authorized,
            }
        }
        await editEvent(this.props.authUser, this.props.event.id, params);
        window.location.reload();
    }

    setInfo = () => {
        const event = this.props.event || {};
        this.setState({
            eventTitle: event.name,
            eventDescription: event.description,
            eventType: event.type,
            city: !!event.city ? event.city[0] : "",
            state: !!event.state ? event.state[0] : "",
            workEnvironment: event.work_model,
            classYears: event.class_years,
            meetingLink: event.link,
            role: !!event.secondary_specialties ? event.secondary_specialties[0] : "",
            date: !!event.event_date ? new Date(event.event_date) : null,
            startTime: event.start_time,
            endTime: event.end_time,
            virtual: event.location === "Remote",
            schools: event.schools,
            clubs: event.clubs,
            majors: event.majors,
            gpa: event.gpa,
            qualifiedOnly: event.qualified_only,
            registerOnRecruit: event.register_direct,
            gender: event.diversity?.gender || null,
            race: event.diversity?.race || [],
            veteran: event.diversity?.veteran || false,
            lgbtq: event.diversity?.lgbtq || false,
            disabled: event.diversity?.disabled || false,
            authorized: event.diversity?.authorized || false,
        })
    }

    // componentDidUpdate = (prevProps) => {
    //     if (prevProps.event !== this.props.event) {
    //         this.setInfo();
    //     }
    // }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            wait(10).then(() => {
                this.setInfo();
            })
        }
    }

    componentDidMount = () => {
        this.setInfo();
    }

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    handleWorkEnvironmentChange = (event) => {
        this.setState({
            workEnvironment: event.target.value,
        });
    };

    handleQualifiedOnlyChange = (boolean) => {
        this.setState({
            qualifiedOnly: boolean,
        });
    };

    toggleInput = (stateKey) => {
        this.setState((prevState) => ({
            [stateKey]: !prevState[stateKey],
        }));
    }

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    handleTimeChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    updateClubs = (club) => {
        if (!club) {
            this.setState({clubs: []})
            return
        }

        let newClubs = [...this.state.clubs];
        if (this.state.clubs.includes(club)) {
            const index = this.state.clubs.indexOf(club);
            newClubs.splice(index, 1);
        } else {
            newClubs.push(club);
        }
        this.setState({clubs: newClubs});
    }

    updateSchools = (school) => {
        if (!school) {
            this.setState({schools: []})
            return
        }

        let newSchools = [...this.state.schools];
        if (this.state.schools.includes(school)) {
            const index = this.state.schools.indexOf(school);
            newSchools.splice(index, 1);
        } else {
            newSchools.push(school);
        }
        this.setState({schools: newSchools});
    }

    updateClassYears = (year) => {
        if (!year) {
            this.setState({classYears: []})
            return
        }

        let newClassYears = [...this.state.classYears];
        if (this.state.classYears.includes(year)) {
            const index = this.state.classYears.indexOf(year);
            newClassYears.splice(index, 1);
        } else {
            newClassYears.push(year);
        }
        this.setState({classYears: newClassYears});
    }

    updateMajors = (major) => {
        if (!major) {
            this.setState({major: []})
            return
        }
        let newMajor = [...this.state.majors];
        if (this.state.majors.includes(major)) {
            const index = this.state.majors.indexOf(major);
            newMajor.splice(index, 1);
        } else {
            newMajor.push(major);
        }
        this.setState({majors: newMajor});
    }

    updateGender = (gender) => {
        this.setState({gender})
    }

    updateRace = (race) => {
        if (!race) {
            this.setState({race: []})
            return
        }

        let newRace = [...this.state.race];
        if (this.state.race.includes(race)) {
            const index = this.state.race.indexOf(race);
            newRace.splice(index, 1);
        } else {
            newRace.push(race);
        }
        this.setState({race: newRace});
    }

    handleQualificationCheckbox = (inputName) => {
        this.setState({[inputName]: !this.state[inputName]});
    }

    handleVirtualChange = (value) => {
        this.setState({virtual: value})
    };

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    render() {
        const TABS = ['Event Details', 'Date and Location', 'Attendees'];
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit This Event'}
                headerToggle
                size={'lg'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <div onClick={this.props.closeModal} className='secondary-button body1-bold'>
                            {"Cancel"}
                        </div>
                        <div onClick={this.submit} className='primary-button body1-bold'>
                            {"Submit"}
                        </div>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div id={'add-joblisting-modal'}>
                    <div className='mb-5'>
                        <SectionTabs
                            activeTab={this.state.activeTab}
                            changeTab={this.changeTab}
                            tabs={TABS}
                        />
                    </div>
                    {this.state.activeTab === TABS[0] &&
                        <CreateEventDetailsStep
                            hideTutorial
                            eventTitle={this.state.eventTitle}
                            eventDescription={this.state.eventDescription}
                            role={this.state.role}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            constants={this.props.constants}
                        />
                    }
                    {this.state.activeTab === TABS[1] &&
                        <CreateEventDateAndLocationStep
                            hideTutorial
                            date={this.state.date}
                            handleDateChange={this.handleDateChange}
                            startTime={this.state.startTime}
                            endTime={this.state.endTime}
                            handleTimeChange={this.handleTimeChange}
                            virtual={this.state.virtual}
                            meetingLink={this.state.meetingLink}
                            state={this.state.state}
                            city={this.state.city}
                            registerOnRecruit={this.state.registerOnRecruit}
                            toggleInput={this.toggleInput}
                            handleVirtualChange={this.handleVirtualChange}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                        />
                    }
                    {this.state.activeTab === TABS[2] &&
                        <CreateJobQualificationStep
                            hideTutorial
                            majors={this.state.majors}
                            updateMajors={this.updateMajors}
                            handleSelectChange={this.handleSelectChange}
                            toggleInput={this.toggleInput}
                            qualifiedOnly={this.state.qualifiedOnly}
                            handleQualifiedOnlyChange={this.handleQualifiedOnlyChange}
                            handleInputChange={this.handleInputChange}
                            clubs={this.state.clubs}
                            gpa={this.state.gpa}
                            schools={this.state.schools}
                            updateClubs={this.updateClubs}
                            updateSchools={this.updateSchools}
                            event
                            recruiter
                            classYears={this.state.classYears}
                            updateClassYears={this.updateClassYears}
                            gender={this.state.gender}
                            race={this.state.race}
                            veteran={this.state.veteran}
                            lgbtq={this.state.lgbtq}
                            disabled={this.state.disabled}
                            authorized={this.state.authorized}
                            handleQualificationCheckbox={this.handleQualificationCheckbox}
                            updateRace={this.updateRace}
                            updateGender={this.updateGender}
                        />
                    }
                </div>
            </ModalComponent>
        )
    }
}

export default RecruiterEditEventModal
