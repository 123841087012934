import React, {PureComponent} from 'react';
import ModalComponent from "./ModalComponent";
import {wait} from "@testing-library/user-event/dist/utils";
import SvgLogo from "../../icons/SvgLogo";
import {Link} from "react-router-dom";
import {Button} from "../Button";
import Spinner from "../../../components/Spinner";


const STEP_ORDER = {
    welcome: 0,
    homepage: 1,
    peopletab: 2,
    jobstab: 3,
    eventstab: 4,
    companiestab: 5,
    learntab: 6
}

class WalkThroughModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            step: 'welcome', // {welcome, homepage, peopletab, jobstab, eventstab, companiestab, learntab}
            opacity: 1,
            welcomeTranslation: 0,
            homepageTranslation: 20,
            peopletabTranslation: 20,
            jobstabTranslation: 20,
            eventstabTranslation: 20,
            companiestabTranslation: 20,
            learntabTranslation: 20,
        }
    }

    navigateToStep = step => {
        const currentStep = this.state.step
        const movingForwards = STEP_ORDER[step] > STEP_ORDER[currentStep]
        this.setState(
            {opacity: 0, [`${currentStep}Translation`]: movingForwards ? -20 : 20},
            () => {
                wait(300)
                    .then(() => {
                        this.setState(
                            {step},
                            () => wait(150)
                                .then(() => this.setState({opacity: 1, [`${step}Translation`]: 0}))
                        )
                    })
            }
        )
    }

    handleOnboardingTutorialViewed = () => {
        window.analytics.track("Onboarding tutorial viewed")
        this.props.markOnboardingTutorialViewed()
    }

    switchImage = () => {
        switch (this.state.step) {
            case 'welcome':
                return (
                    <div className='relative'>
                        <img
                             height={420}
                             className='rounded-t-lg absolute -top-3 scale-110'
                             src={require('../../../img/walkthrough/main (1).png')}
                             alt="homepage screenshot"
                             style={{
                                 transition: 'opacity 300ms ease, transform 300ms ease',
                                 opacity: this.state.opacity,
                                 transform: `translateY(${Math.abs(this.state.homepageTranslation) / 2}px)`
                             }}
                        />
                        <div style={{
                            height: 428,
                            transition: 'opacity 300ms ease, transform 300ms ease',
                            opacity: this.state.opacity,
                            transform: `translateY(${Math.abs(this.state.welcomeTranslation) / 2}px)`
                        }} className='flex flex-col items-center justify-center'>
                            <SvgLogo className={'text-white'}/>
                            <p className='text-lg font-semibold text-white mt-5'>
                                Meet the best
                            </p>
                        </div>
                    </div>
                )
            case 'homepage':
                return <img width={800}
                            height={420}
                            className='rounded-t-lg'
                            src={require('../../../img/walkthrough/slide1.png')}
                            alt="homepage screenshot"
                            style={{
                                transition: 'opacity 300ms ease, transform 300ms ease',
                                opacity: this.state.opacity,
                                transform: `translateY(${Math.abs(this.state.homepageTranslation) / 2}px)`
                            }}
                />
            case 'peopletab':
                return <img width={800}
                            height={420}
                            className='rounded-t-lg'
                            src={require('../../../img/walkthrough/slide2.png')}
                            alt="server screenshot"
                            style={{
                                transition: 'opacity 300ms ease, transform 300ms ease',
                                opacity: this.state.opacity,
                                transform: `translateY(${Math.abs(this.state.peopletabTranslation) / 2}px)`
                            }}
                />
            case 'jobstab':
                return <img width={800}
                            height={420}
                            className='rounded-t-lg'
                            src={require('../../../img/walkthrough/slide3.png')}
                            alt="server screenshot"
                            style={{
                                transition: 'opacity 300ms ease, transform 300ms ease',
                                opacity: this.state.opacity,
                                transform: `translateY(${Math.abs(this.state.jobstabTranslation) / 2}px)`
                            }}
                />
            case 'eventstab':
                return <img width={800}
                            height={420}
                            className='rounded-t-lg'
                            src={require('../../../img/walkthrough/slide4.png')}
                            alt="profile screenshot"
                            style={{
                                transition: 'opacity 300ms ease, transform 300ms ease',
                                opacity: this.state.opacity,
                                transform: `translateY(${Math.abs(this.state.eventstabTranslation) / 2}px)`
                            }}
                />
            case 'companiestab':
                return <img width={800}
                            height={420}
                            className='rounded-t-lg'
                            src={require('../../../img/walkthrough/slide5.png')}
                            alt="profile screenshot"
                            style={{
                                transition: 'opacity 300ms ease, transform 300ms ease',
                                opacity: this.state.opacity,
                                transform: `translateY(${Math.abs(this.state.companiestabTranslation) / 2}px)`
                            }}
                />
            case 'learntab':
                return <img width={800}
                            height={420}
                            className='rounded-t-lg'
                            src={require('../../../img/walkthrough/slide6.png')}
                            alt="profile screenshot"
                            style={{
                                transition: 'opacity 300ms ease, transform 300ms ease',
                                opacity: this.state.opacity,
                                transform: `translateY(${Math.abs(this.state.learntabTranslation) / 2}px)`
                            }}
                />
            default:
                return null
        }
    }

    switchText = () => {
        switch (this.state.step) {
            case 'welcome':
                return (
                    <p className='text-slate-800 text-xl font-semibold mb-20'
                       style={{
                           transition: 'opacity 300ms ease, transform 300ms ease',
                           opacity: this.state.opacity,
                           transform: `translateX(${this.state.welcomeTranslation}px)`
                       }}
                    >
                        <span role="img" aria-label="wave">👋</span> Welcome to RecruitU, your new home <span
                        className="sunset-gradient">from first step to final offer</span>
                    </p>
                )
            case 'homepage':
                return (
                    <p className='text-slate-800 text-xl font-semibold mb-20'
                       style={{
                           transition: 'opacity 300ms ease, transform 300ms ease',
                           opacity: this.state.opacity,
                           transform: `translateX(${this.state.homepageTranslation}px)`
                       }}
                    >
                        Use <span className='primary'>Home</span> to find recs, tips and tricks, and track your overall
                        progress
                    </p>
                )
            case 'peopletab':
                return (
                    <p className='text-slate-800 text-xl font-semibold mb-20'
                       style={{
                           transition: 'opacity 300ms ease, transform 300ms ease',
                           opacity: this.state.opacity,
                           transform: `translateX(${this.state.peopletabTranslation}px)`
                       }}
                    >
                        Find contacts and manage your networking efforts on the <span
                        className='primary'>Network</span> tab
                    </p>
                )
            case 'jobstab':
                return (
                    <p className='text-slate-800 text-xl font-semibold mb-20'
                       style={{
                           transition: 'opacity 300ms ease, transform 300ms ease',
                           opacity: this.state.opacity,
                           transform: `translateX(${this.state.jobstabTranslation}px)`
                       }}
                    >
                        Discover new opportunities and track your progress to an offer on the <span
                        className='primary'>Jobs</span> tab
                    </p>
                )
            case "eventstab":
                return (
                    <p className='text-slate-800 text-xl font-semibold mb-20'
                       style={{
                           transition: 'opacity 300ms ease, transform 300ms ease',
                           opacity: this.state.opacity,
                           transform: `translateX(${this.state.eventstabTranslation}px)`
                       }}
                    >
                        Stay ahead by finding and attending events using the <span className='primary'>Events</span> tab
                    </p>
                )
            case "companiestab":
                return (
                    <p className='text-slate-800 text-xl font-semibold mb-20'
                       style={{
                           transition: 'opacity 300ms ease, transform 300ms ease',
                           opacity: this.state.opacity,
                           transform: `translateX(${this.state.companiestabTranslation}px)`
                       }}
                    >
                        Find and follow your favorite companies on the <span className='primary'>Companies</span> tab
                    </p>
                )
            case "learntab":
                return (
                    <p className='text-slate-800 text-xl font-semibold mb-20'
                       style={{
                           transition: 'opacity 300ms ease, transform 300ms ease',
                           opacity: this.state.opacity,
                           transform: `translateX(${this.state.learntabTranslation}px)`
                       }}
                    >
                        Refine your interview skills with real-world examples in the <span
                        className='primary'>Learn</span> tab
                    </p>
                )
            default:
                return null
        }
    }

    switchButtons = () => {
        switch (this.state.step) {
            case 'welcome':
                return (
                    <div className="row-ac-je">
                        <Button variant={'ghost'} className='hidden'>
                            Back
                        </Button>
                        <Button variant={'default'} onClick={() => this.navigateToStep('homepage')}>
                            Next
                        </Button>
                    </div>
                )
            case 'homepage':
                return (
                    <div className="row-ac-jb">
                        <Button variant={'ghost'} onClick={() => this.navigateToStep('welcome')}>
                            Back
                        </Button>
                        <Button variant={'default'} onClick={() => this.navigateToStep('peopletab')}>
                            Next
                        </Button>
                    </div>
                )
            case 'peopletab':
                return (
                    <div className="row-ac-jb">
                        <Button variant={'ghost'} onClick={() => this.navigateToStep('homepage')}>
                            Back
                        </Button>
                        <Button variant={'default'} onClick={() => this.navigateToStep('jobstab')}>
                            Next
                        </Button>
                    </div>
                )
            case 'jobstab':
                return (
                    <div className="row-ac-jb">
                        <Button variant={'ghost'} onClick={() => this.navigateToStep('peopletab')}>
                            Back
                        </Button>
                        <Button variant={'default'} onClick={() => this.navigateToStep('eventstab')}>
                            Next
                        </Button>
                    </div>
                )
            case 'eventstab':
                return (
                    <div className="row-ac-jb">
                        <Button variant={'ghost'} onClick={() => this.navigateToStep('jobstab')}>
                            Back
                        </Button>
                        <Button variant={'default'} onClick={() => this.navigateToStep('companiestab')}>
                            Next
                        </Button>
                    </div>
                )
            case 'companiestab':
                return (
                    <div className="row-ac-jb">
                        <Button variant={'ghost'} onClick={() => this.navigateToStep('eventstab')}>
                            Back
                        </Button>
                        <Button variant={'default'} onClick={() => this.navigateToStep('learntab')}>
                            Next
                        </Button>
                    </div>
                )
            case 'learntab':
                return (
                    <div className="row-ac-jb">
                        <Button variant={'ghost'} onClick={() => this.navigateToStep('companiestab')}>
                            Back
                        </Button>
                        <Button variant={'default'} onClick={this.props.markOnboardingTutorialViewed}>
                            {this.props.loading ? <Spinner light={true}/> : 'Complete my Profile'}
                        </Button>
                    </div>
                )
            default:
                return null
        }
    }

    render() {
        const stepNumber = STEP_ORDER[this.state.step]
        return (
            <ModalComponent isOpen={this.props.isOpen}
                            noPadding
                            width={800}
                            noMaxHeight
                            backgroundColor={'white'}
            >
                <div className={`${stepNumber !== 0 ? 'pt-24 ph-24' : 'p-0 m-0'} bg-primary col-plain`}
                     style={{borderTopRightRadius: 12, borderTopLeftRadius: 12, overflow: 'hidden'}}>
                    {this.switchImage()}
                </div>
                <div className="bg-white pv-20 ph-24"
                     style={{borderBottomRightRadius: 12, borderBottomLeftRadius: 12, overflow: 'hidden'}}>
                    <div className="row-ac pv-6 mb-20">
                        <div style={{height: 3, width: 80, transition: "background 1s ease, background-color 1s ease"}}
                             className="bg-slate-200 br-2 mr-8">
                            <div className="bg-primary br-2 mr-8 w-100p h-100p"
                                 style={{opacity: stepNumber < 1 ? 0 : 1, transition: 'opacity 300ms ease'}}
                            />
                        </div>
                        <div style={{height: 3, width: 80, transition: "background 1s ease, background-color 1s ease"}}
                             className="bg-slate-200 br-2 mr-8">
                            <div className="bg-primary br-2 mr-8 w-100p h-100p"
                                 style={{opacity: stepNumber < 2 ? 0 : 1, transition: 'opacity 300ms ease'}}
                            />
                        </div>
                        <div style={{height: 3, width: 80, transition: "background 1s ease, background-color 1s ease"}}
                             className="bg-slate-200 br-2 mr-8">
                            <div className="bg-primary br-2 mr-8 w-100p h-100p"
                                 style={{opacity: stepNumber < 3 ? 0 : 1, transition: 'opacity 300ms ease'}}
                            />
                        </div>
                        <div style={{height: 3, width: 80, transition: "background 1s ease, background-color 1s ease"}}
                             className="bg-slate-200 br-2 mr-8">
                            <div className="bg-primary br-2 mr-8 w-100p h-100p"
                                 style={{opacity: stepNumber < 4 ? 0 : 1, transition: 'opacity 300ms ease'}}
                            />
                        </div>
                        <div style={{height: 3, width: 80, transition: "background 1s ease, background-color 1s ease"}}
                             className="bg-slate-200 br-2 mr-8">
                            <div className="bg-primary br-2 mr-8 w-100p h-100p"
                                 style={{opacity: stepNumber < 5 ? 0 : 1, transition: 'opacity 300ms ease'}}
                            />
                        </div>
                        <div style={{height: 3, width: 80, transition: "background 1s ease, background-color 1s ease"}}
                             className="bg-slate-200 br-2 mr-8">
                            <div className="bg-primary br-2 mr-8 w-100p h-100p"
                                 style={{opacity: stepNumber < 6 ? 0 : 1, transition: 'opacity 300ms ease'}}
                            />
                        </div>
                    </div>
                    {this.switchText()}
                    {this.switchButtons()}
                </div>
            </ModalComponent>
        );
    }
}

WalkThroughModal.propTypes = {};

export default WalkThroughModal;
