import React, { useState, useEffect, useMemo } from 'react';
import OnboardingStepHeader from './OnboardingStepHeader';
import OnboardingStepWrapper from './OnboardingStepWrapper';
import OnboardingStepButtons from './OnboardingStepButtons';
import InputWrapper from '../atoms/inputs/InputWrapper';
import MultiSelectComponent from '../atoms/MultiSelectComponent';
import { MAJOR_OPTIONS } from '../../utils/dummy';
import SvgCheck from '../icons/SvgCheck';
import { getSchoolClubs } from '../../api/student/clubs'; 
import { connect } from 'react-redux';
import useIsMobile from '../../hooks/isMobile';


function mapStateToProps(state) {
    return {
       user: state.userReducer.user,
       authUser: state.userReducer.authUser,
    };
}

function OnboardingEducationStep({ goNextStep, goPreviousStep, onEducationSubmit, haveJob, lastStep, authUser, user }) {
    const [formData, setFormData] = useState({
        major: [],
        minor: [],
        gpa: '',
        classYear: '',
        clubs: []
    });

    const [noGpa, setNoGpa] = useState(false);
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [clubOptions, setClubOptions] = useState([]);
    const isMobile = useIsMobile();

    const clubsNamesMap = useMemo(() => {
        return Object.fromEntries(clubOptions.map(club => [club.id, club.name]));
    }, [clubOptions]);
    const clubsIdsMap = useMemo(() => {
        return Object.fromEntries(clubOptions.map(club => [club.name, club.id]));
    }, [clubOptions]);

    const selectedClubNames = useMemo(() => {
        return formData.clubs.map(clubId => clubsNamesMap[clubId]);
    }, [formData.clubs, clubsNamesMap]);



    useEffect(() => {
        const fetchClubs = async () => {
            const res = await getSchoolClubs(authUser, user.college);
            console.log(res)
            setClubOptions(res.clubs.filter(club => club.demo !== true));
        };
        fetchClubs();
    }, [authUser, user.college]);

  

    const handleMajorChange = (selectedMajor) => {
        const majorArray = [...formData.major];
        let updatedMajor;
        if (majorArray.includes(selectedMajor)) {
            updatedMajor = majorArray.filter((item) => item !== selectedMajor);
        } else if (selectedMajor === null) {
            updatedMajor = [];
        } else {
            updatedMajor = [...majorArray, selectedMajor];
        }
        setFormData({ ...formData, major: updatedMajor });
    };

    const handleMinorChange = (selectedMinor) => {
        const minorArray = [...formData.minor];
        let updatedMinor;
        if (minorArray.includes(selectedMinor)) {
            updatedMinor = minorArray.filter((item) => item !== selectedMinor);
        } else if (selectedMinor === null) {
            updatedMinor = [];
        } else {
            updatedMinor = [...minorArray, selectedMinor];
        }
        setFormData({ ...formData, minor: updatedMinor });
    };

    const handleGradYearChange = (e) => {
        const text = e.target.value;
        if (text === "" || (text.length <= 4 && /^\d*$/.test(text)) && Number(text) <= 2030) {
            setFormData({ ...formData, classYear: text });
        }
    };

    const handleGPAChange = (e) => {
        const text = e.target.value;
        // if (text === "" || /^\d*\.?\d*$/.test(text)) {
            setFormData({ ...formData, gpa: text });
        // }
    };

    const handleClubsChange = (selectedClubName) => {
        
        let updatedClubs;
        if (selectedClubNames.includes(selectedClubName)) {
            updatedClubs = formData.clubs.filter(clubId => clubsNamesMap[clubId] !== selectedClubName);
        } else if (selectedClubName === null) {
            updatedClubs = [];
        } else {
            updatedClubs = [...formData.clubs, clubsIdsMap[selectedClubName]];
        }
        setFormData({ ...formData, clubs: updatedClubs });
    };

    const toggleCheckbox = () => {
        setNoGpa(!noGpa);
        if (!noGpa) {
            setFormData({ ...formData, gpa: '' });
            setErrors({ ...errors, gpa: '' });
        }
    };

    useEffect(() => {
        validateForm();
    }, [formData, noGpa]);

    const validateForm = () => {
        let formErrors = {};

        if (!formData.classYear || formData.classYear.length !== 4 || !(/^\d*\.?\d*$/.test(formData.classYear))) formErrors.classYear = "Valid class year is required";

        if (!noGpa && (!formData.gpa || parseFloat(formData.gpa) < 0)) {
            formErrors.gpa = "Valid GPA is required";
        }

        setErrors(formErrors);
        setIsFormValid(Object.keys(formErrors).length === 0);
    };

    const handleSubmit = () => {
        if (isFormValid) {
            onEducationSubmit(formData);
            goNextStep(isMobile ? 7 : null);
        }
    };

    return (
        <OnboardingStepWrapper>
            <OnboardingStepHeader
                title={'Tell us about yourself'}
                description={'Please provide your major, minor, GPA, class year, and clubs'}
            />
            <div className='flex flex-col gap-3 md:gap-5'>
                <div className='flex-1'>
                    <p className='text-sm text-slate-500 font-semibold mb-1'>Major(s)</p>
                    <MultiSelectComponent
                        label={'Your major'}
                        value={formData.major}
                        light
                        setValue={handleMajorChange}
                        clearFilter={() => handleMajorChange(null)}
                        options={MAJOR_OPTIONS}
                    />
                </div>
                <div className='flex-1 hidden md:block'>
                    <p className='text-sm text-slate-500 font-semibold mb-1'>Minor(s)</p>
                    <MultiSelectComponent
                        label={'Your minor'}
                        value={formData.minor}
                        light
                        setValue={handleMinorChange}
                        clearFilter={() => handleMinorChange(null)}
                        options={MAJOR_OPTIONS}
                    />
                </div>
                {!noGpa && (
                    <InputWrapper
                        label='GPA*'
                        type='number'
                        name='gpa'
                        placeholder='ie. 3.6'
                        value={formData.gpa}
                        onChange={handleGPAChange}
                        error={errors.gpa}
                    />
                )}
                <div className='flex-1'>
                    <button onClick={toggleCheckbox} className={`flex flex-row items-center ml-2 mt-1 w-max`}>
                        <div className={`${noGpa ? 'checkbox-active' : 'checkbox border-slate-200'} mr-2`}>
                            {noGpa ? <SvgCheck className={'base-white-100'} /> : null}
                        </div>
                        <p className='body2 text-slate-500'>I don't have a GPA yet</p>
                    </button>
                </div>
                <InputWrapper
                    label='Class Year*'
                    type='text'
                    name='classYear'
                    placeholder='ie. 2024'
                    value={formData.classYear}
                    onChange={handleGradYearChange}
                    error={errors.classYear}
                />
                {clubOptions.length > 0 && (
                    <div className='flex-1'>
                        <p className='text-sm text-slate-500 font-semibold mb-1'>Club(s)</p>
                    <MultiSelectComponent
                        label={'Select your clubs'}
                        value={selectedClubNames}
                        light
                        setValue={handleClubsChange}
                        clearFilter={() => handleClubsChange(null)}
                        options={clubOptions.map(club => ({ title: club.name, club: club }))}
                        scrollable={true}
                        />
                    </div>
                )}
            </div>
            <OnboardingStepButtons
                goNextStep={handleSubmit}
                goPreviousStep={goPreviousStep}
                nextLabel={lastStep ? "Finish": "Next"}
                isNextDisabled={!isFormValid}
            />
        </OnboardingStepWrapper>
    );
}

export default connect(mapStateToProps)(OnboardingEducationStep);
