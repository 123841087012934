import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import DatePicker from "react-datepicker";
import {uploadDoc} from "../../api/firebase";
import Dropzone from "react-dropzone";
import SvgPlus from "../../components/icons/SvgPlus";
import {editCompany} from "../../api/recruiter/companies";
import {connect} from "react-redux";
import { v4 as uuid } from 'uuid';
import {Button} from "../../components/atoms/Button";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class RecruiterCompanyAddDeal extends Component {
    state = {
        company_name: '',
        title: '',
        description: '',
        date: '',
        logoUrl: "",
    }

    closeModal = () => {
        this.props.closeModal()
    }

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    save = async () => {
        const deals = this.props.company.deals || [];
        deals.push({
            date: this.state.date,
            company_name: this.state.company,
            logo_url: this.state.logoUrl,
            title: this.state.title,
            description: this.state.description,
        })
        await editCompany(this.props.authUser, {deals});
        this.props.closeModal();
        window.location.reload()
    }

    onDrop = async (acceptedFiles) => {
        const newUuid = await uuid()
        const url = await uploadDoc("companies/deals/" + this.props.company.id, newUuid, acceptedFiles[0]);
        this.setState({logoUrl: url});
    }

    render() {
        const saveDisabled = false
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Add deal'}
                headerToggle
                size={'lg'}
                toggle={this.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={saveDisabled}
                            onClick={this.save}>
                           Add
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className='flex flex-col gap-5'>
                    <Dropzone onDrop={(files) => this.onDrop(files)}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className='cursor-pointer'>
                                <input {...getInputProps()} />
                                <div style={{}} className=''>
                                    {!!this.state.logoUrl ? (
                                        <div
                                            className='h-24 w-80 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                        >
                                            <img
                                                className='h-24 w-80 object-contain bg-white aspect-square cursor-pointer  rounded-lg'
                                                src={this.state.logoUrl}
                                                alt="profile-pic"
                                            />
                                            {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                        </div>
                                    ) : (
                                        <div
                                            className='h-24 w-80 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                        >
                                            <SvgPlus className={'text-blue-500'}/>
                                            <p className='text-xs mt-2'>
                                                Add logo
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Company'}
                        placeholder={'Company name'}
                        className='input-light px-3 w-full'
                        value={this.state.company}
                        onChange={(e) => this.handleInputChange(e, 'company')}
                    />
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Title'}
                        placeholder={'Deal title'}
                        className='input-light px-3 w-full'
                        value={this.state.title}
                        onChange={(e) => this.handleInputChange(e, 'title')}
                    />
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Description'}
                        placeholder={'Deal description'}
                        className='input-light px-3 w-full'
                        value={this.state.description}
                        onChange={(e) => this.handleInputChange(e, 'description')}
                    />
                    <div className='w-full flex flex-1'>
                        <div className='text-xs font-medium text-slate-500 mb-1'>
                            Date
                        </div>
                        <DatePicker
                            className={'px-3 input-light body2 w-full flex-1'}
                            placeholderText={'Deal date'}
                            selected={this.state.date}
                            showYearDropdown
                            onChange={(e) => this.handleDateChange(e, 'date')}
                        />
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RecruiterCompanyAddDeal);
