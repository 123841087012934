import { wait } from "@testing-library/user-event/dist/utils";
import React, { PureComponent } from "react";
import { CLASS_YEARS, MAJOR_OPTIONS } from "../../../utils/dummy";
import DatePicker from "react-datepicker";
import WrappedTextInput from "../WrappedTextInput";
import TimePicker from "react-time-picker";
import { editEvent } from "../../../api/student/admin";
import { searchCompanies } from "../../../utils/typesense";
import TagComponent from "../TagComponent";
import MultiSelectComponent from '../MultiSelectComponent';
import ModalComponent from './ModalComponent';
import RichTextEditor from "../inputs/RichTextEditor";
import { EditorState, ContentState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';


class EditEventModal extends PureComponent {
    state = {
        title: '',
        company: "",
        city: [],
        state: [],
        newCity: "",
        newState: "",
        description: "",
        url: "",
        searchResults: [],
        classYears: [],
        industry: '',
        startTime: '10:00',
        classYears: [],
        majors: [],
        gpa: null,
        endTime: '11:00',
        date: null,
        editorState: EditorState.createEmpty(),

    }

    titleChanged = e => this.setState({title: e.target.value});

    newCityChanged = e => this.setState({newCity: e.target.value});

    newStateChanged = e => this.setState({newState: e.target.value});

    urlChanged = e => this.setState({url: e.target.value});

    industryChanged = e => this.setState({industry: e.target.value});

    descriptionChanged = e => this.setState({description: e.target.value});

    handleDeadlineChange = e => this.setState({deadline: e});

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    handleTimeChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    updateClassYears = (year) => {
        if (year === null) {
            this.setState({classYears: []});
            return
        }

        let newClassYears = [...this.state.classYears];
        if (this.state.classYears.includes(year)) {
            const index = this.state.classYears.indexOf(year);
            newClassYears.splice(index, 1);
        } else {
            newClassYears.push(year);
        }
        this.setState({classYears: newClassYears});
    }

    updateCities = () => {
        const city = this.state.newCity;
        let newCity = [...this.state.city];
        newCity.push(city);
        this.setState({city: newCity, newCity: ""});
    }

    deleteCity = (city) => {
        let newCities = [...this.state.city];
        const index = this.state.city.indexOf(city);
        newCities.splice(index, 1);
        this.setState({city: newCities, newCity: ""});
    }

    updateStates = () => {
        const state = this.state.newState;
        let newState = [...this.state.state];
        newState.push(state);
        this.setState({state: newState, newState: ""});
    }

    deleteState = (state) => {
        let newStates = [...this.state.state];
        const index = this.state.state.indexOf(state);
        newStates.splice(index, 1);
        this.setState({state: newStates, newState: ""});
    }

    companyChanged = async (e) => {
        const text = e.target.value;
        this.setState({company: text});
        const searchResults = await searchCompanies(text);
        this.setState({searchResults});
    }

    setCompany = (result) => {
        this.setState({
            searchResults: [],
            companyId: result.id,
            company: result.name,
        })
    }

    componentDidMount = () => {
        this.setEventInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            wait(10).then(() => {
                this.setEventInfo();
            })
        }
    }

    updateClassYears = (year) => {
        if (!year) {
            this.setState({classYears: []})
            return
        }

        let newClassYears = [...this.state.classYears];
        if (this.state.classYears.includes(year)) {
            const index = this.state.classYears.indexOf(year);
            newClassYears.splice(index, 1);
        } else {
            newClassYears.push(year);
        }
        this.setState({classYears: newClassYears});
    }

    updateEvent = async () => {
        const contentState = this.state.editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);
        await editEvent(this.props.authUser, this.props.listing.id, {
            name: this.state.title,
            description: htmlContent,
            city: this.state.city,
            state: this.state.state,
            company_id: this.state.companyId,
            event_date: this.state.date,
            start_time: this.state.startTime,
            end_time: this.state.endTime,
            link: this.state.url,
            class_years: this.state.classYears,
            industry: this.state.industry,
            majors: this.state.majors,
            gpa: this.state.gpa,
        });
        this.props.closeModal();
        this.props.fetchEvent();
    }

    setEventInfo = () => {
        const event = this.props.listing || {};
        const company = event.company || {};
        this.setState({
            title: event.name,
            city: !!event.city ? event.city : [],
            state: !!event.state ? event.state : [],
            description: event.description,
            company: company.name,
            date: !!event.event_date ? new Date(event.event_date) : null,
            startTime: event.start_time,
            endTime: event.end_time,
            url: event.link,
            majors: event.majors,
            gpa: event.gpa,
            classYears: !!event.class_years? event.class_years : [],
            industry: event.industry,
        });

        if (event.description){
            try {
                const contentState = typeof value === 'string'
                    ? ContentState.createFromText(event.description)
                    : convertFromRaw(event.description);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({ editorState });
            } catch (error) {
                this.setState({ editorState: EditorState.createEmpty() });
            }
        } else {
            this.setState({ editorState: EditorState.createEmpty() });
   
        }
    }

    setEditorState = (editorState) => {
        this.setState({editorState});
    }

    updateMajors = (major) => {
        if (!major) {
            this.setState({major: []})
            return
        }
        let newMajor = [...this.state.majors];
        if (this.state.majors.includes(major)) {
            const index = this.state.majors.indexOf(major);
            newMajor.splice(index, 1);
        } else {
            newMajor.push(major);
        }
        this.setState({majors: newMajor});
    }

    render() {
        const tags = this.state.tags || [];
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit Event'}
                headerToggle
                size={'xl'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <div onClick={this.updateEvent} className='primary-button body1-bold'>
                            Save
                        </div>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div id={'add-joblisting-modal'}>
                    <div className='flex flex-row flex-wrap gap-2 mt-5'>
                        <div className="col-plain">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Title
                            </p>
                            <input
                                value={this.state.title}
                                style={{width: 300}}
                                onChange={this.titleChanged}
                                placeholder={'Enter title'}
                                className='input-light px-3 body2 base-black-50'
                            />
                        </div>
                        <div className="col-plain">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Company
                            </p>
                            <input
                                value={this.state.company}
                                style={{width: 300}}
                                onChange={this.companyChanged}
                                placeholder={'Enter title'}
                                className='input-light px-3 body2 base-black-50'
                            />
                        </div>
                        {this.state.searchResults.map(result => {
                            return (
                                <button className='row-plain' onClick={() => this.setCompany(result)}>
                                    <img src={result.logo_url} alt={'company-icon'}
                                            className='w-8 h-8 rounded-sm border object-contain bg-white border-neutral-200 mr-2'/>
                                    <div className='col-plain'>
                                        <p className='body3'>{result.name}</p>
                                        <p className='body4'>{result.sector}</p>
                                    </div>
                                </button>
                            )
                        })}
                        <div className="col-plain">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Industry
                            </p>
                            <input
                                value={this.state.industry}
                                style={{width: 300}}
                                onChange={this.industryChanged}
                                placeholder={'Enter industry'}
                                className='input-light px-3 body2 base-black-50'
                            />
                        </div>
                        <div className="col-plain">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Event URL
                            </p>
                            <input
                                value={this.state.url}
                                style={{width: 300}}
                                onChange={this.urlChanged}
                                placeholder={'Enter job url'}
                                className='input-light px-3 body2 base-black-50'
                            />
                        </div>
                        <div className='flex flex-row items-center gap-3'>
                            <div className={`flex flex-col gap-1.5 flex-1`}>
                                <p className='text-md text-slate-500 font-semibold text-slate-800'>
                                    Date
                                </p>
                                <DatePicker
                                    className={'px-3 input-light body2 w-full'}
                                    placeholderText={'Set date'}
                                    selected={this.state.date}
                                    showYearDropdown
                                    onChange={(e) => this.handleDateChange(e, 'date')}
                                />
                            </div>
                            <p className='text-md font-semibold text-slate-800 mt-7 mx-3'>
                                From
                            </p>
                            <div className={`flex flex-col gap-1.5 flex-1 mt-[30px]`}>
                                <TimePicker
                                    className='input-light px-3 outline-none text-slate-800'
                                    value={this.state.startTime}
                                    disableClock
                                    showTime={{use12Hours: true, format: "HH:mm A"}}
                                    onChange={(value) => this.handleDateChange(value, 'startTime')}
                                />
                            </div>
                            <p className='text-md font-semibold text-slate-800 mt-7 mx-3'>
                                To
                            </p>
                            <div className={`flex flex-col gap-1.5 flex-1 mt-[30px]`}>
                                <TimePicker
                                    className='input-light px-3 outline-none text-slate-800'
                                    value={this.state.endTime}
                                    disableClock
                                    showTime={{use12Hours: true, format: "HH:mm A"}}
                                    onChange={(value) => this.handleDateChange(value, 'endTime')}
                                />
                            </div>
                        </div>
                        <div className="col-plain">
                            
                        </div>
                    </div>
                    <div className='flex flex-row items-center justify-between gap-3'>
                        <MultiSelectComponent
                            className={'flex-1'}
                            outerLabel={'Majors'}
                            label={'ie. Finance, Economics'}
                            value={this.state.majors}
                            light
                            setValue={this.updateMajors}
                            clearFilter={() => this.updateMajors(null)}
                            options={MAJOR_OPTIONS}
                            direction={'top'}
                            node={document.getElementById('add-joblisting-modal')}
                        />
                        <MultiSelectComponent
                            className={'flex-[0.5]'}
                            outerLabel={'Graduation Year'}
                            label={'ie. 2025, 2026...'}
                            value={this.state.classYears}
                            light
                            setValue={this.updateClassYears}
                            clearFilter={() => this.updateClassYears(null)}
                            options={CLASS_YEARS}
                            node={document.getElementById('add-joblisting-modal')}
                        />
                        <WrappedTextInput
                            type="numeric"
                            outerLabel={'Minimum GPA'}
                            placeholder={'ie. 3.8'}
                            className='input-light px-3 flex-[0.5]'
                            value={this.state.gpa}
                            onChange={(e) => this.handleInputChange(e, 'gpa')}
                        />
                    </div>
                    <div className='mt-3'>
                        <label htmlFor="message" className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                            Description
                        </label>
                        {/* <textarea id="message" rows="4"
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Job description..." onChange={this.descriptionChanged}
                                    value={this.state.description}></textarea> */}
                        <div>
                            <RichTextEditor initialHtml={this.state.description} editorState={this.state.editorState} setEditorState={this.setEditorState}/>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <div className='flex flex-row gap-3'>
                            <div className='mt-5'>
                                <label className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                                    City
                                </label>
                                <div className='items-center flex flex-row gap-1'>
                                    {this.state.city.map(tag =>
                                        <TagComponent
                                            label={tag}
                                            type={'job-type'}
                                            removable
                                            deleteTag={this.deleteCity}
                                        />
                                    )}
                                </div>
                                <div className="col-plain mt-2">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        + Add City
                                    </p>
                                    <div className='flex flex-row items-center gap-2'>
                                        <input
                                            value={this.state.newCity}
                                            style={{width: 300}}
                                            onChange={this.newCityChanged}
                                            placeholder={'Enter city'}
                                            className='input-light px-3 body2 base-black-50'
                                        />
                                        <button
                                            disabled={this.state.newCity === ''}
                                            onClick={this.updateCities}
                                            className={'primary-button-sm h-full body1-bold disabled:opacity-50'}>
                                            Add city
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <label className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                                    State
                                </label>
                                <div className='items-center flex flex-row gap-1'>
                                    {this.state.state.map(tag =>
                                        <TagComponent
                                            label={tag}
                                            removable
                                            deleteTag={this.deleteState}
                                        />
                                    )}
                                </div>
                                <div className="col-plain mt-2">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        + Add State
                                    </p>
                                    <div className='flex flex-row items-center gap-2'>
                                        <input
                                            value={this.state.newState}
                                            style={{width: 300}}
                                            onChange={this.newStateChanged}
                                            placeholder={'Enter state'}
                                            className='input-light px-3 body2 base-black-50'
                                        />
                                        <button
                                            disabled={this.state.newState === ''}
                                            onClick={this.updateStates}
                                            className={'primary-button-sm h-full body1-bold disabled:opacity-50'}>
                                            Add state
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='mt-5'>
                            <label className="block mb-2 body2-bold text-neutral-400 dark:text-white">
                                Tags
                            </label>
                            <div className='items-center flex flex-row gap-1'>
                                {tags.map(tag =>
                                    <TagComponent
                                        label={tag}
                                        type={'random'}
                                        removable
                                        deleteTag={this.deleteTag}
                                    />
                                )}
                            </div>
                            <div className="col-plain mt-2">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    + Add Tag
                                </p>
                                <div className='flex flex-row items-center gap-2'>
                                    <input
                                        value={this.state.newTag}
                                        style={{width: 300}}
                                        onChange={this.newTagChanged}
                                        placeholder={'Enter tag'}
                                        className='input-light px-3 body2 base-black-50'
                                    />
                                    <button
                                        disabled={this.state.newTag === ''}
                                        onClick={this.updateTags}
                                        className={'primary-button-sm h-full body1-bold disabled:opacity-50'}>
                                        Add tag
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default EditEventModal
