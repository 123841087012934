import React, {useState, useEffect} from "react";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {connect} from "react-redux";
import { editUser, getUser } from "../../api/student/users";
import { createApplication } from "../../api/student/applications";
import { createTracker } from "../../api/student/trackers";
import OnboardingLeftPane from "./OnboardingLeftPane";
import OnboardingCitiesStep from "./OnboardingCitiesStep";
import OnboardingLinkedinStep from "./OnboardingLinkedinStep";
import OnboardingResumeStep from "./OnboardingResumeStep";
import OnboardingEducationStep from "./OnboardingEducationStep";
import OnboardingIndustriesStep from "./OnboardingIndustriesStep";
import OnboardingCompaniesStep from "./OnboardingCompaniesStep";
import OnboardingMatchesStep from "./OnboardingMatchesStep";
import OnboardingWorkStep from "./OnboardingWorkStep";
import Spinner from "../Spinner";
import { addLinkedinUrl } from "../../api/student/users";
import SvgR from "../icons/SvgR";
import AnimatedLogo from "../atoms/loading/AnimatedLogo";
import Marquee from "react-fast-marquee";
import SvgLogo from "../icons/SvgLogo";
import useIsMobile  from "../../hooks/isMobile";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        constants: state.userReducer.constants,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

function OnboardingMain({user, authUser, setUser, constants}) {
    const [step, setStep] = useState(5);
    const [loading, setLoading] = useState(false);
    const [path, setPath] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [major, setMajor] = useState([]);
    const [minor, setMinor] = useState([]);
    const [gpa, setGpa] = useState(null);
    const [grade, setGrade] = useState("");
    const [resume, setResume] = useState("");
    const [locations, setLocations] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [roleInterests, setRoleInterests] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [current_job, setCurrentJob] = useState({});
    const [lastStep, setLastStep] = useState(12);
    const [clubs, setClubs] = useState([]);
    const have_job = user?.have_job;
    const job_status = user?.job_status;

    const isMobile = useIsMobile();

    const onEducationSubmit = (formData) => {
        setMajor(formData.major);
        setMinor(formData.minor);
        setGpa(!!formData.gpa ? Number(formData.gpa) : 0.0);
        setGrade(formData.classYear);
        setClubs(formData.clubs);
    }

    useEffect(() => {
        if (!have_job && job_status === "Not looking right now") {
            setLastStep(7);
        } else if (have_job && job_status === "Not looking right now") {
            setLastStep(8);
        } else {
            setLastStep(12);
        }
    }, [have_job, job_status])

    function goNextStep(nextStep=null) {
        window.analytics.track("Onboarding Step Completed", {
          onboarding_step: (nextStep || (step + 1)) - 1
        })
        if (step === lastStep) {
            updateUser();
        } else if (!!nextStep) {
            setStep(nextStep);
        } else {
            setStep(step + 1);
        }
    }

    function goPreviousStep() {
        if (step === 8 && !have_job) {
            setStep(6)
        } else if (isMobile && step === 7) {
            setStep(5);
        } else{
            setStep(step - 1);
        }
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const path = queryParameters.get("path", "");
        setPath(path);
    }, [])

    const updateUser = async () => {
        setLoading(true);
        const newParams = {
            grade,
            linkedin,
            major,
            minor,
            gpa,
            resume,
            locations,
            industries,
            role_interests: roleInterests,
            current_job,
            seeking_role: job_status !== "Not looking right now",   
            onboarded: true,
            clubs,
        }

        await editUser(authUser, newParams);
        await addLinkedinUrl(authUser, linkedin);
        for (const companyId of companies) {
            createTracker(authUser, {type: "company", company_id: companyId});
        }
        for (const job of jobs) {
            createApplication(authUser, {company_id: job.company_id, job_id: job.id});
        }

        const user = await getUser(authUser);
        setPath(path);
        setUser(user.user);
        setLoading(false);

        window.analytics.track("Onboarding Completed")
    }

    if (loading) {
        return <div className='min-h-[100vh] flex flex-col items-center justify-center'>
            <div className='flex flex-col items-center'>
                <div className='flex flex-row items-center scale-[.5]'>
                    <SvgR className={'-mr-1 mb-[2px] text-slate-900'}/>
                    <AnimatedLogo width={62} height={62} loading="true"/>
                </div>
                <Marquee direction={'right'} className='bg-slate-200 rounded-full mr-1' style={{width: 52}}>
                    <div className='h-2 w-2 rounded-full bg-slate-500'/>
                </Marquee>
            </div>
        </div>
    }
    return (
        <div className='flex flex-row flex-1  overflow-hidden bg-student-gradient md:bg-unset'>
            <OnboardingLeftPane step={step}/>
           
            <div className='flex flex-col flex-1 md:overflow-y-scroll md:mt-[72px] justify-center md:justify-start w-full mx-10 md:mx-0 overflow-hidden md:overflow-auto'>
                <div className='flex flex-col gap-0 items-center mb-7 md:hidden'>
                    <SvgLogo className={'w-56 text-white'}/>
                </div>
                <SwitchTransition>
                    <CSSTransition
                        key={step}
                        timeout={500}
                        classNames="fade-slide"
                    >
                        <div className="w-full bg-white tw-card md:tw-card-none max-h-[calc(100vh-7rem)] md:max-h-full overflow-y-auto flex mx-auto md:block">

                            {/* Onboarding starts here */}
                            {step === 5 && (
                                <OnboardingEducationStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    onEducationSubmit={onEducationSubmit}
                                    haveJob={have_job}
                                    lastStep={lastStep === 7}
                                />
                            )}

                           
                            {step === 6 && (
                                <OnboardingResumeStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    resume={resume}
                                    setResume={setResume}
                                    user={user}
                                />
                            )}

                            {step === 7 && (
                                <OnboardingLinkedinStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    linkedin={linkedin}
                                    setLinkedin={setLinkedin}
                                />
                            )}
                           
                            {/*Work step to show after education step IF the user said they had a job*/}
                            {step === 8 && (
                                <OnboardingWorkStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    setCurrentJob={setCurrentJob}
                                    jobStatus={job_status}
                                    lastStep={lastStep === 8}
                                />
                            )}

                            {/*Preferences, skip this if user said they are not looking for a job*/}
                            {step === 9 && (
                                <OnboardingCitiesStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    onCitiesSelected={setLocations}
                                    cities={locations}
                                />
                            )}
                            {step === 10 && (
                                <OnboardingIndustriesStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    onIndustriesSelected={setIndustries}
                                    onRolesSelected={setRoleInterests}
                                    industries={industries}
                                    roles={roleInterests}
                                    constants={constants}
                                />
                            )}
                            {step === 11 && (
                                <OnboardingCompaniesStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    onCompaniesSelected={setCompanies}
                                />
                            )}
                            {/*Matches*/}
                            {step === 12 && (
                                <OnboardingMatchesStep
                                    goPreviousStep={goPreviousStep}
                                    goNextStep={goNextStep}
                                    jobs={jobs}
                                    setJobs={setJobs}
                                    grade={grade}
                                    industries={industries}
                                    companies={companies}
                                />
                            )}
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingMain);
