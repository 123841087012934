import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {APPLICATION_STATUS_OPTIONS, EVENT_STATUS_OPTIONS, INDUSTRY_OPTIONS} from "../../utils/dummy";
import SelectComponent from "../../components/atoms/SelectComponent";
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class RecruiterPeekEditStatus extends Component {
    state = {}

    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    render() {
        const saveEnabled = false
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={`Update ${this.props.type} status`}
                headerToggle
                size={'md'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <button onClick={this.props.closeModal} className='secondary-button body1-bold'>
                            Cancel
                        </button>
                        <button
                            disabled={!saveEnabled}
                            onClick={this.save}

                            className='primary-button body1-bold disabled:opacity-50'>
                            Save Changes
                        </button>
                    </div>
                }
                // id={'edit-company-header-modal'}
                footerAlignment={'right'}
            >
                <div className='flex flex-col gap-5' id={'edit-status-modal'}>
                    <SelectComponent
                        className={'flex-1'}
                        outerLabel={'Status'}
                        label={'ie. Applied'}
                        value={this.state.status}
                        light
                        setValue={this.handleSelectChange}
                        clearFilter={() => this.handleSelectChange('status', null)}
                        stateName={'industry'}
                        options={this.props.type === 'application' ? APPLICATION_STATUS_OPTIONS : EVENT_STATUS_OPTIONS}
                        node={document.getElementById('edit-status-modal')}

                    />
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RecruiterPeekEditStatus);
