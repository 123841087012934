import React, {Component} from "react";
import {wait} from "@testing-library/user-event/dist/utils";

import ModalComponent from "../../components/atoms/modals/ModalComponent";
import ProgressStepper from "../atoms/ProgressStepper";
import CreateJobQualificationStep from "./create-job/CreateJobQualificationStep";
import {createEvent} from "../../api/recruiter/events";
import { createStudentEvent } from "../../api/student/events";
import CreateEventDetailsStep from "./create-event/CreateEventDetailsStep";
import CreateEventDateAndLocationStep from "./create-event/CreateEventDateAndLocationStep";
import SearchCompany from "../../components/addjobflow/SearchCompany";
import {searchCompanies} from "../../utils/typesense";
import {Button} from "../../components/atoms/Button";

const ZERO_STATE = {
    step: 0,
    eventTitle: '',
    eventDescription: '',
    industry: '',
    address:'',
    city: '',
    state: '',
    date: null,
    classYears: [],
    qualifiedOnly: false,
    registerOnRecruit: false,
    majors: [],
    clubs: [],
    schools: [],
    startTime: '10:00',
    endTime: '11:00',
    virtual: false,
    meetingLink: '',
    companyId: '',
    creator: '',
    role: '',
    stepLabels:  ['Event Details', 'Date and Location', 'Attendees']
};

class CreateEventModal extends Component {
    state = {
        ...ZERO_STATE,
        gender: null,
        race: [],
        veteran: false,
        lgbtq: false,
        disabled: false,
        authorized: false,
    };

    componentDidMount = () => {
        this.setInfo();

        if (this.props.tier === 'blk') this.setState({stepLabels:  ['Event Details', 'Date and Location']})
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            wait(10).then(() => {
                this.setInfo();
            })
        }
    }

    setInfo = () => {
        if (this.props.recruiter) {
            const companyId = this.props.user.company_id || ""
            this.setState({creator: companyId, companyId, step: 1, clubs: this.props.currentClubs})
        } else {
            this.setState({creator: this.props.authUser.uid, date: this.props.selectedDate})
        }
    }

    submit = async () => {
        const tier = this.props.tier;
        const industry = this.state.role?.endsWith('Consulting') ? 'Consulting' : 'Finance';

        const params = {
            name: this.state.eventTitle,
            description: this.state.eventDescription,
            company_id: this.state.companyId,
            creator: this.state.creator,
            location: this.state.virtual ? "Remote" : "In-Person",
            event_date: this.state.date,
            start_time: this.state.startTime,
            end_time: this.state.endTime,
            address:this.state.address,
            city: !!this.state.city ? [this.state.city] : [],
            state: !!this.state.state ? [this.state.state] : [],
            class_years: this.state.classYears,
            scope: tier !== "blk" ? "public" : "club",
            clubs: tier !== "blk" ? this.state.clubs.map(club => club.id) : ["baeZVpmndkm8fCNGFIuZ"],
            schools: this.state.schools,
            link: this.state.meetingLink,
            industry: this.state.industry,
            majors: this.state.majors,
            gpa: this.state.gpa,
            qualified_only: this.state.qualifiedOnly,
            exclusive: true,
            register_direct: this.state.registerOnRecruit,
            specialties: [industry],
            secondary_specialties: [this.state.role]
        }

        if (this.props.recruiter) {
            const res = await createEvent(this.props.authUser, params);
            window.analytics.track("Recruiter New Event Created");
        } else {
            await createStudentEvent(this.props.authUser, params);
        }
        this.props.closeModal();
        window.location.reload();
    }

    nextStep = () => {
        if (this.state.step === this.state.stepLabels.length) {
            this.submit();
            return;
        }
        this.setState({step: this.state.step + 1})
    }

    prevStep = () => {
        if (this.state.step === 1) {
            this.props.closeModal();
            return;
        }
        this.setState({step: this.state.step - 1})
    }


    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    handleQualifiedOnlyChange = (boolean) => {
        this.setState({
            qualifiedOnly: boolean,
        });
    };

    toggleInput = (stateKey) => {
        this.setState((prevState) => ({
            [stateKey]: !prevState[stateKey],
        }));
    }

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    handleTimeChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    updateClubs = (club) => {
        if (!club) {
            this.setState({clubs: []})
            return
        }

        let newClubs = [...this.state.clubs];
        if (this.state.clubs.includes(club)) {
            const index = this.state.clubs.indexOf(club);
            newClubs.splice(index, 1);
        } else {
            newClubs.push(club);
        }
        this.setState({clubs: newClubs});
    }

    updateSchools = (school) => {
        if (!school) {
            this.setState({schools: []})
            return
        }

        let newSchools = [...this.state.schools];
        if (this.state.schools.includes(school)) {
            const index = this.state.schools.indexOf(school);
            newSchools.splice(index, 1);
        } else {
            newSchools.push(school);
        }
        this.setState({schools: newSchools});
    }

    updateClassYears = (year) => {
        if (!year) {
            this.setState({classYears: []})
            return
        }

        let newClassYears = [...this.state.classYears];
        if (this.state.classYears.includes(year)) {
            const index = this.state.classYears.indexOf(year);
            newClassYears.splice(index, 1);
        } else {
            newClassYears.push(year);
        }
        this.setState({classYears: newClassYears});
    }

    updateMajors = (major) => {
        if (!major) {
            this.setState({major: []})
            return
        }
        let newMajor = [...this.state.majors];
        if (this.state.majors.includes(major)) {
            const index = this.state.majors.indexOf(major);
            newMajor.splice(index, 1);
        } else {
            newMajor.push(major);
        }
        this.setState({majors: newMajor});
    }

    handleVirtualChange = (value) => {
        this.setState({virtual: value})
    };

    setCompany = (result) => {
        this.setState({
            searchResults: [],
            companyId: result.id,
            companyName: result.name,
        })
        this.nextStep();
    }

    companyChanged = async (text) => {
        const searchResults = await searchCompanies(text);
        this.setState({searchResults});
    }

    switchDisabled = () => {
        switch (this.state.step) {
            case 0:
                return true;
            case 1:
                return !this.state.eventTitle || !this.state.eventDescription;
            case 2:
                return this.state.date === null || (this.state.virtual ? !this.state.meetingLink : (!this.state.city || !this.state.state));
            default:
                return false
        }
    }

    updateGender = (gender) => {
        this.setState({gender})
    }

    updateRace = (race) => {
        if (!race) {
            this.setState({race: []})
            return
        }

        let newRace = [...this.state.race];
        if (this.state.race.includes(race)) {
            const index = this.state.race.indexOf(race);
            newRace.splice(index, 1);
        } else {
            newRace.push(race);
        }
        this.setState({race: newRace});
    }

    handleQualificationCheckbox = (inputName) => {
        this.setState({[inputName]: !this.state[inputName]});
    }

    render() {

        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Create A New Event'}
                headerToggle
                size={'lg'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button variant={'secondary'} onClick={this.prevStep}>
                            {this.state.step === 1 ? "Cancel" : "Back"}
                        </Button>
                        <Button onClick={this.nextStep} disabled={this.switchDisabled()}>
                            {this.state.step !== this.state.stepLabels.length ? "Next" : "Submit"}
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
                showScrollBar
            >
                <div id={'add-joblisting-modal'}>
                    <ProgressStepper
                        step={this.state.step}
                        stepLabels={this.state.stepLabels}
                    />
                    {/*Todo: START AT STEP 0 FOR STUDENTS OR NO COMPANY PASSED IN*/}
                    {this.state.step === 0 &&
                        <SearchCompany
                            nextStep={this.nextStep}
                            setCompany={this.setCompany}
                            companyChanged={this.companyChanged}
                            searchResults={this.state.searchResults}
                        />
                    }

                    {this.state.step === 1 &&
                        <CreateEventDetailsStep
                            eventTitle={this.state.eventTitle}
                            eventDescription={this.state.eventDescription}
                            industry={this.state.industry}
                            role={this.state.role}
                            classYears={this.state.classYears}
                            updateClassYears={this.updateClassYears}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            constants={this.props.constants}
                        />
                    }
                    {this.state.step === 2 &&
                        <CreateEventDateAndLocationStep
                            date={this.state.date}
                            handleDateChange={this.handleDateChange}
                            startTime={this.state.startTime}
                            endTime={this.state.endTime}
                            handleTimeChange={this.handleTimeChange}
                            virtual={this.state.virtual}
                            meetingLink={this.state.meetingLink}
                            address={this.state.address}
                            state={this.state.state}
                            city={this.state.city}
                            registerOnRecruit={this.state.registerOnRecruit}
                            toggleInput={this.toggleInput}
                            handleVirtualChange={this.handleVirtualChange}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                        />
                    }
                    {this.state.step === 3 &&
                        <CreateJobQualificationStep
                            event
                            majors={this.state.majors}
                            updateMajors={this.updateMajors}
                            handleSelectChange={this.handleSelectChange}
                            toggleInput={this.toggleInput}
                            qualifiedOnly={this.state.qualifiedOnly}
                            handleQualifiedOnlyChange={this.handleQualifiedOnlyChange}
                            handleInputChange={this.handleInputChange}
                            clubs={this.state.clubs}
                            schools={this.state.schools}
                            updateClubs={this.updateClubs}
                            updateSchools={this.updateSchools}
                            recruiter={this.props.recruiter}
                            gender={this.state.gender}
                            race={this.state.race}
                            veteran={this.state.veteran}
                            lgbtq={this.state.lgbtq}
                            disabled={this.state.disabled}
                            authorized={this.state.authorized}
                            handleQualificationCheckbox={this.handleQualificationCheckbox}
                            updateRace={this.updateRace}
                            updateGender={this.updateGender}
                            classYears={this.state.classYears}
                            updateClassYears={this.updateClassYears}
                        />
                    }
                </div>
            </ModalComponent>
        )
    }
}

export default CreateEventModal
