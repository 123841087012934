import React, {Component} from "react";
import WrappedTextInput from "../../../components/atoms/WrappedTextInput";
import {CLASS_YEARS, MAJOR_OPTIONS} from "../../../utils/dummy";
import MultiSelectComponent from "../../../components/atoms/MultiSelectComponent";
import SvgSearch from "../../../components/icons/SvgSearch";
import CollapseComponent from "../../../components/atoms/CollapseComponent";
import FadeInOnScroll from "../../../components/atoms/FadeInOnScroll";
import SvgClose from "../../../components/icons/SvgClose";
import {searchClubs, searchColleges} from "../../../utils/typesense";
import SelectComponent from "../../../components/atoms/SelectComponent";
import SvgCheck from "../../../components/icons/SvgCheck";
import Checkbox from "../../../components/atoms/Checkbox";


class CreateJobQualificationStep extends Component {
    state = {
        schoolSearch: '',
        clubSearch: '',
        clubOptions: [],
        schoolOptions: []
    }

    handleClubSearchChange = async (event) => {
        const text = event.target.value;
        this.setState({clubSearch: text});
        const clubs = await searchClubs(text, 1, 5, this.props.schools.join(","));
        this.setState({clubOptions: clubs});
    }

    selectClub = (club) => {
        this.props.updateClubs(club);
        this.setState({clubSearch: '', clubOptions: []});
    }

    handleSchoolSearchChange = async (event) => {
        const text = event.target.value;
        this.setState({schoolSearch: text});

        const schools = await searchColleges(text);
        this.setState({schoolOptions: schools});
    }

    selectSchool = (school) => {
        this.props.updateSchools(school);
        this.setState({schoolSearch: '', schoolOptions: []});
    }

    render() {
        const qualifiedOptions = [
            {label: "Exclusive", value: "qualified", description: `Only candidates matching criteria above can apply to this ${this.props.event ? 'event' : 'job'}`},
            {label: "Recommended", value: "recommended", description: `Candidates matching criteria will be notified, but anyone can apply`}
        ]
        const checkboxes = [
            {
                label: 'Veteran',
                active: this.props.veteran,
                onClick: () => this.props.handleQualificationCheckbox('veteran')
            },
            {
                label: 'LGBTQ',
                active: this.props.lgbtq,
                onClick: () => this.props.handleQualificationCheckbox('lgbtq')
            },
            {
                label: 'Disability',
                active: this.props.disabled,
                onClick: () => this.props.handleQualificationCheckbox('disabled')
            },
            {
                label: 'Authorized to work',
                active: this.props.authorized,
                onClick: () => this.props.handleQualificationCheckbox('authorized')
            },
        ];

        return (
            <div className='flex flex-col gap-5'>
                {!this.props.hideTutorial &&
                    <p className='text-lg text-slate-800 font-semibold'>
  
                        {`Choose who to share this ${this.props.event ? 'event' : 'job'} with`}
                        
                    </p>
                }
                {this.props.recruiter ?
                    <>
                        <p className='text-md text-slate-500 font-semibold text-slate-800'>
                            How would you like to share your {this.props.event ? 'event listing' : 'job posting'}?
                        </p>
                        <fieldset className="grid grid-cols-2 gap-4">
                            {qualifiedOptions.map((option) => (
                                <div key={option.value}>
                                    <input
                                        type="radio"
                                        name="locationOption"
                                        value={option.value}
                                        id={option.value}
                                        className="peer hidden [&:checked_+_label_svg]:block"
                                        checked={option.value === 'qualified' ? this.props.qualifiedOnly : !this.props.qualifiedOnly}
                                        onChange={() => this.props.handleQualifiedOnlyChange(option.value === 'qualified')}
                                    />
                                    <label
                                        htmlFor={option.value}
                                        className={`block cursor-pointer rounded-lg border  bg-white p-3 text-sm font-medium shadow-sm ${
                                            (option.value === 'qualified' && this.props.qualifiedOnly) || 
                                            (option.value === 'recommended' && !this.props.qualifiedOnly)
                                                ? "border-blue-500"
                                                : "border-gray-100 hover:border-gray-200"
                                        }`}
                                    >
                                        <div className="flex items-center justify-between gap-2">
                                            <div className="flex flex-col gap-2">
                                                <p className='text-md text-slate-800 font-semibold'>
                                                    {option.label}
                                                </p>
                                                <p className='text-sm text-slate-500'>
                                                    {option.description}
                                                </p>
                                            </div>
                                            <div className="w-6 h-6">
                                                <svg
                                                    className={`h-5 w-5 text-blue-600 ${
                                                        (option.value === 'qualified' && this.props.qualifiedOnly) || 
                                                        (option.value === 'recommended' && !this.props.qualifiedOnly) 
                                                            ? 'block' 
                                                            : 'hidden'
                                                    }`}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            ))}
                        </fieldset>
                    </>
                    : null}
                {/*Todo: Show only to recruiters, students school should be set to the school they are attending behind the scenes*/}
                {this.props.recruiter ?
                    <>
                        <div className='flex flex-row justify-between gap-3'>
                            <div className='flex-[1] relative'>
                                <WrappedTextInput
                                    type="text"
                                    outerLabel={'Schools'}
                                    placeholder={'ie. Harvard, UChicago, ...'}
                                    className='pl-44 input-light flex-1 px-3 body2 w-full'
                                    value={this.state.schoolSearch}
                                    onChange={this.handleSchoolSearchChange}
                                    icon={<SvgSearch width={24} height={24}
                                                    className={'absolute text-slate-500'}
                                                    style={{left: 12, top: 10}}/>}
                                />
                                <div className='flex flex-row items-center gap-2 mt-2 flex-wrap'>
                                    {this.props.schools.filter(e => e).map(item =>
                                        <div onClick={() => this.props.updateSchools(item)}
                                            className='relative group cursor-pointer'>
                                            <div
                                                className='h-[40px] px-2 text-xs flex flex-col items-center justify-center border border-slate-200 group-hover:border-red-500 rounded-lg object-cover'>
                                                <p>{item.slice(0, 20) + (item.length > 20 ? '...' : '')}</p>
                                            </div>
                                            <div className='absolute top-[-4px] right-[-8px] bg-slate-200  rounded-full  p-1'>
                                                <SvgClose className={'w-3 h-3 group-hover:text-red-500'}/>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='absolute left-0 right-0 z-10 top-16'>
                                    <CollapseComponent
                                        className={'bg-white rounded-lg border border-slate-200 shadow-xl'}
                                        isOpen={this.state.schoolSearch.length !== 0}>
                                        {this.state.schoolOptions.map((res) =>
                                            <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                                <div
                                                    onClick={() => this.selectSchool(res.name)}
                                                    className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                                    <div className='flex flex-col'>
                                                        <p className='body1-bold text-slate-800'>
                                                            {res.name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </FadeInOnScroll>
                                        )}
                                    </CollapseComponent>
                                </div>
                        </div>
                        </div>
                        <MultiSelectComponent
                            className={'flex-1'}
                            outerLabel={'Graduation Year'}
                            label={'ie. 2025, 2026...'}
                            value={this.props.classYears}
                            light
                            setValue={this.props.updateClassYears}
                            clearFilter={() => this.props.updateClassYears(null)}
                            options={CLASS_YEARS}
                            node={document.getElementById('add-joblisting-modal')}
                        />
                        {/*Todo: automatically select the clubs the student is in, they can add or remove from there. The search should return only the clubs they are in*/}
                        <div className='flex-[1] relative'>
                            <WrappedTextInput
                                type="text"
                                outerLabel={'Clubs'}
                                placeholder={'ie. Harvard Business Club...'}
                                className='pl-44 input-light flex-1 px-3 body2 w-full'
                                value={this.state.clubSearch}
                                onChange={this.handleClubSearchChange}
                                icon={<SvgSearch width={24} height={24}
                                                className={'absolute text-slate-500'}
                                                style={{left: 12, top: 10}}/>}
                            />
                            <div className='flex flex-row items-center gap-2 mt-2 flex-wrap z-[99]'>
                                {this.props.clubs.map(item =>
                                    <div className='relative group cursor-pointer' onClick={() => this.props.updateClubs(item)}>
                                        <img
                                            className='h-[40px] aspect-square border border-slate-200 group-hover:border-red-500 rounded-lg object-contain'
                                            src={item.logo_url}
                                        />
                                        <div className='absolute top-[-4px] right-[-8px] bg-slate-200  rounded-full  p-1'>
                                            <SvgClose className={'w-3 h-3 group-hover:text-red-500'}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='absolute left-0 right-0 z-10 top-16'>
                                <CollapseComponent className={'bg-white rounded-lg border border-slate-200 shadow-xl'}
                                                isOpen={this.state.clubSearch.length !== 0}>
                                    {/*Todo:refocus the search input*/}
                                    {this.state.clubOptions.map((res) =>
                                        <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                            <div
                                                onClick={() => this.selectClub(res)}
                                                className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                                <img
                                                    className='h-[40px] aspect-square border border-slate-200 bg-white rounded-lg object-contain'
                                                    src={res.logo_url}
                                                />
                                                <div className='flex flex-col'>
                                                    <p className='body1-bold text-slate-800'>
                                                        {res.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </FadeInOnScroll>
                                    )}
                                </CollapseComponent>
                            </div>
                        </div>
                    </>
                : null}
                <div className={`grid ${this.props.recruiter ? 'grid-cols-2' : 'grid-cols-1'} gap-2`}>
                    <MultiSelectComponent
                        outerLabel={'Majors'}
                        label={'ie. Finance, Economics'}
                        value={this.props.majors}
                        light
                        setValue={this.props.updateMajors}
                        clearFilter={() => this.props.updateMajors(null)}
                        options={MAJOR_OPTIONS}
                        direction={'top'}
                        node={document.getElementById('add-joblisting-modal')}

                    />
                    {this.props.recruiter ? 
                        <WrappedTextInput
                            type="numeric"
                            outerLabel={'Minimum GPA'}
                            placeholder={'ie. 3.8'}
                            className='input-light px-3  w-full'
                            value={this.props.gpa}
                            onChange={(e) => this.props.handleInputChange(e, 'gpa')}
                        />
                    : null}
                </div>
        
                {this.props.recruiter && (
                    <>
                        <div className='flex flex-row gap-2'>
                            <SelectComponent
                                label='Gender'
                                light
                                value={this.props.gender}
                                scrollable={true}
                                setValue={this.props.updateGender}
                                clearFilter={() => this.props.updateGender(null)}
                                options={[{title: "Male"}, {title: "Female"}, {title: "Prefer Not to Specify"}]}
                                node={document.getElementById('add-joblisting-modal')}
                            />
                            <MultiSelectComponent
                                className={'min-w-[120px]'}
                                label='Race'
                                light
                                value={this.props.race}
                                scrollable={true}
                                setValue={(value) => this.props.updateRace(value)}
                                clearFilter={() => this.props.updateRace(null)}
                                options={[
                                    {title: "White"},
                                    {title: "Hispanic or Latino"},
                                    {title: "Black or African American"},
                                    {title: "American Indian or Native American"},
                                    {title: "Asian or Pacific Islander"},
                                    {title: "Other"},
                                    {title: "Prefer Not to Specify"}
                                ]}
                                node={document.getElementById('add-joblisting-modal')}
                            />
                            {checkboxes.map(checkbox => (
                               <Checkbox label={checkbox.label} checked={checkbox.active} onClick={checkbox.onClick} className={'px-2'}/>
                            ))}
                        </div>
                    </>
                )}
            </div>
        )
    }
}

export default CreateJobQualificationStep