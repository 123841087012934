import React, {useState} from 'react';
import OnboardingStepHeader from './OnboardingStepHeader';
import OnboardingStepWrapper from './OnboardingStepWrapper';
import OnboardingStepButtons from './OnboardingStepButtons';
import SelectableItem from './SelectableItem';
import {POPULAR_COMPANIES} from './onboarding-constants'; // Replace with actual company list
import SvgClose from '../icons/SvgClose';
import WrappedTextInput from '../atoms/WrappedTextInput';
import SvgCompanyBuilding from '../icons/SvgCompanyBuilding';
import CollapseComponent from '../atoms/CollapseComponent';
import FadeInOnScroll from '../atoms/FadeInOnScroll';
import CompanyLogo from '../atoms/CompanyLogo';
import {searchCompanies} from '../../utils/typesense';
import InputWrapper from "../atoms/inputs/InputWrapper";

function OnboardingWorkStep({goNextStep, goPreviousStep, setCurrentJob, lastStep}) {
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companySearch, setCompanySearch] = useState('');
    const [openCollapse, setOpenCollapse] = useState(false);
    const [companySearchResults, setCompanySearchResults] = useState([]);
    const [jobTitle, setJobTitle] = useState('');
    const [jobType, setJobType] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

    const handleCompanyClick = (company) => {
        setSelectedCompany(company);
        setCompanySearch('');
        setOpenCollapse(false);
        validateForm(company, jobTitle, jobType);
    };

    const handleSearchChange = (event) => {
        const text = event.target.value;
        setCompanySearch(text);

        if (text === '') {
            setOpenCollapse(false);
            setCompanySearchResults([]);
            return;
        }

        setOpenCollapse(true);
        companySearchChanged(text);
    };

    const companySearchChanged = async (text) => {
        const results = await searchCompanies(text);
        setCompanySearchResults(results);
    };

    const handleJobTitleChange = (event) => {
        const title = event.target.value;
        setJobTitle(title);
        validateForm(selectedCompany, title, jobType);
    };

    const handleJobTypeChange = (event) => {
        const type = event.target.value;
        setJobType(type);
        validateForm(selectedCompany, jobTitle, type);
    };

    const validateForm = (company, title, type) => {
        setIsFormValid(company && title && type);
    };

    const handleSubmit = () => {
        if (isFormValid) {
            setCurrentJob({
                company: selectedCompany.id,
                company_name: selectedCompany.name,
                title: jobTitle,
                job_type: jobType
            });
            goNextStep();
        }
    };

    return (
        <OnboardingStepWrapper>
            <OnboardingStepHeader
                title={'Tell us about your work'}
                description={'Please provide your company, job title, and whether it is a full time position or an internship'}
            />
            <div className='flex flex-col gap-5'>
                <div>
                    <p className='text-sm text-slate-500 font-semibold mb-1'>
                        Company
                    </p>
                    <div className='relative'>
                        <div onClick={() => selectedCompany?.name && setSelectedCompany(null)}>
                            <WrappedTextInput
                                type="text"
                                placeholder={'Search any company...'}
                                className='pl-44 input-light px-3 body2 w-full'
                                value={selectedCompany?.name || companySearch}
                                onChange={handleSearchChange}
                                icon={
                                    <SvgCompanyBuilding
                                        width={20}
                                        height={20}
                                        className={'absolute text-slate-500'}
                                        style={{left: 12, top: 10}}
                                    />
                                }
                            />
                        </div>
                        <div className='absolute top-12 left-0 right-0 z-[99]'>
                            <CollapseComponent
                                isOpen={companySearch.length !== 0 && openCollapse}
                                className='bg-white shadow-lg border overflow-hidden border-neutral-200 rounded-lg'
                            >
                                {companySearchResults.map((res) => (
                                    <FadeInOnScroll triggerImmediately delay={10} key={res.id}>
                                        <div
                                            onClick={() => handleCompanyClick(res)}
                                            className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 px-2'
                                        >
                                            <CompanyLogo id={res.id} className={'w-12 h-12'}/>
                                            <div className='flex flex-col'>
                                                <p className='text-sm text-slate-800 line-clamp-2'>
                                                    {res.name}
                                                </p>
                                            </div>
                                        </div>
                                    </FadeInOnScroll>
                                ))}
                                <FadeInOnScroll triggerImmediately delay={10} key={"empty"}>
                                    <div
                                        onClick={() => handleCompanyClick({id: "", name: companySearch})}
                                        className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 px-2'
                                    >
                                        <CompanyLogo id={"res.id"} className={'w-12 h-12'}/>
                                        <div className='flex flex-col'>
                                            <p className='text-sm text-slate-800 line-clamp-2'>
                                                {companySearch}
                                            </p>
                                        </div>
                                    </div>
                                </FadeInOnScroll>
                            </CollapseComponent>
                        </div>
                    </div>
                </div>
                <InputWrapper
                    label={'Job Title'}
                    type="text"
                    placeholder="Job Title"
                    value={jobTitle}
                    onChange={handleJobTitleChange}
                />
                <div className="grid grid-cols-2 gap-5">
                    <SelectableItem
                        label="Full Time"
                        isSelected={jobType === 'Full Time'}
                        onClick={() => handleJobTypeChange({target: {value: 'Full Time'}})}
                    />
                    <SelectableItem
                        label="Internship"
                        isSelected={jobType === 'Internship'}
                        onClick={() => handleJobTypeChange({target: {value: 'Internship'}})}
                    />
                </div>
            </div>
            <OnboardingStepButtons
                goNextStep={handleSubmit}
                goPreviousStep={goPreviousStep}
                nextLabel={lastStep ? "Finish": "Next"}
                isNextDisabled={!isFormValid}
            />
        </OnboardingStepWrapper>
    );
}

export default OnboardingWorkStep;
