import React, {PureComponent} from "react";
import {connect} from "react-redux";
import ModalComponent from "./ModalComponent";
import SelectComponent from "../SelectComponent";
import { editUser } from "../../../api/student/users";
import SvgEdit from "../../../components/icons/SvgEdit";
import SvgAddCircle from "../../../components/icons/SvgAddCircle";
import MultiSelectComponent from "../MultiSelectComponent";
import { MAJOR_OPTIONS } from "../../../utils/dummy";
import Dropzone from 'react-dropzone'
import { wait } from "@testing-library/user-event/dist/utils";
import {uploadDoc} from "../../../api/firebase";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        }
    }
}

class EditProfileModal extends PureComponent {
    state = {
        firstName: "",
        lastName: "",
        tagline: "",
        city: "",
        bio: "",
        state: "",
        gpa: "",
        majors: [],
        profilePicUrl: "",
        error: false,
        errorMessage: true,
    }
    firstNameChanged = e => this.setState({firstName: e.target.value});
    lastNameChanged = e => this.setState({lastName: e.target.value});
    taglineChanged = e => this.setState({tagline: e.target.value});
    cityChanged = e => this.setState({city: e.target.value});
    stateChanged = e => this.setState({state: e.target.value});
    bioChanged = e => this.setState({bio: e.target.value});
    gpaChanged = e => this.setState({gpa: e.target.value});

    updateMajors = (major) => {
        console.log('dddd', major)
        if (!major) {
            this.setState({majors: []})
            return
        }
        let newMajor = [...this.state.majors];
        if (this.state.majors.includes(major)) {
            const index = this.state.majors.indexOf(major);
            newMajor.splice(index, 1);
        } else {
            newMajor.push(major);
        }
        this.setState({majors: newMajor});
    }
   

    saveChanges = async () => {
        const updatedFields = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            headline: this.state.tagline,
            city: this.state.city,
            state: this.state.state,
            bio: this.state.bio,
            profile_pic_url: this.state.profilePicUrl,
            major: this.state.majors,
            gpa: this.state.gpa
        }
        const newUser = {...this.props.user, ...updatedFields};
        this.props.setUser(newUser);
        await editUser(this.props.authUser, updatedFields)
        this.props.closeModal();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            wait(30).then(() => {
                this.setInfo();
            })
        }
    }

    componentDidMount = () => {
        this.setInfo()
    }

    setInfo = () => {
        const user = this.props.user || {};
        this.setState({
            firstName: user.first_name,
            lastName: user.last_name,
            tagline: user.headline,
            city: user.city,
            state: user.state,
            bio: user.bio,
            profilePicUrl: user.profile_pic_url,
            gpa: user.gpa,
            majors: user.major,
        });
    }

    onDrop = async (acceptedFiles) => {
        const url = await uploadDoc("profile_pic/", this.props.user.username, acceptedFiles[0]);
        this.setState({profilePicUrl: url});
    }

    render() {
        const user = this.props.user || {};
        const saveEnabled = user.first_name !== this.state.firstName || user.last_name !== this.state.lastName || user.bio !== this.state.bio || user.headline !== this.state.tagline || user.city !== this.state.city || user.state !== this.state.state || user.profile_pic_url !== this.state.profile_pic_url
            || user.gpa !== this.state.gpa || user.major !== this.state.majors;
        return (
            <ModalComponent isOpen={this.props.isOpen}
                            width={800}
                            header='Edit Profile'
                            backgroundColor={'white'}
                            headerToggle
                            toggle={this.props.closeModal}
                            FooterComponent={
                                <div className='flex flex-row items-center justify-end gap-3'>
                                    <button onClick={this.props.closeModal} className='secondary-button body1-bold'>
                                        Cancel
                                    </button>
                                    <button onClick={this.saveChanges} disabled={!saveEnabled}
                                            className={`primary-button body1-bold ${!saveEnabled && 'opacity-50'}`}>
                                        Save Changes
                                    </button>
                                </div>
                            }
                            footerAlignment={'right'}
                            id={'edit-profile-modal'}
            >
                <div className='flex flex-col w-full gap-5 bg-white'>
                    <div className='flex flex-row items-center gap-3'>
                        {!this.state.profilePicUrl ?
                            <div
                                className='br-12 bg-neutral-200 flex flex-col items-center border border-neutral-200'
                                style={{width: 80, height: 80}}/>
                            :
                            <img alt={'user'} src={this.state.profilePicUrl}
                                    className='h-28 w-28 rounded-full rounded-full  border-2 border-white'
                                    style={{width: 80, height: 80}}/>
                        }

                        <Dropzone onDrop={this.onDrop}>
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps()} className='cursor-pointer'>
                                    <input {...getInputProps()} />
                                    {/* <div
                                        className='w-full flex flex-row items-center justify-center rounded-xl h-26 border border-neutral-300 border-dashed p-6 text-neutral-400 hover:bg-neutral-100'>
                                        <p className='body3 ml-2'>
                                            hi
                                        </p>
                                    </div> */}
                                    {!this.state.profilePicUrl ?
                                        <div
                                            className='text-indigo-500 cursor-pointer hover:text-indigo-800 body2-bold flex flex-row items-center gap-2'>
                                            <SvgAddCircle className={'w-4 h-4'}/> Add Image
                                        </div>
                                        :
                                        <div
                                            className='text-indigo-500 cursor-pointer hover:text-indigo-800 body2-bold flex flex-row items-center gap-2'>
                                            <SvgEdit className={'w-4 h-4'}/> Edit Image
                                        </div>
                                    }
                                </div>
                            )}
                        </Dropzone>
                        
                    </div>

                    <div className='flex flex-row justify-between items-center'>
                        <div className='flex flex-col gap-5 flex-1'>
                            <div className='flex flex-row items-center gap-3'>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        First Name
                                    </p>
                                    <input
                                        value={this.state.firstName}
                                        onChange={this.firstNameChanged}
                                        placeholder={'John'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Last Name
                                    </p>
                                    <input
                                        value={this.state.lastName}
                                        onChange={this.lastNameChanged}
                                        placeholder={'Doe'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    Tagline
                                </p>
                                <input
                                    value={this.state.tagline}
                                    onChange={this.taglineChanged}
                                    placeholder={'Your title, role, etc...'}
                                    className='input-light px-3 body2 base-black-50 w-full'
                                />
                            </div>
                            <div className='flex flex-row items-center gap-3'>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        City
                                    </p>
                                    <input
                                        value={this.state.city}
                                        onChange={this.cityChanged}
                                        placeholder={'City'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                                <div className="col-plain w-full" id={'edit-profile-modal'}>
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        State
                                    </p>
                                    <input
                                        value={this.state.state}
                                        onChange={this.stateChanged}
                                        placeholder={'State'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div>
                            <div className="col-plain w-full">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    About
                                </p>
                                <textarea
                                    value={this.state.bio}
                                    onChange={this.bioChanged}
                                    placeholder={'A little more about you...'}
                                    className='input-light text-sm px-3 body2 base-black-50 w-full pt-2 min-h-[82px]'
                                />
                            </div>
                            <div className='flex flex-row items-center gap-3'>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        GPA
                                    </p>
                                    <input
                                        value={this.state.gpa}
                                        onChange={this.gpaChanged}
                                        placeholder={'GPA'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                                <div className="col-plain w-full" id={'edit-profile-modal'}>
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Major
                                    </p>
                                    <MultiSelectComponent
                                        className={'flex-1'}
                                        label={'ie. Finance, Economics'}
                                        value={this.state.majors}
                                        light
                                        setValue={this.updateMajors}
                                        clearFilter={() => this.updateMajors(null)}
                                        options={MAJOR_OPTIONS}
                                        direction={'top'}
                                        node={document.getElementById('edit-profile-modal')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileModal)
