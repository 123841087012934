import React, {PureComponent} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import JobPage from "./components/pages/JobPage";
import CompanyPage from "./components/pages/CompanyPage";
import ClubPage from "./components/pages/ClubPage";
import AuthPage from "./components/pages/AuthPage";
import TrackPage from './components/pages/TrackPage';
import {editUser, getUser} from "./api/student/users";
import {getUserApplications} from './api/student/applications';
import {getUserTrackers} from './api/student/trackers';
import {getUserNetworks} from './api/student/networks';
import ProfilePage from "./components/pages/ProfilePage";
import PublicJobPage from './components/pages/PublicJobPage';
import NetworkingPage from "./components/pages/NetworkingPage";
import SvgLogo from "./components/icons/SvgLogo";
import WalkThroughModal from "./components/atoms/modals/WalkthroughModal";
import {getFavoritedStudents, getRecruiter} from "./api/recruiter/recruiter";
import {joinClub} from "./api/student/clubs";
import StudentProfilePage from "./components/pages/StudentProfilePage";
import 'react-toastify/dist/ReactToastify.css';
import ReceivedInviteModal from "./components/atoms/modals/ReceivedInviteModal";
import Discover from "./components/pages/Discover";
import RecruiterSideApp from "./RecruiterSideApp";
import CalendarPage from "./components/pages/CalendarPage";
import QuestionPage from "./components/pages/QuestionPage";
import EventPage from './components/pages/EventPage';
import ResourcesPage from './components/pages/ResourcesPage';
import {getCompany} from "./api/recruiter/companies";
import ToastComponent from './components/atoms/ToastComponent';
import ImageViewerModal from "./components/modals/ImageViewerModal";
import HomePage from "./components/pages/HomePage";
import TopNavbar from "./components/TopNavbar";
import JobsPage from './components/pages/JobsPage';
import AnimatedLogo from "./components/atoms/loading/AnimatedLogo";
import EventsPage from './components/pages/EventsPage';
import CompaniesPage from './components/pages/CompaniesPage';
import NetworksPage from './components/pages/NetworksPage';
import OnboardingMain from "./components/onboarding/OnboardingMain";
import SignupPage from './components/pages/SignupPage';
import SvgR from "./components/icons/SvgR";
import Marquee from "react-fast-marquee";
import PublicCompanyPage from './components/pages/PublicCompanyPage';
import {getConstants} from './api/constants';
import ClubAdminPortal from './ClubAdminPortal';
import ClubApplicationPage from "./components-club-admin/pages/ClubApplicationPage";
import ClubStudentPeek from './components-club-admin/peeks/ClubStudentPeek';
import PublicEventPage from './components/pages/PublicEventPage';
import PersonalizationModal from './components/atoms/modals/PersonalizationModal';
import NUXTopBar from './components/NUX/NUXTopBar';
import PreApplyModal from './components/atoms/modals/PreApplyModal';
import { analytics } from './utils/june';

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
        setAuthUser: (user) => {
            dispatch({
                type: "SET_AUTH_USER",
                user,
            })
        },
        setApplications: (applications) => {
            dispatch({
                type: "SET_APPLICATIONS",
                applications,
            })
        },
        setNetworks: (networks) => {
            dispatch({
                type: "SET_NETWORKS",
                networks,
            })
        },
        setCompanyNetworks: (companyNetworks) => {
            dispatch({
                type: "SET_COMPANY_NETWORKS",
                companyNetworks,
            })
        },
        clearUser: () => {
            dispatch({
                type: "CLEAR_USER",
                user: null,
            })
        },
        setDomain: (domain) => {
            dispatch({
                type: "SET_DOMAIN",
                domain,
            })
        },
        setTier: (tier) => {
            dispatch({
                type: "SET_TIER",
                tier,
            })
        },
        setCandidates: (candidates) => {
            dispatch({
                type: "SET_CANDIDATES",
                candidates,
            })
        },
        setTrackers: (trackers) => {
            dispatch({
                type: "SET_TRACKERS",
                trackers,
            })
        },
        setConstants: (constants) => {
            dispatch({
                type: "SET_CONSTANTS",
                constants,
            })
        },
        closeStudentPeek: () => {
            dispatch({
                type: "CLOSE_STUDENT_PEEK",
            })
        },
        toggleClubAdminPortal: () => {
            dispatch({
                type: "TOGGLE_CLUB_ADMIN_PORTAL",
            })
        },
        setPersonalizationModalOpen: (isOpen) => {
            dispatch({
                type: "SET_PERSONALIZATION_MODAL_OPEN"
            });
        },
        setPersonalizationModalClosed: () => {
            dispatch({
                type: "SET_PERSONALIZATION_MODAL_CLOSED"
            });
        },
    }
}

function mapStateToProps(state) {
    return {
        userData: state.userReducer.user,
        domain: state.userReducer.domain,
        studentPeekOpen: state.userReducer.studentPeekOpen,
        studentPeekStudent: state.userReducer.studentPeekStudent,
        authUser: state.userReducer.authUser,
        clubAdminPortalOpen: state.userReducer.clubAdminPortalOpen,
        isPersonalizationModalOpen: state.userReducer.isPersonalizationModalOpen,
    }
}

class Routes extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            initializing: true,
            user: {},
            placeholder: false,
            inviteModalOpen: false,
            invitedClubId: null,
            path: "",
        }
    }

    componentDidMount() {
        this.userUpdated();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        const queryParameters = new URLSearchParams(window.location.search);
        if (queryParameters.get("clubId")) {
            this.setState({inviteModalOpen: true, invitedClubId: queryParameters.get("clubId")})
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.userUpdated();
            this.props.setDomain(window.location.host);
        }
    }

    resize() {
        this.setState({
            placeholder: window.innerWidth <= 760,
        });
    }

    markOnboardedTutorialViewed = async () => {
        this.setState({loading: true})
        await editUser(this.props.user, {viewed_onboarding_tutorial: true});
        this.setState({loading: false})
        this.userUpdated(false);
        this.openPersonalizationModal();
    }

    closePersonalizationModal = () => {
        this.props.setPersonalizationModalClosed();
    }

    openPersonalizationModal = () => {
        this.props.setPersonalizationModalOpen();
    }

    userUpdated = async (rerender = true) => {
        if (this.props.user !== 0) {
            if (this.props.user) {
                if (rerender) {
                    this.setState({initializing: true})
                }
                this.props.setAuthUser(this.props.user);

                const res = await getConstants();
                this.props.setConstants(res.constants);

                if (this.props.user.userType === "recruiter") {
                    const user = await getRecruiter(this.props.user);
                    this.props.setUser(user.recruiter);

                    const res = await getCompany(this.props.user);
                    this.props.setTier(res.company.tier);

                    const candidates = await getFavoritedStudents(this.props.user);
                    this.props.setCandidates(Object.values(candidates));
                    this.setState({initializing: false});
                } else {
                    let user = await getUser(this.props.user);
                    const userClubIds = user.user.clubs.map(club => club.id)

                    if (this.props.domain.startsWith("blkcapitalmanagement") && !userClubIds.includes("baeZVpmndkm8fCNGFIuZ")) {
                        await joinClub(this.props.user, "baeZVpmndkm8fCNGFIuZ");
                        user = await getUser(this.props.user);
                    }

                    this.props.setUser(user.user);

                    const applications = await getUserApplications(this.props.user);
                    this.props.setApplications(applications.applications);

                    const networks = await getUserNetworks(this.props.user);
                    this.props.setNetworks(networks.networks);
                    this.props.setCompanyNetworks(networks);

                    const trackers = await getUserTrackers(this.props.user);
                    if (!!trackers) {
                        this.props.setTrackers(trackers.trackers);
                    }

                    if (window.location.pathname.startsWith("/clubadmin")) {
                        this.props.toggleClubAdminPortal();
                    }

                    this.setState({initializing: false});
                }

                this.setAnalytics(this.props.userData, this.props.user.userType);
            } else {
                this.props.clearUser();
                this.setState({initializing: false});
            }
        }
    }

    setAnalytics = (userData, type) => {
        const analyticsObj = {
            firstName : userData?.first_name,
            lastName: userData?.last_name,
            name: `${userData?.first_name} ${userData?.last_name}`,
            email: `${userData?.email}`,
            clubs: (userData?.clubs || []).map(club => ({
                name: club.name,
                isExec: Object.keys(club.leadership).includes(userData?.id),
                isPointOfContact: club?.points_of_contact.map(poc => poc.email).includes(userData?.email),
            })),
            classYear: userData?.grade,
            school: userData?.college,
            major: userData?.major
        };
        window.analytics.identify(userData?.id, analyticsObj);

        if (type === "student") {
            analyticsObj.clubIds = userData?.clubs.map(club => club.id).join(",");
            analyticsObj.clubNames = userData?.clubs.map(club => club.name).join(",");
            analyticsObj.major = userData?.major.join(",");
            analyticsObj.gpa = userData.gpa;
            analyticsObj.jobSearchStatus = userData.job_status;
            analyticsObj.industries = userData?.industries.join(",");
            analyticsObj.locations = userData?.locations.join("|");
            analyticsObj.rolePreferences = userData?.role_preferences?.join(",");
            analyticsObj.gender = userData?.diversity?.gender;
            analyticsObj.race = userData?.diversity?.race?.join(",");
            analyticsObj.gender = userData?.diversity?.gender;
            analyticsObj.authorizedToWork = userData?.diversity?.authorized;
            analyticsObj.disability = userData?.diversity?.disabled;
            analyticsObj.firstGeneration = userData?.diversity?.first_generation;
            analyticsObj.lgbtq = userData?.diversity?.lgbtq;
            analyticsObj.veteran = userData?.diversity?.veteran;
            analyticsObj.requireSponsorship = userData?.diversity?.require_sponsorship;
        }

        analyticsObj.type = type === "recruiter" ? "recruiter" : window.location.pathname.startsWith("/clubadmin") ? "clubadmin" : "student";
        window.heap.identify(userData?.id);
        window.heap.addUserProperties(analyticsObj);
    }

    openNuxDrawer = () => {
        this.setState({nuxDrawerOpen: true})
    }

    closeNuxDrawer = () => {
        this.setState({nuxDrawerOpen: false})
    }

    closeInviteModal = () => {
        this.setState({inviteModalOpen: false})
    }

    render() {
        const isBlk = this.props.domain.startsWith("blkcapitalmanagement");
        if (this.state.initializing) {
            return (
                <div className='min-h-[100vh] flex flex-col items-center justify-center'>
                    <div className='flex flex-col items-center'>
                        <div className='flex flex-row items-center scale-[.5]'>
                            <SvgR className={'-mr-1 mb-[2px] text-slate-900'}/>
                            <AnimatedLogo width={62} height={62} loading="true"/>
                        </div>
                        <Marquee direction={'right'} className='bg-slate-200 rounded-full mr-1' style={{width: 52}}>
                            <div className='h-2 w-2 rounded-full bg-slate-500'/>
                        </Marquee>
                    </div>
                </div>
            )
        }
        if (!this.props.user) {
            return (
                <Switch>
                    <Route path="/authentication"
                           render={(props) => <AuthPage {...props} mobile={this.props.mobile}/>}/>
                    <Route path="/signup" render={(props) => <SignupPage {...props} />}/>
                    <Route exact path="/recruiter/authentication" render={(props) => <AuthPage {...props} recruiter/>}/>
                    <Route path="/job/:jobId"
                           render={(routeProps) =>
                               <PublicJobPage {...routeProps}/>
                           }/>
                    <Route path="/company/:companyId"
                           render={(routeProps) =>
                               <PublicCompanyPage {...routeProps}/>
                           }/>
                    <Route path="/event/:eventId"
                           render={(routeProps) =>
                               <PublicEventPage {...routeProps}/>
                           }/>
                    <Route
                        path="/club/:clubId/application/:appId"
                        render={props => (
                            <Redirect
                                to={`/signup?path=club/${props.match.params.clubId}/application/${props.match.params.appId}`}/>
                        )}
                    />
                    <Route path="/onboarding"
                           render={(routeProps) =>
                               <OnboardingMain {...routeProps}/>
                           }/>
                    <Redirect to="/authentication"/>
                </Switch>
            )
        }

        if (this.state.placeholder && !!this.props.user && (this.props.userData?.onboarded || this.props.user.userType === "recruiter")) {
            return (
                <div className='w-full h-full bg-primary flex flex-col items-center justify-center gap-9'>
                    <SvgLogo className={'w-2/3 text-white'}/>
                    <div className='flex flex-col gap-3 items-center text-center px-[20vw]'>
                        <p className='title2 text-white'>
                            You're all set! Continue on your computer... 💻
                        </p>
                        <p className='text-white text-opacity-50 body2'>
                            We can’t wait to show you all that RecruitU has to offer! Log in on a computer to begin using our platform.
                        </p>
                    </div>
                </div>
            )
        }


        // if (this.props.userData?.waitlisted) {
        //     return (
        //         <Switch>
        //             <Route exact path="/waitlist" render={(props) => <WaitlistPage {...props} />}/>
        //             <Redirect to="/waitlist"/>
        //         </Switch>
        //     )
        // }

        if (!!this.props.userData?.email && !this.props.userData?.onboarded && this.props.user.userType !== "recruiter") {
            return (
                <Switch>
                    <Route
                        exact
                        path="/onboarding/job/:id"
                        render={props => (
                            <Redirect to={`/onboarding?path=job/${props.match.params.id}`}/>
                        )}
                    />
                    <Route
                        exact
                        path="/onboarding/company/:id"
                        render={props => (
                            <Redirect to={`/onboarding?path=company/${props.match.params.id}`}/>
                        )}
                    />
                     <Route
                        exact
                        path="/onboarding/event/:id"
                        render={props => (
                            <Redirect to={`/onboarding?path=event/${props.match.params.id}`}/>
                        )}
                    />
                    {/* <Route path="/onboarding"
                           render={(routeProps) =>
                               <Onboarding {...routeProps}/>
                           }/>
                    <Route exact path="/linkedin/callback"
                           render={(routeProps) =>
                               <OnboardingPage {...routeProps}
                                               mobile={this.state.mobile}
                                               tablet={this.state.tablet}/>
                           }/> */}
                    <Route path="/onboarding"
                           render={(routeProps) =>
                               <OnboardingMain {...routeProps}/>
                           }/>
                    <Redirect to="/onboarding"/>
                </Switch>
            )
        }

        if (this.props.user.userType === "recruiter") {
            return (
                <RecruiterSideApp/>
            )
        }


        if (this.props.clubAdminPortalOpen) {
            return (
                <ClubAdminPortal/>
            )
        }

        const user = this.props.userData || {};
        const nux = user.nux || {};
        const hideNux = (nux.add_application && nux.apply_job && nux.add_contact && nux.update_contact) || isBlk;
        
        const showTopBanner = !user?.completed_personalization && user?.userType !== "recruiter";
        
        const queryParameters = new URLSearchParams(window.location.search);
        const path = queryParameters.get("path");
        if (path) {
            window.history.pushState({}, '', '/'+ path);
        }

        return (
            <div className='w-full h-full flex flex-col relative' id={'main'}>
                {/*{hideNux ? null : <NUXTopBar open={!this.state.nuxDrawerOpen} openNuxDrawer={this.openNuxDrawer}/>}*/}
                {showTopBanner && (
                    <NUXTopBar open={showTopBanner} openNuxDrawer={this.openPersonalizationModal}/>
                )}
                <TopNavbar mobile={this.state.mobile} showTopBanner={showTopBanner} />
                {/*<NUXDrawer open={this.state.nuxDrawerOpen} closeDrawer={this.closeNuxDrawer}*/}
                {/*           user={this.props.userData}/>*/}

                <PreApplyModal />

                <PersonalizationModal 
                    isOpen={this.props.isPersonalizationModalOpen}
                    closeModal={this.closePersonalizationModal}
                    skipSteps
                />

               
                <div className={`flex flex-col flex-1 ${showTopBanner ? 'mt-[108px]' : 'mt-[60px]'} overflow-y-scroll hide-scrollbar`}>
                    <Switch>
                        <Route exact path="/"
                               render={(routeProps) =>
                                   <HomePage {...routeProps}
                                        openPersonalizationModal={this.openPersonalizationModal} 
                                    />
                               }/>
                        <Route exact path="/calendar"
                               render={(routeProps) =>
                                   <CalendarPage {...routeProps}/>
                               }/>
                        <Route path="/job"
                               render={(routeProps) =>
                                   <JobPage {...routeProps}/>
                               }/>
                        <Route path="/jobs"
                               render={(routeProps) =>
                                   <JobsPage {...routeProps}/>
                               }/>
                        <Route path="/company"
                               render={(routeProps) =>
                                   <CompanyPage {...routeProps}/>
                               }/>
                        <Route exact path="/networking"
                               render={(routeProps) =>
                                   <NetworkingPage {...routeProps}/>
                               }/>
                        <Route exact path="/discover"
                               render={(routeProps) =>
                                   <Discover {...routeProps}/>
                               }/>
                        <Route exact path="/saved"
                               render={(routeProps) =>
                                   <TrackPage {...routeProps}/>
                               }/>
                        <Route exact path="/club/:clubId?"
                               render={(routeProps) =>
                                   <ClubPage {...routeProps}/>
                               }/>
                        <Route exact path="/profile"
                               render={(routeProps) =>
                                   <ProfilePage {...routeProps}/>
                               }/>
                        <Route exact path="/studentprofile"
                               render={(routeProps) =>
                                   <StudentProfilePage {...routeProps}/>
                               }/>
                        <Route path="/event/:eventId"
                               render={(routeProps) =>
                                   <EventPage {...routeProps}/>
                               }/>
                        <Route path="/events"
                               render={(routeProps) =>
                                   <EventsPage {...routeProps}/>
                               }/>
                        <Route path="/companies"
                               render={(routeProps) =>
                                   <CompaniesPage {...routeProps}/>
                               }/>
                        <Route path="/network"
                               render={(routeProps) =>
                                   <NetworksPage {...routeProps}/>
                               }/>
                        <Route exact path="/learn"
                               render={(routeProps) =>
                                   <ResourcesPage {...routeProps}/>
                               }/>
                        <Route exact path="/question/:questionId"
                               render={(routeProps) =>
                                   <QuestionPage {...routeProps}/>
                               }/>
                        <Route exact path="/club/:clubId/application/:applicationId"
                               render={(routeProps) =>
                                   <ClubApplicationPage {...routeProps}/>
                               }/>
                        <Redirect to={!!path ? "/" + path : "/"}/>
                    </Switch>
                </div>
                <ImageViewerModal/>
                <WalkThroughModal markOnboardingTutorialViewed={this.markOnboardedTutorialViewed}
                                  loading={this.state.loading}
                                  isOpen={!this.props.userData?.viewed_onboarding_tutorial}/>
                <ReceivedInviteModal authUser={this.props.user} isOpen={this.state.inviteModalOpen}
                                     invitedClubId={this.state.invitedClubId} closeModal={this.closeInviteModal}/>
                <ClubStudentPeek
                    authUser={this.props.authUser}
                    student={this.props.studentPeekStudent}
                    close={this.props.closeStudentPeek}
                    open={this.props.studentPeekOpen}
                    hideStats
                />
                <ToastComponent/>
            </div>
        );
    }
}

Routes.propTypes = {
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);