import React, {PureComponent} from "react";
import {connect} from "react-redux";
import ModalComponent from "./ModalComponent";
import Datepicker from "react-tailwindcss-datepicker";
import {primary} from "../../../utils/colors";
import SvgCheck from "../../icons/SvgCheck";
import {uploadDoc} from "../../../api/firebase";
import Dropzone from "react-dropzone";
import SvgPostAdd from "../../icons/SvgPostAdd";
import { editUser } from "../../../api/student/users";
import SvgDocument from "../../icons/SvgDocument";
import { ThickArrowLeftIcon } from "@radix-ui/react-icons";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        }
    }
}
class EditProfileOtherModal extends PureComponent {
    state = {
        linkedin: '',
        resume: '',
        buttonMessage: "Drop Resume",
        error: false,
        errorMessage: true,
    }
    linkedinChanged = e => this.setState({linkedin: e.target.value});


    saveChanges = async () => {
        const updatedFields = {
            linkedin: this.state.linkedin,
            resume: this.state.resume,
        }
        const newUser = {...this.props.user, ...updatedFields};
        this.props.setUser(newUser);
        await editUser(this.props.authUser, updatedFields)
        this.props.closeModal();
    }

    onDrop = async (acceptedFiles) => {
        this.setState({buttonMessage: "Resume uploaded!"});
        const url = await uploadDoc("resumes", this.props.user.username, acceptedFiles[0]);
        this.setState({resume: url, buttonMessage: "Resume uploaded!"});
    }

    componentDidMount = () => {
        const user = this.props.user || {};
        this.setState({
            linkedin: user.linkedin,
            resume: user.resume,
        });
    }

    render() {
        const user = this.props.user || {};
        const saveEnabled = user.linkedin !== this.state.linkedin || user.resume !== this.state.resume;
        return (
            <ModalComponent isOpen={this.props.isOpen}
                            width={800}
                            header={`Edit Your Profile`}
                            backgroundColor={'white'}
                            headerToggle
                            toggle={this.props.closeModal}
                            FooterComponent={
                                <div className='flex flex-row items-center justify-end gap-3'>
                                    <button onClick={this.props.closeModal} className='secondary-button body1-bold'>
                                        Cancel
                                    </button>
                                    <button onClick={this.saveChanges} disabled={!saveEnabled}
                                            className={`primary-button body1-bold ${!saveEnabled && 'opacity-50'}`}>
                                        Save Changes
                                    </button>
                                </div>
                            }
                            footerAlignment={'right'}
                            id={'edit-profile-modal'}
            >
                <div className='flex flex-col w-full gap-5 bg-white'>
                    <div className='flex flex-row justify-between items-center'>
                        <div className='flex flex-col gap-5 flex-1'>
                            <div className='flex flex-row items-center gap-3'>
                                <div className="col-plain w-full">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Linkedin URL
                                    </p>
                                    <input
                                        value={this.state.linkedin}
                                        onChange={this.linkedinChanged}
                                        placeholder={'https://www.linkedin.com'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div>
                            <div>
                                {!!user.resume ?
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Current Resume
                                        </p>
                                        <a href={user.resume} className='flex flex-row items-center gap-2 text-slate-500 hover:text-slate-800 '>
                                            <SvgDocument className={'w-6 h-6'}/>
                                            <p className='cursor-pointer body2-bold'>
                                                View
                                            </p>
                                        </a>
                                    </div>
                                : null}
                            </div>
                            <div className=''>
                                <Dropzone onDrop={this.onDrop}>
                                    {({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()} className='cursor-pointer'>
                                            <input {...getInputProps()} />
                                            <div
                                                className='w-full flex flex-row items-center justify-center rounded-xl h-26 border border-neutral-300 border-dashed p-6 text-neutral-400 hover:bg-neutral-100'>
                                                <SvgPostAdd width={24} height={24}/>
                                                <p className='body3 ml-2'>
                                                    {this.state.buttonMessage}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileOtherModal)
