import React, {useState, useEffect} from 'react';
import SvgUsers from "../components/icons/SvgUsers";
import ProfilePicture from "../components-recruiter/atoms/ProfilePicture";
import CircularProgress from "./atoms/CircularProgress";
import RecruitPremium from './atoms/RecruitPremium';


function ReferralWidget({user}) {
    const [inviteLink, setInviteLink] = useState("https://app.recruitu.com/authentication?activeTab=signup&referred=" + user.username);
    const [copyStatus, setCopyStatus] = useState('idle'); // 'idle', 'copied'
    const referrals = user.referrals || [];

    const handleCopyLink = () => {
        navigator.clipboard.writeText(inviteLink)
            .then(() => setCopyStatus('copied'))
            .catch(() => setCopyStatus('idle'));

        setTimeout(() => setCopyStatus('idle'), 3000); // Reset after 3 seconds
    };

    return (
        <div
            className='bg-primary flex-[2] flex flex-row gap-8 shadow-lg shadow-slate-200 p-5 text-white border border-slate-300 rounded-xl'>
            <div className='flex flex-col flex-[.8]'>
                <div className='flex flex-row items-center gap-2'>
                    <SvgUsers className={'w-5 h-5'}/>
                    <p className='text-xl font-semibold'>
                        Your Referrals
                    </p>
                </div>
                <RecruitPremium user={user} />
            </div>
            <div className='flex flex-col flex-[.5]'>
                <div className='ml-auto flex flex-row items-center gap-2'>
                    <p className='text-lg font-bold'>
                        <span className='text-2xl'>{referrals.length}</span> / 3
                    </p>
                    <CircularProgress value={referrals.length} target={3} size={36}/>
                </div>
                <div className='flex flex-col bg-white/10 rounded-xl p-3 gap-2 mt-1 flex-1'>
                    <p className='text-md font-medium'>Completed referrals ({referrals.length})</p>
                    {referrals.length === 0 ? (
                        <p className='opacity-50 text-sm'>No referrals yet.</p>
                    ) : (
                        <ul className='flex flex-col gap-2 max-h-[150px] overflow-y-scroll'>
                            {referrals.map((referral, index) => (
                                <li key={index} className='flex flex-row items-center gap-2 font-medium'>
                                    <ProfilePicture size={32} id={referral.username}
                                                    className={'border border-white/20'}/>
                                    {referral.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ReferralWidget;
