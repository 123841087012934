import React, {PureComponent} from "react";
import {NAV_ITEMS} from "../utils/dummy";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import NewClubModal from "./atoms/modals/NewClubModal";
import ClubSumbitSuccess from "./atoms/modals/ClubSumbitSuccess";
import {wait} from "@testing-library/user-event/dist/utils";
import TopNavItem from "./navigation/TopNavItem";
import SvgLogoPrimary from "./icons/SvgLogoPrimary";
import SvgBell from "./icons/SvgBell";
import SvgBriefcase from "./icons/SvgBriefcase";
import TooltipComponent from "./atoms/TooltipComponent";
import NotificationsPanel from "./notifications/NotificationsPanel";
import SelectComponentBody from "./atoms/SelectComponentBody";
import DropdownComponent from "./atoms/DropdownComponent";
import ModalComponent from "./atoms/modals/ModalComponent";
import {Button} from "./atoms/Button";
import SvgExitToApp from "./icons/SvgExitToApp";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        domain: state.userReducer.domain,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
        toggleClubAdminPortal: () => {
            dispatch({
                type: "TOGGLE_CLUB_ADMIN_PORTAL",
            })
        }
    }
}

class Navbar extends PureComponent {
    state = {
        newClubModalVisible: false,
        clubSubmitSuccessVisible: false,
        clubSelectionModalVisible: false,
        navbarOpen: false,
        forceClosed: false,
        activeRoute: this.props.location.pathname,
        lastRoute: '/'
    }

    componentDidMount() {
        this.checkRoute()
    }

    componentDidUpdate(prevProps) {
        // Check if the current route path differs from the previous one
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.checkRoute()
        }
    }

    checkRoute = () => {
        this.setState({activeRoute: this.props.location.pathname});
        const path = this.props.location.pathname
        if (path === '/discover' || path === '/' || path === '/learn' || path === '/saved' || path === '/network' || path === '/events' || path === '/jobs' || path === '/companies') {
            this.setState({lastRoute: path})
        } else if (path.includes('/club') || path.includes('/profile')) {
            this.setState({lastRoute: ''})
        }
    }

    closeNewClubModal = () => {
        this.setState({newClubModalVisible: false})
    }

    closeClubSubmitSuccesfulModal = () => {
        this.setState({clubSubmitSuccessVisible: false})
    }

    submitClub = () => {
        this.closeNewClubModal()
        wait(300).then(() =>
            this.setState({clubSubmitSuccessVisible: true})
        )
    }

    openClubSelectionModal = () => {
        this.setState({clubSelectionModalVisible: true});
    }

    closeClubSelectionModal = () => {
        this.setState({clubSelectionModalVisible: false});
    }

    // navToClub = (clubName) => {
    //     if (clubName !== "New Club") {
    //         const selectedClub = this.props.user.clubs.find(club => club.name === clubName);
    //         this.props.history.push({
    //             pathname: `/club`,
    //             state: {
    //                 club_id: selectedClub.id,
    //             }
    //         })
    //     }
    //
    // }

    render() {
        const clubs = this.props.user.clubs || [];
        const admin = this.props.user.admin || [];
        const formattedClubs = clubs.map(club => {
            return {label: club.name, id: club.id, img: club.logo_url, linkToClub: true}
        });
        // formattedClubs.push({
        //     label: 'New Club',
        //     icon: SvgAddCircle,
        //     onClick: () => {
        //         this.setState({newClubModalVisible: true});
        //     }
        // })
        const user = this.props.user || {};
        const profilePic = user.profile_pic_url ? user.profile_pic_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8";
        return (
            <div
                id={'navbar'}
                className={`flex flex-row items-center bg-white h-[60px] px-6 z-[999] border-b border-slate-200 absolute ${this.props.showTopBanner ? 'top-[48px]' : 'top-0'} right-0 left-0`}>
                {(this.props.domain.startsWith("blkcapitalmanagement")) ?
                    <Link to={'/'} className={'cursor-pointer flex flex-row items-center'}>
                        <SvgLogoPrimary style={{height: 32, width: 80}}/>
                        {(this.state.navbarOpen && !this.state.forceClosed) &&
                            <p className='font-semibold'>
                                BLK Capital
                            </p>
                        }
                    </Link>
                    :
                    <Link to={'/'} className={'cursor-pointer'}>
                        <SvgLogoPrimary style={{height: 36, width: 120}}/>
                    </Link>
                }
                <ul className='flex flex-row ml-5 gap-1 lg:ml-14'>
                    {(NAV_ITEMS).map(item =>
                        <TopNavItem
                            item={item}
                            key={item.label}
                            isActive={this.state.lastRoute === item.route}
                        />
                    )}
                </ul>
                {formattedClubs.length > 0 && formattedClubs.length <= 5 ? (
                    <ul className='flex flex-row items-center ml-auto bg-primary/5 pl-[16px] overflow-hidden rounded-lg mr-3 gap-0'>
                        <p className={'text-sm font-semibold mr-3'}>
                            Groups:
                        </p>
                        <div className='flex flex-row max-w-[210px] overflow-hidden hide-scrollbar'>
                            {formattedClubs.map(item =>
                                <TopNavItem
                                    item={item}
                                    key={item.label}
                                />
                            )}
                        </div>
                    </ul>
                ) : (formattedClubs.length > 0 && (
                    <button
                        className="ml-auto"
                        onClick={this.openClubSelectionModal}
                    >
                        <TopNavItem
                            item={{label: "View clubs"}}
                            key={"View clubs"}
                        />
                    </button>
                ))}
                {admin.length > 0 ?
                    <div onClick={this.props.toggleClubAdminPortal}
                         className={'py-[9px] flex relative flex-row items-center rounded-lg cursor-pointer'}>
                        <TopNavItem
                            item={{label: "Admin Portal", icon:SvgExitToApp}}
                            key={"Admin Portal"}
                        />
                    </div>
                    :
                    <div className='ml-auto'/>
                }
                <div className='border-r border-slate-200 mx-3 h-3/4'/>
                <ul className='flex flex-row items-center'>
                    <TooltipComponent
                        ToggleComponent={
                            <div
                                className={'py-[9px] px-4 flex relative flex-row items-center hover:bg-primary/10 rounded-lg cursor-pointer'}>
                                <SvgBell className={'w-6 h-6'}/>
                                {/*Todo:add badge*/}
                                {user.unread_notification &&
                                    <div
                                        className='rounded-full bg-red-400 px-1 min-w-[16px] text-center text-white font-semibold text-xs absolute top-0 right-3'>
                                        1
                                    </div>
                                }
                            </div>
                        }
                        placement={'bottom-end'}
                        node={document.getElementById('navbar')}
                        ContentComponent={
                            <NotificationsPanel setUser={this.props.setUser} user={this.props.user}
                                                authUser={this.props.authUser}/>
                        }
                    />
                    <TopNavItem
                        item={{
                            img: profilePic,
                            user: user,
                            route: '/profile'
                        }}
                        profile
                    />
                </ul>
                <NewClubModal open={this.state.newClubModalVisible} submitClub={this.submitClub}
                              close={this.closeNewClubModal}/>
                <ClubSumbitSuccess open={this.state.clubSubmitSuccessVisible}
                                   close={this.closeClubSubmitSuccesfulModal}/>
                {/* Club Selection Modal */}
                <ModalComponent
                    backgroundColor={'white'}
                    header={'Select a group to view'}
                    headerToggle
                    isOpen={this.state.clubSelectionModalVisible} toggle={this.closeClubSelectionModal}>
                    <div className="p-4 max-h-[50vh] overflow-y-scroll">
                        {formattedClubs.map(club => (
                            <div key={club.id} className="mb-2">
                                <Link to={{pathname: '/club/' + club.id, state: {club_id: club.id}}}
                                      onClick={this.closeClubSelectionModal}
                                      className="block py-2 px-4 bg-gray-100 hover:bg-gray-200 rounded-lg">
                                    {club.label}
                                </Link>
                            </div>
                        ))}
                    </div>
                </ModalComponent>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
