import React, {Component} from "react";


class PageRightWrapper extends Component {
    render() {
        return (
            <div className={`pr-3 ${this.props.open ? 'mr-2 w-[370px] xl:w-[420px]' : 'mr-[-16px]'} py-2 flex flex-col`}
                 style={{
                     width: !this.props.open && 0,
                     opacity: this.props.open ? 1 : 0,
                     ...this.props.style,
                     transition: 'all 150ms ease-in-out',
                 }}>
                <div className='flex flex-col flex-1 overflow-hidden'>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default PageRightWrapper
