import React from "react";
import ModalComponent from "./ModalComponent";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
    isOpen: state.userReducer.isPreApplyModalOpen,
});

function mapDispatchToProps(dispatch) {
    return {
        setPreApplyModalOpen: () =>
            dispatch({ type: "SET_PRE_APPLY_MODAL_OPEN" }),
        setPreApplyModalClosed: () =>
            dispatch({ type: "SET_PRE_APPLY_MODAL_CLOSED" }),
        setPersonalizationModalOpen: () =>
            dispatch({ type: "SET_PERSONALIZATION_MODAL_OPEN" }),
    };
}

function PreApplyModal({
    isOpen,
    setPreApplyModalOpen,
    setPreApplyModalClosed,
    setPersonalizationModalOpen,
}) {
    function toggleModal() {
        if (isOpen) {
            setPreApplyModalClosed();
        } else {
            setPreApplyModalOpen();
        }
    }
    function handleCTAClick() {
        setPersonalizationModalOpen();
        toggleModal();
    }
    return (
        <ModalComponent
            isOpen={isOpen}
            noPadding
            width={800}
            noMaxHeight
            backgroundColor={"white"}
            toggle={toggleModal}
            id={'pre-apply-modal'}
        >
            <div className="flex flex-col items-center justify-center text-center py-12 px-8">
                <h1 className="text-2xl font-bold mb-6">Unlock Your Job Search Potential!</h1>
                <p className="text-lg mb-8">Complete your profile to apply for jobs</p>
                <button 
                    className="bg-primary text-white font-bold py-3 px-8 rounded-full text-xl hover:bg-primary-dark transition duration-300"
                    onClick={handleCTAClick}
                >
                    Complete My Profile
                </button>
            </div>
        </ModalComponent>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PreApplyModal);
