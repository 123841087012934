import React, {PureComponent} from "react";
import SvgAddCircle from "../icons/SvgAddCircle";
import Tooltip from "../atoms/modals/Tooltip";
import {connect} from "react-redux";
import {createRecruiterNetwork} from "../../api/recruiter/recruiter_networks";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        recruiterNetworks: state.userReducer.recruiterNetworks,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateRecruiterNetwork: (recruiterNetwork) => {
            dispatch({
                type: "UPDATE_RECRUITER_NETWORK",
                recruiterNetwork,
            })
        },
    }
}
class UntrackedStudentStatusComponent extends PureComponent {
    state = {
        tracking: false
    }


    addNetwork = async () => {
        this.setState({tracking: true});
        if (Object.keys(this.props.recruiterNetworks).includes(this.props.student.username)) {
            throw new Error("Already have a network with this user.")
        }
        const recruiterNetwork = await createRecruiterNetwork(this.props.authUser, {candidate_id: this.props.student.username});
        this.props.updateRecruiterNetwork(recruiterNetwork.recruiter_network);
    }


    render() {
        return (
            <>
                {Object.keys(this.props.recruiterNetworks).includes(this.props.student.username) ?
                    <button className={`secondary-pill-sm bg-primary pointer text-white hover:text-white ${this.props.sm ? 'body4-bold' : 'body3-bold '}`} disabled={true}>
                        Saved
                    </button>
                    :
                    <Tooltip
                        toggleComponent={
                            <button onClick={() => this.addNetwork()}
                                    className={`secondary-pill-sm ${this.props.sm ? 'body4-bold' : 'body3-bold'}`}
                            >
                                <SvgAddCircle className={`${this.props.sm ? 'w-4 h-4' : 'w-5 h-5'} mr-1`}/>
                                Save
                            </button>
                        }
                        label={'Add this student to your Student Tracker'}
                    />
                }
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UntrackedStudentStatusComponent);

