import React, {PureComponent} from "react";
import SvgSchool from "../components/icons/SvgSchool";
import StudentClubLogo from "../components/atoms/StudentClubLogo";
import { Button } from "./atoms/Button";
import Checkbox from "./atoms/Checkbox";

class StudentClubListItem extends PureComponent {
    state = {
        isAdmin: false,
    }

    checkAdmin = () => {
        this.setState({isAdmin: !this.state.isAdmin});
        this.props.addAdmin(this.props.club);
    }

    componentDidMount = () => {
        this.isLeadership();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.club !== prevProps.club) {
            this.isLeadership();
        }
    }

    isLeadership = () => {
        const club = this.props.club || {};
        const leadership = club?.leadership || {};
        this.setState({isAdmin: this.props.username in leadership})
    }

    render() {
        const club = this.props.club || {};
        const leadership = club?.leadership || {};
        const inClub = this.props.userClubs.includes(club.id) 
        return (
            <div
                className={`flex flex-row justify-between bg-white cursor-pointer hover:bg-white/25 shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl ${this.props.grid && 'mb-0 h-full'}`}
                style={{border: `1px solid ${this.props.previewingID === club.id ? '#4848F2' : '#00000020'}`}}
                key={club.id}>
                <div
                    className={`flex ${this.props.grid ? 'flex-row gap-2 items-center' : 'flex-row items-center'}`}>
                    {!this.props.hideLogo &&
                        <StudentClubLogo clubId={club.id} size={this.props.grid ? 64 : 88} className={'border border-slate-200'}/>
                    }
                    <div className={`${this.props.grid ? 'mt-0' : (!this.props.hideLogo && 'ml-3')} col-jc`}>
                        <div className='mr-2 flex flex-col gap-2'>
                            <p className='body1-bold m-0 text-gray-800'>
                                {club.name}
                            </p>
                            <div className='flex flex-row items-center text-neutral-400 gap-1'>
                                <SvgSchool className={'w-4 h-4'}/>
                                <p className='body2 m-0'>
                                    {club.college}&nbsp; {!!club.founded  && `• Est. ${club.founded}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`flex mt-12 ${this.props.grid ? 'flex-row gap-2' : 'flex-row items-center'}`}>
                    {!!this.props.addAdmin &&
                        <div className='mr-20'>
                            <Checkbox
                                checked={this.state.isAdmin}
                                onClick={this.checkAdmin}
                                label={'Executive'}
                            />
                        </div>
                    }
                    <Button variant={'default'} onClick={() => this.props.addRemoveClub(club.id)} className={`${inClub ? 'opacity-50' : ''}`}>
                        {inClub ? "Leave" : club.private ? "Request" : "Join"} 
                    </Button>
                </div>
            </div>
        )
    }
}

export default StudentClubListItem;
