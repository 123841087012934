import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import {editCompany} from "../../api/recruiter/companies";
import {connect} from "react-redux";
import {Button} from "../../components/atoms/Button";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}
class RecruiterCompanyEditOverview extends Component {
    state = {
        headquarters: "",
        numLocations: 0,
        numJobOpenings: 0,
    }

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    save = async () => {
        const params = {
            headquarters: this.state.headquarters,
            num_locations: this.state.numLocations,
            num_job_openings: this.state.numJobOpenings,
        }
        await editCompany(this.props.authUser, params);
        this.props.closeModal();
        window.location.reload()
        window.analytics.track("Recruiter Company Edit Overview");
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.company !== prevProps.company) {
            this.setInfo();
        }
    }

    componentDidMount = () => {
        this.setInfo();
    }

    setInfo = () => {
        const company = this.props.company || {};
        this.setState({
            headquarters: company.headquarters,
            numLocations: company.num_locations,
            numJobOpenings: company.num_job_openings,
        })
    }

    render() {
        const company = this.props.company || {};
        const saveEnabled = company.num_locations !== this.state.numLocations || company.headquarters !== this.state.headquarters || company.num_job_openings !== this.state.numJobOpenings;
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit company overview'}
                headerToggle
                size={'md'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.props.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={!saveEnabled}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className='flex flex-col gap-5'>
                    <WrappedTextInput
                        type="number"
                        outerLabel={'Number of locations'}
                        placeholder={'--'}
                        className='input-light px-3 w-full'
                        value={this.state.numLocations}
                        onChange={(e) => this.handleInputChange(e, 'numLocations')}
                    />
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Headquarters'}
                        placeholder={'--'}
                        className='input-light px-3 w-full'
                        value={this.state.headquarters}
                        onChange={(e) => this.handleInputChange(e, 'headquarters')}
                    />
                    <WrappedTextInput
                        type="number"
                        outerLabel={'Job Openings'}
                        placeholder={'--'}
                        className='input-light px-3 w-full'
                        value={this.state.numJobOpenings}
                        onChange={(e) => this.handleInputChange(e, 'numJobOpenings')}
                        subText={"Total number of active job listings on RecruitU"}
                    />
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RecruiterCompanyEditOverview);
