import React, {useEffect, useState} from "react";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import SvgMagic from "../icons/SvgMagic";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import {Button} from "../atoms/Button";
import CompanyResultItem from "../jobboard/CompanyResultItem";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../../utils/constants";
import PersonalizationModal from "../atoms/modals/PersonalizationModal";
import ChooseClubsModal from "../atoms/modals/ChooseClubsModal";
import ApplicationPeek from "../crm/ApplicationPeek";
import {editApplication} from "../../api/student/applications";
import SvgCompanyBuilding from "../icons/SvgCompanyBuilding";
import SvgUserCheck from "../icons/SvgUserCheck";
import SvgBriefcase from "../icons/SvgBriefcase";
import CompanyProgressOverview from "../CompanyProgressOverview";
import CompanyLogo from "../atoms/CompanyLogo";
import SvgCalendar from "../icons/SvgCalendar";
import NetworkStatusTrackerComponet from "../clubpage/NetworkStatusTrackerComponent";
import TagComponent from "../atoms/TagComponent";
import {homePagePersonSearch, searchCompanies, homePageJobSearch} from "../../utils/typesense";
import ProfilePicture from "../../components-recruiter/atoms/ProfilePicture";
import SvgZap from "../icons/SvgZap";
import LinksList from "../../components-recruiter/dashboard/LinksList";
import SvgSearchFeather from "../icons/SvgSearchFeather";
import SvgEdit2 from "../icons/SvgEdit2";
import SvgUserAdd from "../icons/SvgUserAdd";
import SvgLightbulb from "../icons/SvgLightbulb";
import SvgPlayVideo from "../icons/SvgPlayVideo";
import SvgCalendarDay from "../icons/SvgCalendarDay";
import SvgBug from "../icons/SvgBug";
import SvgUsers from "../icons/SvgUsers";
import ReferralWidget from "../ReferralWidget";
import ScrapedNetworkingPeek from "../networkingpage/ScrapedNetworkingPeek";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        applications: state.userReducer.applications,
        networks: state.userReducer.networks,
        trackers: state.userReducer.trackers
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
        deleteNetwork: (personId) => {
            dispatch({
                type: "DELETE_NETWORK",
                personId,
            })
        },
        updateApplication: (application) => {
            dispatch({
                type: "UPDATE_APPLICATION",
                application,
            })
        },
        updateTracker: (tracker) => {
            dispatch({
                type: "UPDATE_TRACKER",
                tracker,
            })
        }
    }
}

const links = [
    {
        label: 'RecruitU User Guide',
        icon: SvgLightbulb,
        // route: '/search',
        onClick: () => {
          window.analytics.track("Press Quick Link", {quick_link_name: "RecruitU User Guide"})
          window.open('https://recruitu.notion.site/RecruitU-Support-Guide-e601533fa2e94cd8ac93d072aeaf32a0', '_blank')
        }
    },
    {
        label: 'RecruitU Video Tutorial',
        icon: SvgPlayVideo,
        // route: '/saved-lists',
        onClick: () => {
          window.analytics.track("Press Quick Link", {quick_link_name: "RecruitU Video Tutorial"})
          toast.warning('Coming soon!', TOAST_OPTIONS)
        }
    },
    {
        label: 'Schedule 1-on-1 onboarding',
        icon: SvgCalendarDay,
        // route: '/jobs',
        onClick: () => {
          window.analytics.track("Press Quick Link", {quick_link_name: "Schedule 1-on-1 onboarding"})
          window.open('https://calendly.com/recruitu-support/15min', '_blank')
        }
    },
    {
        label: 'Report a bug',
        icon: SvgBug,
        onClick: () => {
          window.analytics.track("Press Quick Link", {quick_link_name: "Report a bug"})
          window.open('https://tally.so/r/mRoD1j', '_blank')
        }
    },
];

const DashboardHeader = ({label, Icon, description, buttonText, buttonPress}) => {
    return (
        <div className='flex flex-col gap-2'>
            <div className='flex flex-row gap-3'>
                <div className='flex flex-row items-center gap-3 mr-auto'>
                    <div className='p-2 border border-slate-300 rounded-lg'>
                        <Icon className={'w-5 h-5'}/>
                    </div>
                    <div>
                        <p className='text-xl font-semibold'>
                            {label}
                        </p>
                        {!!description &&
                            <p className='text-slate-500 text-sm'>
                                {description}
                            </p>
                        }
                    </div>
                </div>
                {!!buttonText &&
                    <Button variant={'ghost'} onClick={buttonPress}>
                        {buttonText}
                    </Button>
                }
            </div>
        </div>
    )
}

const StepToComplete = ({title, description, buttonText, Icon, completed, onClick}) => {
    return (
        <div className='flex flex-row items-center gap-3 py-3'>
            <div
                className={`p-3 rounded-full ${completed ? 'bg-green-500/10 text-green-500' : 'bg-primary/10 text-primary'}`}>
                <Icon className={'w-6 h-6'}/>
            </div>
            <div className='flex flex-col flex-1'>
                <p className='font-semibold'>
                    {title}
                </p>
                <p className='text-slate-500 text-sm'>
                    {description}
                </p>
            </div>
            <Button disabled={completed} variant={completed ? 'secondary' : 'default'} onClick={onClick}>
                {completed && <SvgCheckCircle className={'w-4 h-4'}/>} {completed ? 'Done' : buttonText}
            </Button>
        </div>
    )
}

const RecommendedJobs = ({recommendedJobs, history, openApplicationPeek}) => {
    return (
        <div className='card p-5 m-0 flex-1'>
            <DashboardHeader
                label={'Recommended Jobs'}
                Icon={SvgCalendar}
                description={'Discover your dream job'}
                buttonText={'Find more jobs'}
                buttonPress={() => history.push(`/jobs?activeTab=Search`)}
            />
            <div className='flex flex-col gap-2 mt-3'>
                <div className='hidden only:flex flex-col items-center justify-center py-16'>
                    <p className='text-slate-500 mb-3 text-center'>
                        RecruitU does not have any jobs listed from your followed companies. Find others.
                    </p>
                    <Button onClick={() => history.push(`/jobs?activeTab=Search`)}>
                        Discover Jobs
                    </Button>
                </div>
                {recommendedJobs.slice(0, 4).map(item =>
                    <div className='row-ac gap-3 flex-1'>
                        <CompanyLogo id={item.company_id} className={'w-16 h-16'}/>
                        <div className='mr-8 flex flex-col gap-0 flex-1 truncate'>
                            <div className='flex flex-col gap-3 flex-1'>
                                <p className='base-black-70 text-md font-semibold m-0 truncate'>
                                    {item.name}
                                </p>
                            </div>
                            <p className='text-sm text-slate-500 m-0 capitalize'>
                                {item.company_name}{item.type && ' • '}{item.type}
                            </p>
                            {/*<div className='flex flex-row items-center gap-2 wrap mt-1'>*/}
                            {/*    {!!item.type ?*/}
                            {/*        <TagComponent sm type={'random'} label={item.type}/> : null}*/}
                            {/*    <TagComponent sm label={item.industry}/>*/}
                            {/*</div>*/}
                        </div>
                        <Button variant={'secondary'} onClick={() => openApplicationPeek(item)}>
                            View
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}


const RecommendedContacts = ({recommendedPeople, contacts, openNetworkingPeek, history, networks}) => {
    return (
        <div className='card p-5 m-0 flex-1'>
            <DashboardHeader
                label={'Recommended Networking Contacts'}
                Icon={SvgMagic}
                description={contacts.length !== 0 && 'Discover people to network with'}
                buttonText={'Find more people'}
                buttonPress={() => history.push(`/network?activeTab=Search`)}
            />
            <div className='flex flex-col gap-2 mt-3'>
                {recommendedPeople.slice(0, 5).map(item => {
                        return (
                            <div className='flex flex-row items-center'>
                                <div className='flex flex-row items-center gap-3'>
                                    <CompanyLogo className={'w-12 h-12'} id={item.company_id}/>
                                    <div>
                                        <p className='font-medium'>
                                            {item.first_name} {item.last_name}
                                        </p>
                                        <p className='text-sm text-slate-500'>
                                            {item.title} {!!item.title && !!item.company_name && 'at'} {item.company_name}
                                        </p>
                                    </div>
                                </div>
                                {item.id in networks ?
                                    <Button variant={'secondary'} className='ml-auto'
                                            onClick={() => openNetworkingPeek(item.id, item)}>
                                        View
                                    </Button>

                                    :
                                    <div className='ml-auto'>
                                        <NetworkStatusTrackerComponet personId={item.id} person={item} openPeek={openNetworkingPeek} sm/>
                                    </div>
                                }
                            </div>
                        )
                    }
                )}
                <div className='hidden only:flex flex-col items-center justify-center py-16'>
                    <p className='text-slate-500 mb-3 text-center'>
                        RecruitU does not have any contacts listed from your followed companies. Find
                        others.
                    </p>
                    <Button onClick={() => history.push(`/network?activeTab=Search`)}>
                        Discover People
                    </Button>
                </div>
            </div>
        </div>
    )
}

function HomePage({
                      user,
                      authUser,
                      history,
                      networks,
                      deleteNetwork,
                      updateNetwork,
                      trackers,
                      applications,
                      updateApplication,
                      updateTracker,
                      openPersonalizationModal,
                  }) {
    const [applicationPreviewOpen, setApplicationPreview] = useState(false);
    const [chooseClubsModalOpen, setChooseClubsModalOpen] = useState(false);
    const [currentJob, setCurrentJob] = useState({});
    const [networkingPeekOpen, setNetworkingPeekOpen] = useState(false);
    const [selectedPersonId, setSelectedPersonId] = useState("");
    const [selectedPeron, setSelectedPerson] = useState({});
    const [recommendedPeople, setRecommendedPeople] = useState([]);
    const [recommendedCompanies, setRecommendedCompanies] = useState([]);
    const [recommendedJobs, setRecommentedJobs] = useState([]);
    const contacts = Object.values(networks) || [];
    const companies = Object.values(trackers).filter(tracker => tracker.type === "company") || [];
    const offers = companies.filter(tracker => tracker.status) || [];
    const apps = Object.values(applications) || [];

    const openNetworkingPeek = (personId, person) => {
        setSelectedPersonId(personId);
        setSelectedPerson(person);
        setNetworkingPeekOpen(true);
    }

    const openApplicationPeek = (currentJob) => {
        history.push(`/job/${currentJob?.id}`);
    }


    const editApp = async (applicationId, jobId, params) => {
        try {
            const editPromise = editApplication(authUser, applicationId, params);
            toast.promise(
                editPromise,
                {
                    pending: 'Updating...',
                    success: 'Application updated!',
                    error: 'Failed to update application!',
                },
                TOAST_OPTIONS
            );
            const editResult = await editPromise
            if (editResult) {
                const newApplication = {...applications[jobId]};
                for (const key of Object.keys(params)) {
                    newApplication[key] = params[key];
                }
                updateApplication(newApplication);

            }
        } catch (error) {
            toast.error('Failed to update application!', TOAST_OPTIONS);
        }
    }

    useEffect(() => {
        getPeopleInfo();
        getCompanyInfo(1);
        getJobInfo();
        window.analytics.page("Home Tab Viewed")
    }, [])

    const getPeopleInfo = async () => {
        const savedCompanyIds = companies.map(tracker => tracker.company_id) || [];
        const people = await homePagePersonSearch(savedCompanyIds, user.college || null);
        const savedContactIds = contacts.map(contact => contact.person_id);
        const filtered = people.filter(person => !savedContactIds.includes(person.id));
        const alreadyDone = [];
        const finalPeople = [];
        for (const person of filtered) {
            if (!(alreadyDone.includes(person.company_id))) {
                alreadyDone.push(person.company_id);
                finalPeople.push(person);
            }
        }
        setRecommendedPeople(finalPeople);
    }

    const getCompanyInfo = (page) => {
        searchCompanies("", 15, page).then(companyResults => {
            const savedIds = companies.map(tracker => tracker.company_id);
            const final = companyResults.filter(company => !savedIds.includes(company.id));
            if (!final.length) {
                getCompanyInfo(page+1);
                return
            }
            setRecommendedCompanies(final)
            return
        })
    }

    const getJobInfo = async () => {
        const hasClassYear = ["2025", "2026", "2027", "2028"].includes(user.grade);
        const savedJobIds = apps.map(application => application.job.id) || [];

        const jobs = await homePageJobSearch("", hasClassYear ? user.grade : null);
        const alreadyDone = [];
        const finalJobs = [];
        for (const job of jobs) {
            if (!(alreadyDone.includes(job.company_id)) && !(savedJobIds.includes(job.id))) {
                alreadyDone.push(job.company_id)
                finalJobs.push(job)
            }
        }
        setRecommentedJobs(finalJobs)
    }

    const previewCompany = (company) => {
        history.push(`/company/${company?.id}`);
    }

    const showNux = !user.completed_personalization || !user?.nux?.add_company || !user.selected_clubs;
    return (
        <PageContentWrapper>
            <div className={'overflow-y-scroll overflow-x-hidden pt-3 hide-scrollbar w-full gap-3 flex-1 pb-5 px-5'}>
                <div className='card relative p-5 flex flex-row m-0 border-slate-300'>
                    <div className='flex flex-row items-center w-full justify-between'>
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-row items-center gap-3'>
                                <ProfilePicture
                                    image={user.profile_pic_url}
                                    userID={user.id}
                                    size={48}
                                    className={'border border-neutral-200'}
                                />
                                <p className='text-2xl xl:text-3xl font-semibold'>
                                    Welcome Back, {user.first_name}!
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-row items-center gap-5'>
                            <div>
                                <p className='text-lg font-semibold text-slate-700'>
                                    {companies.length}
                                </p>
                                <p className='text-xs xl:text-sm text-slate-500'>
                                    Companies Followed
                                </p>
                            </div>
                            <div>
                                <p className='text-lg font-semibold text-slate-700'>
                                    {contacts.length}
                                </p>
                                <p className='text-xs xl:text-sm text-slate-500'>
                                    Contacts Saved
                                </p>
                            </div>
                            <div>
                                <p className='text-lg font-semibold text-slate-700'>
                                    {apps.length}
                                </p>
                                <p className='text-xs xl:text-sm text-slate-500'>
                                    Applications Saved
                                </p>
                            </div>
                            <div>
                                <p className='text-lg font-semibold text-slate-700'>
                                    {offers.length}
                                </p>
                                <p className='text-xs xl:text-sm text-slate-500'>
                                    Offers Recieved
                                </p>
                            </div>
                            <Link to={'/profile'}>
                                <Button variant={'secondary'}>
                                    View profile
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-3'>
                {showNux ?
                    <>
                        <div className='card p-5 m-0 flex-[1.7]'>
                            <DashboardHeader
                                label={'Get Started'}
                                Icon={SvgMagic}
                                description={'Finish these steps to get your account up and running.'}
                            />
                            <div className='flex flex-col justify-center flex-1'>
                                <StepToComplete
                                    title={'Finish onboarding'}
                                    Icon={SvgUserCheck}
                                    description={'Finish filling out your demographics.'}
                                    buttonText={'Finish'}
                                    completed={user.completed_personalization}
                                    onClick={() => openPersonalizationModal()}
                                />
                                <StepToComplete
                                    title={'Find a company'}
                                    Icon={SvgCompanyBuilding}
                                    description={'Find a company you want to work at to start your journey.'}
                                    buttonText={'Finish'}
                                    completed={user.nux.add_company}
                                    onClick={() => history.push(`/companies?activeTab=Search`)}
                                />
                                <StepToComplete
                                    title={'Join your clubs'}
                                    Icon={SvgBriefcase}
                                    description={'Select the clubs you are a part of from your school.'}
                                    buttonText={'Finish'}
                                    completed={user.selected_clubs}
                                    onClick={() => setChooseClubsModalOpen(true)}
                                />
                            </div>
                        </div>
                        <RecommendedContacts openNetworkingPeek={openNetworkingPeek} recommendedPeople={recommendedPeople} history={history} networks={networks} contacts={contacts}/>
                    </>
                    :
                    <>
                        <RecommendedContacts openNetworkingPeek={openNetworkingPeek} recommendedPeople={recommendedPeople} history={history} networks={networks} contacts={contacts}/>
                        <RecommendedJobs openApplicationPeek={openApplicationPeek} recommendedJobs={recommendedJobs} history={history}/>
                    </>
                  }
                </div>
                <div className='flex flex-row items-stretch gap-3'>
                    <ReferralWidget user={user}/>
                    <div
                        className='bg-white flex-[1] flex flex-col gap-3 shadow-lg shadow-slate-200 p-5 border border-primary outline outline-offset-2 outline-slate-200 rounded-xl'>
                        <div className='flex flex-row items-center gap-2 text-primary'>
                            <SvgZap className={'w-5 h-5'}/>
                            <p className='text-xl font-semibold'>
                                Quick Links
                            </p>
                        </div>
                        <div className='flex flex-col gap-2 flex-1 overflow-y-scroll'>
                            <LinksList links={links}/>
                        </div>
                    </div>
                </div>
                <div className='card p-5 m-0'>
                    <DashboardHeader
                        label={'Recommended Companies'}
                        Icon={SvgCompanyBuilding}
                        description={'Follow companies to stay up to date on new jobs and events'}
                        buttonText={companies.length > 0 && 'Find more companies'}
                        buttonPress={() => history.push('/companies?activeTab=Search')}
                    />
                    <div className='flex flex-col mt-5'>
                        <div className='hidden only:flex flex-col items-center justify-center py-16'>
                            <p className='text-slate-500 mb-3'>
                                You aren't following any companies. Search and follow companies to have them appear in
                                your saved section.
                            </p>
                            <Button onClick={() => history.push('/companies?activeTab=Search')}>
                                Discover companies
                            </Button>
                        </div>
                        <div>
                            <div className='hidden only:flex flex-col items-center justify-center'>
                                <p className='text-md text-slate-500 my-12 text-sm'>
                                    No recommendations, check back later!
                                </p>
                            </div>
                            {recommendedCompanies.map(company => {
                                    return (
                                        // <CompanyProgressOverview
                                        //     companyTracker={company_tracker}
                                        //     applications={applications}
                                        //     networks={networks}
                                        //     trackers={trackers}
                                        //     updateTracker={updateTracker}
                                        //     authUser={authUser}
                                        //     history={history}
                                        //     openApplicationPeek={openApplicationPeek}
                                        //     openNetworkingPeek={openNetworkingPeek}
                                        // />
                                        <CompanyResultItem
                                            openCompanyPeek={previewCompany}
                                            item={company}
                                            list={true}
                                        />
                                    )
                                }
                            )}
                        </div>
                    </div>
                </div>
                <ScrapedNetworkingPeek
                    open={networkingPeekOpen}
                    person={selectedPeron}
                    isSaved={(selectedPersonId in networks)}
                    network={networks[selectedPersonId]}
                    close={() => setNetworkingPeekOpen(false)}
                    deleteNetwork={deleteNetwork}
                    updateNetwork={updateNetwork}
                />
                <ApplicationPeek
                    open={applicationPreviewOpen}
                    listing={currentJob}
                    application={applications[currentJob.id]}
                    editApplication={editApp}
                    close={() => setApplicationPreview(false)}
                />
                <ChooseClubsModal isOpen={chooseClubsModalOpen} close={() => {
                    setChooseClubsModalOpen(false);
                }}/>
            </div>
        </PageContentWrapper>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
