import React, {PureComponent} from "react";
import SvgAddCircle from "./icons/SvgAddCircle";
import Tooltip from "./atoms/modals/Tooltip";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import { Button } from "./atoms/Button";
import { createApplication } from "../api/student/applications";
import { TOAST_OPTIONS } from "../utils/constants";
import SvgCheckCircle from "./icons/SvgCheckCircle";
import SvgNot from "./icons/SvgNot";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        applications: state.userReducer.applications
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateApplication: (application) => {
            dispatch({
                type: "UPDATE_APPLICATION",
                application,
            })
        },
    }
}


class JobStatusTrackerComponent extends PureComponent {

    startApplication = async (e) => {
        e.stopPropagation()
        const job = this.props.listing;
        try {
            const applicationPromise = createApplication(this.props.authUser, {
                company_id: job.company_id,
                job_id: job.id,
            });
            toast.promise(
                applicationPromise,
                {
                    pending: 'Adding application...',
                    success: 'Application saved to tracker',
                    error: 'Failed to save application to tracker',
                },
                TOAST_OPTIONS
            );
            const applicationResult = await applicationPromise
            if (applicationResult) {
                this.props.updateApplication(applicationResult.application);
            }

            window.analytics.track("Saved Job", {
              job_id: job.id
            })
        } catch (error) {
            toast.error('Failed to save application to tracker', TOAST_OPTIONS);
        }
    }

    render() {
        const tracking = this.props.listing.id in this.props.applications;
        const listing = this.props.listing || {};
        const classYears = listing.class_years || [];
        const enabled = classYears.includes(this.props.user.grade)
        return (
            <>
                {tracking ?
                    <div className={`flex flex-1 mr-2 ${this.props.className}`}>
                        <Tooltip
                            toggleComponent={
                                <Button
                                    variant={'secondary'}
                                    icon={SvgCheckCircle}
                                    disabled={true}
                                    className={'flex-1 flex w-full cursor-not-allowed'}
                                >
                                    {listing.apply_direct ? "Already Applied!" : "Saved"}
                                </Button>
                            }
                            label={listing.apply_direct ? 'You have already applied to this job on RecruitU' : 'You already have this job saved'}
                        />
                    </div>
                    :
                    <>
                        <div className="flex flex-1 mr-2">
                            <Tooltip
                                toggleComponent={
                                    <Button
                                        onClick={(e) => enabled && this.startApplication(e)}
                                        variant={'secondary'}
                                        className={!enabled && 'opacity-50'}
                                        icon={SvgAddCircle}
                                    >
                                        Save
                                    </Button>
                                }
                                label={enabled ? 'Save this to your saved jobs' : 'Your class year is not eligible to apply to this job'}
                            />
                        </div>
                    </>
                }
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobStatusTrackerComponent);
