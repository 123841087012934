import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {editCompany} from "../../api/recruiter/companies";
import {connect} from "react-redux";
import {Button} from "../../components/atoms/Button";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class RecruiterCompanyEditAbout extends Component {
    state = {
        description: "",
    }

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    save = async () => {
        await editCompany(this.props.authUser, {description: this.state.description});
        this.props.closeModal();
        window.location.reload()
        window.analytics.track("Recruiter Edit Company About")
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.company !== prevProps.company) {
            this.setInfo();
        }
    }

    componentDidMount = () => {
        this.setInfo();
    }

    setInfo = () => {
        const company = this.props.company || {};
        this.setState({
            description: company.description
        })
    }

    render() {
        const saveEnabled = this.props.company.description !== this.state.description;
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit company description'}
                headerToggle
                size={'lg'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.props.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={!saveEnabled}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className={`flex flex-col gap-1.5`}>
                    <div className='text-xs font-medium text-slate-500'>
                        Description
                    </div>
                    <textarea
                        name="description"
                        value={this.state.description}
                        onChange={(e) => this.handleInputChange(e, 'description')}
                        placeholder={'Company description...'}
                        className='input-light text-sm px-3 py-3 base-black-50 w-full min-h-[240px]'
                    />
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RecruiterCompanyEditAbout);
