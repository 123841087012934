import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {Button} from "../../components/atoms/Button";
import {Link, withRouter} from "react-router-dom";
import { filteredUserSearch, searchClubs } from "../../utils/typesense";
import { createList, editList } from "../../api/recruiter/lists";
import {connect} from "react-redux";
import Spinner from "../../components/Spinner";
import ProfilePicture from "../atoms/ProfilePicture";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

const ZERO_STATE = {
    name: '',
    search: '',
    searchResults: [],
    list: [],
    collapseOpen: false,
    listId: null,
    loading: false
}

class EditListModal extends Component {
    state = ZERO_STATE

    nameChanged = e => this.setState({name: e.target.value});

    searchChanged = async (e) => {
        const text = e.target.value;
        let searchResults = [];
        if (text.length > 0) {
            if (this.props.type === "user") {
                searchResults = await filteredUserSearch(text);
                searchResults = searchResults.students;
            } else {
                searchResults = await searchClubs(text);
            }
        }
        this.setState({search: text, searchResults, collapseOpen: true});
    };

    addToList = itemToAdd => {
        if (!this.state.list.some(item => item.id === itemToAdd.id)) {
            this.setState(prevState => ({
                search: '',
                searchResults: [],
                collapseOpen: false,
                list: [...prevState.list, itemToAdd]
            }));
        }
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen && !!this.props.currentList) {
            this.setState({
                name: this.props.currentList.name,
                listId: this.props.currentList.id
            });
        }
        if (prevProps.club !== this.props.club && this.props.club.id) {
            this.setState({list: [this.props.club]});
        }
    }

    removeFromList = async (itemToRemove) => {
        this.setState(prevState => ({
            list: prevState.list.filter(item => item.id !== itemToRemove.id)
        }));
    }

    update = async () => {
        this.setState({loading: true});
        if (this.props.isNewList) {
            await createList(this.props.authUser, {
                name: this.state.name,
                type: this.props.type,
                ids: []
            });
        } else {
            // Todo: new lists
            await editList(this.props.authUser, this.state.listId, this.props.type, {
                name: this.state.name,
                // ids: this.state.list.map(item => item.id)
            });
        }

        this.props.closeModal();
        this.setState(ZERO_STATE);
        this.props.history.push(`/search?active_tab=${this.props.type === "user" ? "Students" : "Clubs"}`)
    }
    
    render() {
        const {isNewList, type} = this.props;
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={isNewList ? `Create A New List` : 'Edit This List'}
                headerToggle
                size={'md'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button variant={'secondary'} onClick={this.props.closeModal}>
                            Cancel
                        </Button>
                        {this.state.loading ?
                            <Spinner/>
                            :
                            <Button onClick={this.update} disabled={!this.state.name}>
                                {isNewList ? 'Create' : 'Save'} List + Add {type === "user" ? "Students" : "Clubs"}
                            </Button>
                        }
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className='flex flex-col gap-5 w-full'>
                    <div className="col-plain w-full">
                        <p className="body3-bold text-neutral-400 mb-1">
                            List Name
                        </p>
                        <input
                            value={this.state.name}
                            onChange={this.nameChanged}
                            placeholder={'Enter a name for this list'}
                            className='input-light w-full px-3 body2 base-black-50'
                        />
                    </div>
                    {/* {type !== "smart" &&
                        <div className="col-plain w-full relative h-[372px]">
                            <p className="body3-bold text-neutral-400 mb-1">
                                Add {type === "user" ? "Students" : "Clubs"}
                            </p>
                            <WrappedTextInput
                                type="text"
                                placeholder={`Find a ${type === "user" ? "student" : "club"}...`}
                                className='pl-44 input-light px-3 body2 w-full mb-3'
                                value={this.state.search}
                                onChange={this.searchChanged}
                                icon={<SvgSearch width={24} height={24}
                                                className={'absolute text-slate-500'}
                                                style={{left: 12, top: 10}}/>}
                            />
                            <div className='absolute top-16 left-0 right-0 z-[99]'>
                                <CollapseComponent isOpen={this.state.search.length !== 0 && this.state.collapseOpen}
                                                className='bg-white shadow-lg border border-neutral-200 rounded-lg'>
                                    <div className='w-full max-h-[320px] overflow-y-scroll'>
                                        {this.state.searchResults.map(item => {
                                            return (
                                                <button
                                                    className='flex flex-row items-center gap-2 p-3 hover:bg-slate-50 w-full'
                                                    key={item.id}
                                                    onClick={() => this.addToList(item)}>
                                                    {item.profile_pic_url ?
                                                        <ProfilePicture
                                                            image={item.profile_pic_url}
                                                            userID={item.id}
                                                            size={40}
                                                            className={'border border-slate-200'}
                                                        />
                                                        :
                                                        <ClubLogo clubId={item.id} alt={'club-icon'} backupUrl={item.logo_url}/>
                                                    }
                                                    <div className='flex flex-col items-start justify-center flex-1 text-left'>
                                                        <p className='font-semibold line-clamp-1'>{item.name || `${item.first_name} ${item.last_name}`}</p>
                                                        <p className='text-sm'>{item.college}</p>
                                                    </div>
                                                </button>
                                            )
                                        })}
                                    </div>
                                </CollapseComponent>
                            </div>
                        <div className='flex flex-col flex-1 overflow-y-scroll gap-3'>
                            {this.state.list.map(item => {
                                return (
                                    <div className='row-plain gap-2' key={item.id}>
                                        {item.profile_pic_url ?
                                            <ProfilePicture
                                                image={item.profile_pic_url}
                                                userID={item.id}
                                                size={40}
                                                className={'border border-slate-200'}
                                            />
                                            :
                                            <ClubLogo clubId={item.id} alt={'club-icon'} backupUrl={item.logo_url}/>
                                        }
                                        <div className='flex flex-col items-start'>
                                            <p className='font-semibold'>{item.name || `${item.first_name} ${item.last_name}`}</p>
                                            <p className='text-sm'>{item.college}</p>
                                        </div>
                                        <Button
                                            onClick={() => this.removeFromList(item)}
                                            icon={SvgClose}
                                            size={'icon'}
                                            variant={'secondary'}
                                            className={'ml-auto'}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        </div>
                    } */}
                </div>
            </ModalComponent>
        )
    }
}

export default withRouter(connect(mapStateToProps)(EditListModal));