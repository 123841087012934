import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {uploadDoc} from "../../api/firebase";
import Dropzone from "react-dropzone";
import SvgPlus from "../../components/icons/SvgPlus";
import {editCompany} from "../../api/recruiter/companies";
import {connect} from "react-redux";
import {v4 as uuid} from 'uuid';
import {Button} from "../../components/atoms/Button";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class RecruiterCompanyEditImages extends Component {
    state = {
        images: [],
        uploadedImage: false,
    }

    closeModal = () => {
        this.props.closeModal()
        this.setState({uploadedImage: false, images: [],})
    }

    save = async () => {
        await editCompany(this.props.authUser, {images: [...this.props.company.images, ...this.state.images]});
        this.props.closeModal();
        window.location.reload()
        window.analytics.track("Recruiter Edit Company Images");
    }

    onDrop = async (acceptedFiles) => {
        const newUuid = await uuid();
        const url = await uploadDoc("companies/images/" + this.props.company.id, newUuid, acceptedFiles[0]);
        this.setState({images: [...this.state.images, url], uploadedImage: true});
    }

    render() {
        const saveDisabled = false
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Add images'}
                headerToggle
                size={'lg'}
                toggle={this.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={saveDisabled}
                            onClick={this.save}>
                            Save Changes
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className='flex flex-col gap-5'>
                    <Dropzone onDrop={(files) => this.onDrop(files)}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className='cursor-pointer'>
                                <input {...getInputProps()} />
                                <div style={{}} className=''>
                                    {this.state.uploadedImage > 0 ? (
                                        <div
                                            className='bg-slate-100 hover:border-blue-500 cursor-pointer overflow-hidden flex flex-row items-center overflow-x-scroll border border-gray-200 rounded-lg gap-3'
                                        >
                                            {this.state.images.slice(-1).map(image =>
                                                <img
                                                    className='max-h-[40vh]'
                                                    src={image}
                                                    alt="company-pic"
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div
                                            className='h-64 w-full bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-lg'
                                        >
                                            <SvgPlus className={'text-blue-500'}/>
                                            <p className='text-xs mt-2'>
                                                Add image
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RecruiterCompanyEditImages);
