import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import CreateJobDetailsStep from "./create-job/CreateJobDetailsStep";
import ProgressStepper from "../atoms/ProgressStepper";
import CreateJobDateLocationStep from "./create-job/CreateJobDateLocationStep";
import CreateJobQualificationStep from "./create-job/CreateJobQualificationStep";
import {createJob} from "../../api/recruiter/jobs";
import {wait} from "@testing-library/user-event/dist/utils";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { stateToHTML } from 'draft-js-export-html';
import {Button} from "../../components/atoms/Button";
import CreateJobApplicationQuestions from "./create-job/CreateJobApplicationQuestions";

const ZERO_STATE = {
    step: 1,
    jobTitle: '',
    jobDescription: '',
    jobType: null,
    workEnvironment: "On-site",
    city: '',
    state: '',
    directOnRecruit: true,
    postToday: true,
    postOn: null,
    hasDeadline: true,
    deadline: null,
    classYears: [],
    qualifiedOnly: false,
    majors: [],
    clubs: [],
    schools: [],
    openings: null,
    url: '',
    applicationQuestions: [],
    numSteps: 4,
    stepLabels: ['Job Details', 'Date and Location', 'Questions', 'Candidate Qualification'],
    editorState: EditorState.createEmpty(),
    role: null,
    gender: null,
    race: [],
    veteran: false,
    lgbtq: false,
    disabled: false,
    authorized: false,
};

class CreateJobModal extends Component {
    state = ZERO_STATE;

    submit = async () => {
        const tier = this.props.tier;
        const contentState = this.state.editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);

        const industry = this.state.role?.endsWith('Consulting') ? 'Consulting' : 'Finance';

        const questions = this.state.applicationQuestions.filter(question => !!question.text);
        const params = {
            name: this.state.jobTitle,
            title: this.state.jobTitle,
            description: htmlContent,
            type: this.state.jobType,
            city: !!this.state.city ? [this.state.city] : [],
            state: !!this.state.state ? [this.state.state] : [],
            work_model: this.state.workEnvironment,
            class_years: this.state.classYears,
            scope: tier !== "blk" ? "public" : "club",
            link: this.state.url,
            industry,
            apply_direct: this.state.directOnRecruit,
            deadline: this.state.hasDeadline ? this.state.deadline : "",
            schools: this.state.schools,
            clubs: tier !== "blk" ? this.state.clubs.map(club => club.id) : ["baeZVpmndkm8fCNGFIuZ"],
            majors: this.state.majors,
            gpa: this.state.gpa,
            qualified_only: this.state.qualifiedOnly,
            openings: this.state.openings,
            exclusive: true,
            application_questions: questions,
            secondary_specialties: [this.state.role],
            specialties: [industry],
            diversity: {
                gender: this.state.gender,
                race: this.state.race,
                lgbtq: this.state.lgbtq,
                disabled: this.state.disabled,
                veteran: this.state.veteran,
                authorized: this.state.authorized,
            }
        }
        await createJob(this.props.authUser, params);
        this.props.closeModal();
        window.location.reload();
        window.analytics.track("New Recruiter Job Created")
    }


    nextStep = () => {
        if (this.state.step === this.state.numSteps) {
            this.submit();
            return;
        }
        this.setState({step: this.state.step + 1})
    }

    prevStep = () => {
        if (this.state.step === 1) {
            this.close();
            return;
        }
        this.setState({step: this.state.step - 1})
    }


    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    updateGender = (gender) => {
        this.setState({gender})
    }

    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    handleWorkEnvironmentChange = (event) => {
        this.setState({
            workEnvironment: event.target.value,
        });
    };

    handleQualifiedOnlyChange = (boolean) => {
        this.setState({
            qualifiedOnly: boolean,
        });
    };

    toggleInput = (stateKey) => {
        this.setState((prevState) => ({
            [stateKey]: !prevState[stateKey],
        }));
    }

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    handleSpecialtiesChange = (specialty) => {
        this.setState({specialties: [specialty]})
    }

    updateClubs = (club) => {
        if (!club) {
            this.setState({clubs: []})
            return
        }

        let newClubs = [...this.state.clubs];
        if (this.state.clubs.includes(club)) {
            const index = this.state.clubs.indexOf(club);
            newClubs.splice(index, 1);
        } else {
            newClubs.push(club);
        }
        this.setState({clubs: newClubs});
    }

    updateRace = (race) => {
        if (!race) {
            this.setState({race: []})
            return
        }

        let newRace = [...this.state.race];
        if (this.state.race.includes(race)) {
            const index = this.state.race.indexOf(race);
            newRace.splice(index, 1);
        } else {
            newRace.push(race);
        }
        this.setState({race: newRace});
    }

    handleQualificationCheckbox = (inputName) => {
        this.setState({[inputName]: !this.state[inputName]});
    }

    updateSchools = (school) => {
        if (!school) {
            this.setState({schools: []})
            return
        }

        let newSchools = [...this.state.schools];
        if (this.state.schools.includes(school)) {
            const index = this.state.schools.indexOf(school);
            newSchools.splice(index, 1);
        } else {
            newSchools.push(school);
        }
        this.setState({schools: newSchools});
    }

    updateClassYears = (year) => {
        if (!year) {
            this.setState({classYears: []})
            return
        }

        let newClassYears = [...this.state.classYears];
        if (this.state.classYears.includes(year)) {
            const index = this.state.classYears.indexOf(year);
            newClassYears.splice(index, 1);
        } else {
            newClassYears.push(year);
        }
        this.setState({classYears: newClassYears});
    }

    updateMajors = (major) => {
        if (!major) {
            this.setState({major: []})
            return
        }
        let newMajor = [...this.state.majors];
        if (this.state.majors.includes(major)) {
            const index = this.state.majors.indexOf(major);
            newMajor.splice(index, 1);
        } else {
            newMajor.push(major);
        }
        this.setState({majors: newMajor});
    }

    updateApplicationQuestions = (text, i) => {
        const applicationQuestions = [...this.state.applicationQuestions];
        if (i >= this.state.applicationQuestions.length) {
            applicationQuestions.push({text, type: "input"})
        } else {
            applicationQuestions[i] = {text, type: "input"}
        }
        this.setState({applicationQuestions})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isOpen !== this.props.isOpen) {
            console.log("PROPS", this.props.currentClubs)
            wait(10).then(() => {
                this.setState({step: 1, clubs: this.props.currentClubs})
            })
        }
    }

    componentDidMount = () => {
        if (this.props.tier === 'blk') {
            this.setState({
                numSteps: 3,
                stepLabels: ['Job Details', 'Date and Location', 'Questions'],
            })
        }
    }

    switchDisabled = () => {
        switch (this.state.step) {
            case 0:
                return true;
            case 1:
                return !this.state.jobTitle || !this.state.jobType || !this.state.role;
            case 2:
                return (this.state.workEnvironment !== "Remote" ? (!this.state.city || !this.state.state) : false) || (this.state.hasDeadline ? !this.state.deadline : false);
            default:
                return false
        }
    }

    close = () => {
        this.setState(ZERO_STATE);
        this.props.closeModal()
    }

    setEditorState = (editorState) => {
        this.setState({editorState});
    }

    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Create A New Job'}
                headerToggle
                size={'lg'}
                toggle={this.close}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button variant={'secondary'} onClick={this.prevStep}>
                            {this.state.step === 1 ? "Cancel" : "Back"}
                        </Button>
                        <Button onClick={this.nextStep} disabled={this.switchDisabled()}>
                            {this.state.step !== this.state.numSteps ? "Next" : "Submit"}
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
                showScrollBar
            >
                <div id={'add-joblisting-modal'}>
                    <ProgressStepper
                        step={this.state.step}
                        stepLabels={this.state.stepLabels}
                    />
                    {this.state.step === 1 &&
                        <CreateJobDetailsStep
                            jobTitle={this.state.jobTitle}
                            jobDescription={this.state.jobDescription}
                            jobType={this.state.jobType}
                            role={this.state.role}
                            openings={this.state.openings}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            editorState={this.state.editorState}
                            setEditorState={this.setEditorState}
                            constants={this.props.constants}
                        />
                    }
                    {this.state.step === 2 &&
                        <CreateJobDateLocationStep
                            jobTitle={this.state.jobTitle}
                            jobDescription={this.state.jobDescription}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            workEnvironment={this.state.workEnvironment}
                            city={this.state.city}
                            state={this.state.state}
                            url={this.state.url}
                            toggleInput={this.toggleInput}
                            directOnRecruit={this.state.directOnRecruit}
                            postToday={this.state.postToday}
                            postOn={this.state.postOn}
                            hasDeadline={this.state.hasDeadline}
                            deadline={this.state.deadline}
                            handleDateChange={this.handleDateChange}
                            handleWorkEnvironmentChange={this.handleWorkEnvironmentChange}
                        />
                    }
                    {this.state.step === 3 &&
                        <CreateJobApplicationQuestions
                            applicationQuestions={this.state.applicationQuestions}
                            updateApplicationQuestions={this.updateApplicationQuestions}
                        />
                    }
                    {this.state.step === 4 &&
                        <CreateJobQualificationStep
                            majors={this.state.majors}
                            updateMajors={this.updateMajors}
                            handleSelectChange={this.handleSelectChange}
                            toggleInput={this.toggleInput}
                            qualifiedOnly={this.state.qualifiedOnly}
                            handleQualifiedOnlyChange={this.handleQualifiedOnlyChange}
                            handleInputChange={this.handleInputChange}
                            clubs={this.state.clubs}
                            schools={this.state.schools}
                            updateClubs={this.updateClubs}
                            updateSchools={this.updateSchools}
                            recruiter
                            gender={this.state.gender}
                            race={this.state.race}
                            veteran={this.state.veteran}
                            lgbtq={this.state.lgbtq}
                            disabled={this.state.disabled}
                            authorized={this.state.authorized}
                            handleQualificationCheckbox={this.handleQualificationCheckbox}
                            updateRace={this.updateRace}
                            updateGender={this.updateGender}
                            classYears={this.state.classYears}
                            updateClassYears={this.updateClassYears}
                        />
                    }
                </div>
            </ModalComponent>
        )
    }
}

export default CreateJobModal