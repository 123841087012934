import React, {PureComponent} from "react";
import ProfilePageExperienceWidget from "../../components/profilepage/ProfilePageExperienceWidget";
import ProfilePageEducationWidget from "../../components/profilepage/ProfilePageEducationWidget";
import SectionTabs from "../../components/atoms/SectionTabs";
import StudentPeekHeader from "../../components/atoms/StudentPeekHeader";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {Document, Page} from "react-pdf";
import {wait} from "@testing-library/user-event/dist/utils";
import {withRouter} from "react-router-dom";
import Tooltip from "../../components/atoms/modals/Tooltip";
import SvgSchool from "../../components/icons/SvgSchool";
import Spinner from "../../components/Spinner";
import ClubLogo from "../../components-recruiter/atoms/ClubLogo";
import SidebarOverlayWrapper from "../../components/atoms/SidebarOverlayWrapper";
import {SIDEBAR_CARD} from "../../utils/dummy";
import { getUserStats } from "../../api/clubAdmin/users";


class ClubStudentPeek extends PureComponent {
    state = {
        activeTab: 'Experience',
        opened: false,
        loading: false,
        stats: {}
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    close = () => {
        this.setState({opened: false})
        wait(150).then(() => {
            this.props.close()
        })
    }

    componentDidMount = () => {
        this.getStats();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.student?.id !== this.props.student?.id) {
            this.getStats();
        }
    }

    getStats = async () => {
        if (!this.props.student?.id || this.props.hideStats) return 

        this.setState({loading: true});
        const res = await getUserStats(this.props.authUser, this.props.student.id)
        this.setState({loading: false, stats: res.stats});
    }

    render() {
        const student = this.props.student || {};
        const education = student.education || [];
        const experiences = student.experiences || [];
        const clubs = student.clubs || [];
        let college = {};
        if (education.length > 0) {
            college = education[0]
        }
        const TABS = ['Experience', !!student.resume && 'Resume'];
        return (
            <>
                <SidebarOverlayWrapper
                    label={'Student Details'}
                    open={this.props.open}
                    onClose={this.close}
                >
                    {this.state.loading ?
                        <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                            <Spinner />
                        </div>
                        :
                        <div className='flex flex-1 overflow-hidden'>
                            <StudentPeekHeader student={student} college={college} hideStats={this.props.hideStats}
                                               clubAdmin stats={this.state.stats} loading={this.state.loading}
                                             
                            />
                            <div className='mt-5'>
                                <SectionTabs
                                    activeTab={this.state.activeTab}
                                    changeTab={this.changeTab}
                                    tabs={TABS}
                                />
                            </div>


                            {this.state.activeTab === 'Clubs' &&
                                <div className='flex-1 overflow-y-scroll pt-3 hide-scrollbar'>
                                    {clubs > 0 &&
                                        <div className={`${SIDEBAR_CARD}`}>
                                            <p className='text-slate-800 text-lg font-semibold mb-3'>
                                                Clubs
                                            </p>
                                            <div
                                                className={`flex flex-col gap-5`}>
                                                {student.clubs.map((club, index) =>
                                                    <div
                                                        className={'flex flex-row items-center gap-3'}
                                                    >
                                                        <Tooltip
                                                            toggleComponent={
                                                                <ClubLogo
                                                                    clubId={club.id}
                                                                    backupUrl={club.logo_url}
                                                                    className={`border border-slate-200`}
                                                                />
                                                            }
                                                            label={club.name}
                                                        />
                                                        <div>
                                                            <p className='text-md font-semibold text-slate-800'>
                                                                {club.name}
                                                            </p>
                                                            <div
                                                                className='flex flex-row items-center text-neutral-400 gap-1'>
                                                                <SvgSchool className={'w-4 h-4'}/>
                                                                <p className='body2 m-0'>
                                                                    {club.college}&nbsp;•&nbsp;Est. {club.founded}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {this.state.activeTab === 'Experience' &&
                                <div className='flex-1 overflow-y-scroll hide-scrollbar pt-3 gap-3 flex flex-col'>
                                    <div className={`${SIDEBAR_CARD}`}>
                                        <p className='text-slate-800 text-lg font-semibold'>
                                            Work
                                        </p>
                                        {experiences.length === 0 ?
                                            <div className='flex flex-col items-center py-10'>
                                                <p className='text-xs text-slate-400'>
                                                    {student.first_name} hasn't added past work experience
                                                </p>
                                            </div>
                                            :
                                            <ProfilePageExperienceWidget
                                                experiences={student.experiences}
                                                inPeek
                                                studentProfilePage
                                            />
                                        }
                                    </div>
                                    <div className={`${SIDEBAR_CARD}`}>
                                        <p className='text-slate-800 text-lg font-semibold'>
                                            Education
                                        </p>
                                        {education.length === 0 ?
                                            <div className='flex flex-col items-center py-10'>
                                                <p className='text-xs text-slate-400'>
                                                    {student.first_name} hasn't added past education experience
                                                </p>
                                            </div>
                                            :
                                            <ProfilePageEducationWidget
                                                education={student.education}
                                                inPeek
                                                studentProfilePage
                                            />
                                        }
                                    </div>
                                </div>
                            }

                            {this.state.activeTab === 'Resume' &&
                                <div
                                    className='w-full bg-slate-50 flex flex-col items-center p-5 flex-1 overflow-y-scroll'>
                                    <a href={student?.resume}
                                        className='secondary-button body1-bold relative mb-4' style={{minHeight: 30, maxWidth: 300}}
                                        >
                                            Download Resume
                                    </a>
                                    <Document file={{url: student?.resume}}>
                                        <Page pageNumber={1} width={560} className='text-slate-500 text-sm'/>
                                    </Document>
                                </div>
                            }
                        </div>
                    }
                </SidebarOverlayWrapper>

            </>
        )
    }
}

export default withRouter(ClubStudentPeek);
