import React, {PureComponent} from 'react';
import SvgInfo from "../icons/SvgInfo";
import SvgChevronRight from "../icons/SvgChevronRight";

class NUXDrawer extends PureComponent {
    render() {
        return (
            <div
                style={{transition: 'height 150ms ease-in-out'}}
                className={`w-full fixed top-0 left-0 right-0 z-50  ${this.props.open ? 'h-[48px]' : 'h-[0px]'} overflow-hidden`}>
                <button
                    className='bg-slate-200 text-primary hover:bg-slate-300 w-full  body2-bold flex flex-row h-full px-5 items-center justify-between'
                    onClick={this.props.openNuxDrawer}>
                    <div className='flex flex-row items-center'>
                        <SvgInfo className={'w-5 h-5 mr-2'}/> Complete Onboarding to unlock all features
                    </div>
                    <div>
                        <SvgChevronRight className={'w-5 h-5'}/>
                    </div>
                </button>
            </div>
        );
    }
}

export default NUXDrawer;
