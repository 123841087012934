import React, { useEffect, useState } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import 'draft-js/dist/Draft.css';
import { useRef } from 'react';
import { CompositeDecorator } from 'draft-js';
import FreeTextInputModal from '../../modals/FreeTextInputModal';
import PlainTextInputModal from '../../modals/PlainTextInputModal';

// Block and Inline Style Button Components
const StyleButton = ({ onToggle, style, label, active }) => {
    const className = `RichEditor-styleButton ${active ? 'RichEditor-activeButton' : ''}`;
    return (
        <span className={className} onMouseDown={(e) => {
            e.preventDefault();
            onToggle(style);
        }}>
            {label}
        </span>
    );
};

// Block Style Controls
const BLOCK_TYPES = [
    { label: 'List', style: 'unordered-list-item' },
    { label: 'Ordered List', style: 'ordered-list-item' },
];

const BlockStyleControls = ({ editorState, onToggle }) => {
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

// Inline Style Controls
const INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = ({ editorState, onToggle }) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

const FreeTextModal = ({linkModalOpen, linkText, setLinkModalOpen, setLinkText, saveLink}) => {
    return (
        <PlainTextInputModal
            isOpen={linkModalOpen}
            title={'Add URL'}
            placeholder={'i.e. https://www.google.com'}
            value={linkText}
            handleInputChange={setLinkText}
            closeModal={() => setLinkModalOpen(false)}
            save={saveLink}
            disabled={!linkText}
        />
    )
}

// RichTextEditor Component
const RichTextEditor = ({ editorState, setEditorState, initialHtml }) => {

    const editorRef = useRef(null);
    const [linkText, setLinkText] = useState("");
    const [linkModalOpen, setLinkModalOpen] = useState(false);

    useEffect(() => {
        if (initialHtml && typeof initialHtml === 'string') {
            const contentState = stateFromHTML(initialHtml);
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
        } else {
            // Initialize with empty editor state if initialHtml is undefined or not a string
            setEditorState(EditorState.createEmpty());
        }
    }, [initialHtml, setEditorState]);

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const onTab = (e) => {
        const maxDepth = 4;
        setEditorState(RichUtils.onTab(e, editorState, maxDepth));
    };

    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const toggleInlineStyle = (inlineStyle) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const focusEditor = () => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };


    const findLinkEntities = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges(
            (character) => {
                const entityKey = character.getEntity();
                return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
            },
            callback
        );
    };
    
    const Link = (props) => {
        const { url } = props.contentState.getEntity(props.entityKey).getData();
        return (
            <a href={url} style={{ color: 'blue', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
                {props.children}
            </a>
        );
    };
    
    // Usage of the decorator
    const decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link,
        },
    ]);

    useEffect(() => {
        if (initialHtml && typeof initialHtml === 'string') {
            const contentState = stateFromHTML(initialHtml);
            const newEditorState = EditorState.createWithContent(contentState, decorator);
            setEditorState(newEditorState);
        } else {
            // Initialize with empty editor state if initialHtml is undefined or not a string
            setEditorState(EditorState.createEmpty(decorator));
        }
    }, [initialHtml, setEditorState]);

    const LinkControls = () => (
        <div className="RichEditor-controls">
            <span className="RichEditor-styleButton" onMouseDown={() => setLinkModalOpen(true)}>
                Hyperlink
            </span>
        </div>
    );

    const addLink = (url) => {
        console.log("DF", url)
        const contentState = editorState.getCurrentContent();
        const contentStateWithLink = contentState.createEntity('LINK', 'MUTABLE', { url });
        const entityKey = contentStateWithLink.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithLink });
    
        setEditorState(RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
        ));
    };

    const saveLink = (link) => {
        addLink(link);
        setLinkModalOpen(false);
    }
    
    console.log("ddd", linkModalOpen)
    return (
        <>
            <div className="RichEditor-root">
                <style>
                    {`
                    .RichEditor-root {
                        background: #fff;
                        border: 1px solid #ddd;
                        border-radius:8px;
                        font-size: 14px;
                    }

                    .RichEditor-editor {
                        border-top: 1px solid #ddd;
                        padding-top:20px;
                        cursor: text;
                        font-size: 16px;
                        min-height:40vh;
                        padding:16px
                    }

                    .RichEditor-controls {
                        display:flex;
                        font-size: 14px;
                        padding:8px;
                        user-select: none;
                        align-items: center;
                        justify-content: center;
                    }

                    .RichEditor-styleButton {
                        color: #999;
                        cursor: pointer;
                        margin-right: 8px;
                        padding: 3px 12px;
                        border:1px solid #00000020;
                        border-radius:100px;
                        font-weight:600;
                        white-space: nowrap;
                    }
                    
                    .RichEditor-styleButton:hover {
                        border:1px solid #00000040;
                        color:#000000
                    }

                    .RichEditor-activeButton {
                        color: #3043A7;
                    }
                `}
                </style>
                <div className="RichEditor-controls">
                    <BlockStyleControls editorState={editorState} onToggle={toggleBlockType} />
                    <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
                    <LinkControls />  
                </div>
                <div className="RichEditor-editor relative z-0" onClick={focusEditor}>
                    <Editor
                        ref={editorRef}
                        editorState={editorState}
                        handleKeyCommand={handleKeyCommand}
                        onChange={setEditorState}
                        onTab={onTab}
                        placeholder="Enter your text..."
                        spellCheck={true}
                        editorClassName="min-h-[40vh]"
                    />
                </div>
            </div>
            <FreeTextModal 
                saveLink={saveLink}
                setLinkModalOpen={setLinkModalOpen}
                linkModalOpen={linkModalOpen}
                linkText={linkText}
                setLinkText={setLinkText}
            />
        </>
    );
};

export default RichTextEditor;

