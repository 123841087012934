import React, {Component} from "react";
import SvgArrowOutward from "../components/icons/SvgArrowOutward";
import ModalComponent from "./atoms/modals/ModalComponent";
import {Button} from "./atoms/Button";
import CompanyLogo from "./atoms/CompanyLogo";
import {Link} from "react-router-dom";
import SvgChevronRight from "./icons/SvgChevronRight";
import SvgLocation from "./icons/SvgLocation";
import RuDirectLabel from "./atoms/RuDirectLabel";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
    setIsPreApplyModalOpen: () => dispatch({ type: "SET_PRE_APPLY_MODAL_OPEN" }),
});


class ApplyNowButton extends Component {
    state = {
        modalOpen: false,
        questionsOpen: false,
        answers: {},
        didYouApplyModalOpen: false,
    }

    

    handleApplyClick = (shouldOpenPreApplyModal) => {
        if (shouldOpenPreApplyModal) {
            this.props.setIsPreApplyModalOpen();
            return;
        }
        
        const applications = this.props.applications || {};
        const job = this.props.job || {};
        if (job.apply_direct) {
            if (job.application_questions.length) this.setState({questionsOpen: true});
            else this.props.startApplication();
        } else if (!!applications[job.id]) {
            this.openLink();
        } else {
            this.setState({modalOpen: true})
        }
        window.analytics.track("Apply Job Clicked", {
            "job_id": job.id
        })
    }

    closeModal = () => {
        this.setState({modalOpen: false, didYouApplyModalOpen: false})
    }

    closeQuestionsModal = () => {
        this.setState({questionsOpen: false})
    }

    saveAndApply = async () => {
        this.props.startApplication();
        this.openLink();
        this.setState({didYouApplyModalOpen: true})
    }

    saveAndMarkApplied = async () => {
        if (this.props.job.id in this.props.applications) {
            const application = this.props.applications[this.props.job.id];
            await this.props.editApplication(application.id, this.props.job.id, {personal_status: "Applied"})
        } else {
            await this.props.startApplication(null, "Applied");
        }

        window.analytics.track("Job Status Updated", {
          job_id: this.props.job.id
        })
        this.closeModal()
    }

    openLink = () => {
        const job = this.props.job || {};
        window.open(job.link, '_blank', 'noreferrer');
        this.closeModal();
        this.setState({didYouApplyModalOpen: true})
    }

    handleInputChange = (text, i) => {
        if (!text) {
            const newAnswers = {...this.state.answers};
            delete newAnswers[i]
            this.setState({answers: newAnswers})
        } else {
            this.setState({
                answers: {
                    ...this.state.answers,
                    [i]: text
                }
            })
        }

    }

    render() {
        const job = this.props.job
        const questions = job.application_questions || [];
        const disabled = Object.values(this.state.answers).length !== questions.length;
        const shouldOpenPreApplyModal = this.props.user?.completed_personalization !== true;
        return (
            <div className="flex flex-1">
                <Button
                    onClick={() => this.handleApplyClick(shouldOpenPreApplyModal)}
                    icon={SvgArrowOutward}
                    variant='default'>
                    {job.id in this.props.applications && this.props.applications[job.id]?.personal_status !== 'To apply' ? 'Job Link' :
                        job.apply_direct ? 'Apply Now on RecruitU' : 'Apply externally'}
                </Button>
                <ModalComponent
                    isOpen={this.state.modalOpen}
                    backgroundColor={'white'}
                    header={'Would you like to save this application first?'}
                    description={'RecruitU is designed to help you track and manage all your applications. Saving jobs to your saved jobs makes them easier to revisit and manage over time. You can always remove them later'}
                    headerToggle
                    size={'md'}
                    toggle={this.closeModal}
                    FooterComponent={
                        <div className='flex-row flex flex-1 gap-3'>
                            <Button onClick={this.openLink}
                                    variant={'secondary'} className='w-full'>Just take me there
                            </Button>
                            <Button
                                onClick={this.saveAndApply}
                                variant='default' className='w-full'>
                                Save application
                            </Button>
                        </div>
                    }
                >
                </ModalComponent>
                <ModalComponent
                    isOpen={this.state.questionsOpen}
                    backgroundColor={'white'}
                    header={'Application'}
                    description={'Please take a few moments to fill out these questions as a part of your application.'}
                    headerToggle
                    size={'lg'}
                    toggle={this.closeQuestionsModal}
                    FooterComponent={
                        <div className='flex-row flex flex-1 gap-3'>
                            <button onClick={this.closeQuestionsModal}
                                    className="secondary-button flex flex-1 body1-bold">Close
                            </button>
                            <button
                                onClick={() => {
                                    this.props.startApplication(this.state.answers);
                                    this.closeQuestionsModal()
                                }}
                                disabled={disabled}
                                className={`primary-button flex flex-1 body1-bold disabled:opacity-50`}>
                                Submit application
                            </button>
                        </div>
                    }
                >
                    {questions.map((question, i) => {
                        return (
                            <div className="col-plain w-full mb-8" key={question.text}>
                                <p className="body3-bold text-neutral-400 mb-1">
                                    {question.text}
                                </p>
                                <textarea
                                    value={this.state.answers[i]}
                                    onChange={(e) => this.handleInputChange(e.target.value, i)}
                                    placeholder={'...'}
                                    className='input-light text-sm px-3 py-3 body2 base-black-50 w-full min-h-[120px]'
                                />
                            </div>
                        )
                    })}
                </ModalComponent>
                <ModalComponent
                    isOpen={this.state.didYouApplyModalOpen}
                    backgroundColor={'white'}
                    header={'Did you submit an application to this job?'}
                    description={'Mark this job as applied in your saved jobs to keep track'}
                    headerToggle
                    size={'md'}
                    toggle={this.closeModal}
                    FooterComponent={
                        <div className='flex-row flex flex-1 gap-3'>
                            <Button onClick={this.closeModal}
                                    variant={'secondary'} className='w-full'>I haven't applied yet
                            </Button>
                            {/*//Save and mark as applied @ cory*/}
                            <Button
                                onClick={this.saveAndMarkApplied}
                                variant='default' className='w-full'>
                                Mark as applied
                            </Button>
                        </div>
                    }
                >
                    <div className='row-ac gap-4 border border-slate-200 rounded-xl p-3'>
                        <CompanyLogo id={job.company_id} className={'w-16 h-16'}/>
                        <div className=''>
                            <p className='text-lg font-bold m-0 blacks-1 flex flex-row text-neutral-900'>
                                {job.name} <span>
                        </span>
                            </p>
                            <p className='text-sm text-slate-500 m-0'>
                                {job.company_name}
                            </p>
                        </div>
                    </div>
                </ModalComponent>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyNowButton);
