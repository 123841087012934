import React, {PureComponent} from "react";
import SvgNoteAdd from "../../../components/icons/SvgNoteAdd";
import StatusSelect from "../../../components/atoms/StatusSelect";
import RecruiterStudentPeekApplicationItem from "../RecruiterStudentPeekApplicationItem";
import RecruiterStudentPeekEventItem from "../RecruiterStudentPeekEventItem";
import "react-pdf/dist/esm/Page/TextLayer.css";
import NoteComponent from "../../../components/NoteComponent";
import {Button} from "../../../components/atoms/Button";
import HorizontalStepperList from "../../../components/atoms/HorizontalStepperList";
import {SIDEBAR_CARD} from "../../../utils/dummy";


class RecruiterStudentActivityNotes extends PureComponent {
    render() {
        const student = this.props.student
        return (
            <div className='flex-1 overflow-y-scroll gap-5 pt-3 hide-scrollbar'>
                {/* <div className={`flex flex-col gap-2 ${SIDEBAR_CARD} pb-0`}>
                    <div className='flex flex-row items-center justify-between'>
                        <p className='text-slate-800 text-md font-semibold'>
                            Status
                        </p>
                        <StatusSelect
                            label={'Status'}
                            value={this.props.status}
                            setValue={this.props.updateStatus}
                            options={[{title: "Applied",}, {title: "Interviewing",}, {title: "Offered",}, {title: "Rejected"},]}
                            light
                        />
                    </div>
                    <HorizontalStepperList
                        updateStatus={this.props.updateStatus}
                        steps={[{title: "Applied",}, {title: "Interviewing",}, {title: "Offered",}, {title: "Rejected"},]}
                        status={this.props.status}
                    />
                </div> */}
                <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                    <p className='text-slate-800 text-md font-semibold'>
                        Notes
                    </p>
                    {this.props.notes.length === 0 ?
                        <div className='flex flex-col items-center justify-center py-7 bg-slate-50 rounded-lg'>
                            <div
                                className='flex flex-col items-center cursor-pointer text-neutral-400 hover:text-neutral-700'>
                                <SvgNoteAdd className={'w-8 h-8 mb-2'}/>
                            </div>
                            <p className='text-xs text-slate-400'>
                                No notes yet...
                            </p>
                        </div>
                        :
                        <div>
                            {this.props.notes.map((note, index) =>
                                <NoteComponent note={note} deleteNote={this.props.deleteNote}
                                               index={index}/>
                            )}
                        </div>
                    }
                    <div className='flex flex-col'>
                        <div className='flex flex-row flex-1 items-center gap-2'>
                            <textarea id="message"
                                      className=" px-2 text-sm py-0.5 text-sm flex flex-1 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                      placeholder="Enter private notes for your recruiting team..."
                                      onChange={this.props.noteChanged}
                                      value={this.props.newNote}
                            />
                            <Button onClick={this.props.addNote} disabled={!this.props.newNote}
                                    className='primary-button body1-bold'>
                                Add note
                            </Button>
                        </div>
                    </div>

                </div>

                <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                    <p className='text-slate-800 text-lg font-semibold'>
                        Applications
                    </p>
                    {this.props.studentApplications.length > 0 ?
                        <div className='flex flex-col gap-3'>
                            {this.props.studentApplications.map(item =>
                                <RecruiterStudentPeekApplicationItem application={item}
                                                                     close={this.props.close}
                                                                     history={this.props.history}/>
                            )}
                        </div>
                        :
                        <div className='flex flex-col items-center py-10'>
                            <p className='text-xs text-slate-400'>
                                {student.first_name} hasn't interacted with any applications
                            </p>
                        </div>
                    }
                </div>
                <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                    <p className='text-slate-800 text-lg font-semibold'>
                        Events
                    </p>
                    {this.props.studentEvents.length > 0 ?
                        <div className='flex flex-col gap-3'>
                            {this.props.studentEvents.map(item =>
                                <RecruiterStudentPeekEventItem event={item}/>
                            )}
                        </div>
                        :
                        <div className='flex flex-col items-center py-10'>
                            <p className='text-xs text-slate-400'>
                                {student.first_name} hasn't interacted with any events
                            </p>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default RecruiterStudentActivityNotes;
