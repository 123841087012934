import React, {PureComponent} from "react";
import SvgSchool from "../icons/SvgSchool";
import SvgEdit from "../icons/SvgEdit";
import {Link} from "react-router-dom";
import ClubLogo from "../../components-recruiter/atoms/ClubLogo";

class ProfilePageClubsWidget extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            club: {},
        };
    }

    render() {
        const club = this.state.club || {}
        return (
            <div className='card'>
                <div className='flex flex-row items-center justify-between mb-4'>
                    <h3 className='mt-0 title2 text-neutral-900'>
                        {this.props.recruiter || this.props.studentProfilePage ? this.props.user.first_name + "\'s Groups" : "Your Groups"}
                    </h3>
                    {/* <SvgEdit className={'cursor-pointer text-neutral-500 hover:text-neutral-900 h-5 w-5'}/> */}
                </div>
                <div>
                    {this.props.user.clubs.map(club =>
                        <>
                            <Link to={{
                                pathname: this.props.studentProfilePage && !this.props.recruiter ? '/studentprofile' : '/club/'+club.id,
                                state: {club_id: club.id}
                            }}>
                                <div className='row-ac mb-2 hover:bg-neutral-100 px-1 py-1 rounded-lg'>
                                    <ClubLogo clubId={club.id}
                                              backupUrl={club.logo_url}
                                              size={48}
                                              className={`border border-slate-200`}/>
                                    <div className='ml-3 flex flex-col gap-0 flex-1'>
                                        <p className='text-md font-semibold m-0 blacks-1'>
                                            {club.name}
                                        </p>
                                        <div className='text-xs text-slate-500 inline-flex'>
                                            <p>
                                                {club.college}
                                                {club.founded &&
                                                    <span className='m-0 ml-4'>
                                                    - Founded {club.founded}
                                                </span>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </>
                    )}
                    <div className='hidden only:flex flex-col items-center justify-center my-5'>
                        <p className='text-sm text-slate-500'>
                            {this.props.recruiter || this.props.studentProfilePage ? this.props.user.first_name + " isn't" : "You aren't"} in any groups
                        </p>
                    </div>
                    {this.props.studentProfilePage? 
                        null 
                        :
                        <div>
                            <div
                                onClick={this.props.openChooseClubsModal}
                                className='primary cursor-pointer hover:text-indigo-800 body2-bold flex flex-row gap-2 text-center pt-5 w-full'>
                                <SvgEdit className={'w-4 h-4'}/> Update My Clubs
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default ProfilePageClubsWidget
