import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import {uploadDoc} from "../../api/firebase";
import Dropzone from "react-dropzone";
import SvgPlus from "../../components/icons/SvgPlus";
import {editCompany} from "../../api/recruiter/companies";
import {connect} from "react-redux";
import { v4 as uuid } from 'uuid';
import {Button} from "../../components/atoms/Button";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class RecruiterCompanyAddQuote extends Component {
    state = {
        name: '',
        title: '',
        quote: '',
        gradInfo: '',
        profilePic: '',
    }

    closeModal = () => {
        this.props.closeModal()
    }

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    save = async () => {
        const quotes = this.props.company.quotes || [];
        quotes.push({
            name: this.state.name,
            title: this.state.title,
            profile_pic: this.state.profilePic,
            grad_info: this.state.gradInfo,
            quote: this.state.quote,
        })
        await editCompany(this.props.authUser, {quotes});
        this.props.closeModal();
        window.location.reload()
    }

    onDrop = async (acceptedFiles) => {
        const newUuid = await uuid()
        const url = await uploadDoc("companies/quotes/" + this.props.company.id, newUuid, acceptedFiles[0]);
        this.setState({profilePic: url});
    }

    render() {
        const saveDisabled = false
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Add quote or testimonial'}
                headerToggle
                size={'lg'}
                toggle={this.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={this.props.closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={saveDisabled}
                            onClick={this.save}>
                            Add
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className='flex flex-col gap-5'>
                    <Dropzone onDrop={(files) => this.onDrop(files)}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className='cursor-pointer'>
                                <input {...getInputProps()} />
                                <div style={{}} className=''>
                                    {!!this.state.profilePic ? (
                                        <div
                                            className='h-24 w-24 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square overflow-hidden flex flex-col items-center justify-center border border-gray-200 rounded-full'
                                        >
                                            <img
                                                className='h-24 w-24 object-contain bg-white aspect-square cursor-pointer  rounded-lg'
                                                src={this.state.profilePic}
                                                alt="profile-pic"
                                            />
                                            {/* <div className='absolute flex flex-col items-center justify-center'>
                                                        <SvgEdit className={'text-blue-500'}/>
                                                        <p className='text-xs mt-2'>
                                                            Edit image
                                                        </p>
                                                    </div> */}
                                        </div>
                                    ) : (
                                        <div
                                            className='h-24 w-24 bg-slate-50 hover:border-blue-500 cursor-pointer aspect-square flex flex-col items-center justify-center border border-gray-200 rounded-full'
                                        >
                                            <SvgPlus className={'text-blue-500'}/>
                                            <p className='text-xs mt-0 text-center'>
                                                Add profile picture
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Name'}
                        placeholder={'e.g. John Smith'}
                        className='input-light px-3 w-full'
                        value={this.state.name}
                        onChange={(e) => this.handleInputChange(e, 'name')}
                    />
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Title'}
                        placeholder={'e.g. Senior Associate'}
                        className='input-light px-3 w-full'
                        value={this.state.title}
                        onChange={(e) => this.handleInputChange(e, 'title')}
                    />
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Graduation Info'}
                        placeholder={"e.g. Harvard '21"}
                        className='input-light px-3 w-full'
                        value={this.state.gradInfo}
                        onChange={(e) => this.handleInputChange(e, 'gradInfo')}
                    />
                    <WrappedTextInput
                        type="text"
                        outerLabel={'Quote'}
                        placeholder={'Quote'}
                        className='input-light px-3 w-full'
                        value={this.state.quote}
                        onChange={(e) => this.handleInputChange(e, 'quote')}
                    />
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps)(RecruiterCompanyAddQuote);
