import React, {PureComponent} from "react";
import ScrapedNetworkingPeek from "../networkingpage/ScrapedNetworkingPeek";
import {connect} from "react-redux";
import {createNetwork} from "../../api/student/networks";
import SvgHeartOutline from "../icons/SvgHeartOutline";
import {Button} from "../atoms/Button";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import SvgChevronUp from "../icons/ChevronUp";
import SvgArrowOutward from "../icons/SvgArrowOutward";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        networks: state.userReducer.networks,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
    }
}


class NetworkStatusTrackerComponet extends PureComponent {
    state = {
        tracking: false,
        networkingPeekOpen: false,
    }

    render() {
        const tracking = Object.keys(this.props.networks).includes(this.props.personId);
        return (
            <>
                {/* {(this.state.tracking || this.props.tracking) ?
                    <Button variant={'secondary'} icon={SvgCheckCircle} disabled={this.state.tracking}>
                        Saved
                    </Button>
                    :
                    <Tooltip
                        toggleComponent={ */}
                            <Button onClick={(e) => {
                                e.stopPropagation()
                                this.props.openPeek(this.props.personId, this.props.person)
                            }}
                                    variant={'secondary'}
                                    icon={tracking ? SvgCheckCircle : SvgArrowOutward}
                            >
                                {tracking ? "Saved" : "Access Email"}
                            </Button>
                        {/* }
                        label={'View more info about this contact'}
                    />
                } */}
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkStatusTrackerComponet);
