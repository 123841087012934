import React, {PureComponent} from "react";
import {connect} from "react-redux";
import ModalComponent from "./ModalComponent";
import Datepicker from "react-tailwindcss-datepicker";
import {primary} from "../../../utils/colors";
import SvgCheck from "../../icons/SvgCheck";
import { editUser, getUser } from "../../../api/student/users";
import {formatDateToString, formatStringToDate} from "../../../utils/strings";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        }
    }
}

class EditEducationModal extends PureComponent {
    state = {
        dates: {
            startDate: null,
            endDate: null,
        },
        error: false,
        errorMessage: true,
        toPresent: false,
        school: "",
        degree: "",
        major: "",
    }
    schoolChanged = e => this.setState({school: e.target.value});
    degreeChanged = e => this.setState({degree: e.target.value});
    majorChanged = e => this.setState({major: e.target.value});


    saveChanges = async () => {
        const user = this.props.user || {};
        const education = user.education;
        const i = this.props.i;

        const updatedFields = {education: education.length > 0 ? [...user.education] : []};
        const edu = {
            degree_name: this.state.degree,
            starts_at: formatStringToDate(this.state.dates.startDate),
            ends_at: this.state.toPresent ? null : formatStringToDate(this.state.dates.endDate),
            school: this.state.school,
            field_of_study: this.state.major,
        }
        if (i !== null) {
            updatedFields.education[i] = {...education[i], ...edu};
        } else {
            updatedFields.education.push(edu);
        }
        
        const newUser = {...this.props.user, ...updatedFields};
        this.props.setUser(newUser);
        await editUser(this.props.authUser, updatedFields);
        this.props.closeModal();
    }

    handleDateChange = (dates) => {
        this.setState({dates})
    }

    componentDidMount = () => {
        this.updateValues();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.i !== this.props.i) {
            this.updateValues();
        }
    }

    updateValues = () => {
        const user = this.props.user || {};
        const education = user.education;
        const i = this.props.i;
        if (i !== null && i < education.length) {
            const edu = education[i];
            let startDate = null;
            let endDate = null;
            if (!!edu.starts_at) {
                startDate = formatDateToString(edu.starts_at);
            }
            if (!!edu.ends_at) {
                endDate = formatDateToString(edu.ends_at);
                this.setState({toPresent: false});
            } else {
                endDate = startDate;
                this.setState({toPresent: true});
            }
            this.setState({
                school: edu.school,
                degree: edu.degree_name,
                major: edu.field_of_study,
                dates: {
                    startDate,
                    endDate,
                }
            })
        } else {
            this.setState({
                school: "",
                degree: "",
                major: "",
                dates: {
                    startDate: null,
                    endDate: null,
                },
                toPresent: false
            })
        }
    }

    render() {
        const saveDisabled = false;
        return (
            <ModalComponent isOpen={this.props.isOpen}
                            width={800}
                            header={`${this.props.addingNew ? 'Add' : 'Edit'} Education`}
                            backgroundColor={'white'}
                            headerToggle
                            toggle={this.props.closeModal}
                            FooterComponent={
                                <div className='flex flex-row items-center justify-end gap-3'>
                                    <button onClick={this.props.closeModal} className='secondary-button body1-bold'>
                                        Cancel
                                    </button>
                                    <button onClick={this.saveChanges} disabled={saveDisabled}
                                            className={`primary-button body1-bold ${saveDisabled && 'opacity-50'}`}>
                                        Save Changes
                                    </button>
                                </div>
                            }
                            footerAlignment={'right'}
                            id={'edit-profile-modal'}
            >
                <div className='flex flex-col w-full gap-5 bg-white'>
                    <div className='flex flex-row justify-between items-center'>
                        <div className='flex flex-col gap-5 flex-1'>
                            <div className='flex flex-row items-center gap-3'>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        School
                                    </p>
                                    <input
                                        value={this.state.school}
                                        onChange={this.schoolChanged}
                                        placeholder={'College'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                    {/* <select
                                        className='input-light px-3 body2 base-black-50 w-full'
                                        onChange={() => null} value={this.state.company}>
                                        <option disabled={true} value="">Select company</option>
                                        {["Morgan Stanley", "Goldman Sachs", "Lazard"].map(option =>
                                            <option value={option}>{option}</option>
                                        )}
                                    </select> */}
                                </div>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Major
                                    </p>
                                    <input
                                        value={this.state.major}
                                        onChange={this.majorChanged}
                                        placeholder={'Finance'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                            </div>
                            <div className='flex flex-row items-center gap-3'>
                                <div className="col-plain flex-1">
                                    <p className="body3-bold text-neutral-400 mb-1">
                                        Degree
                                    </p>
                                    <input
                                        value={this.state.degree}
                                        onChange={this.degreeChanged}
                                        placeholder={'Bachelors'}
                                        className='input-light px-3 body2 base-black-50 w-full'
                                    />
                                </div>
                                <div className='flex flex-row items-center gap-3 flex-1'>
                                    <div className="col-plain w-full">
                                        <p className="body3-bold text-neutral-400 mb-1">
                                            Time of Enrollment
                                        </p>
                                        <div className='flex flex-row items-center gap-2'>
                                            <Datepicker
                                                inputClassName='input-light w-full px-3 body2'
                                                value={this.state.dates}
                                                onChange={this.handleDateChange}
                                                primaryColor={primary}
                                                displayFormat={"MM/YYYY"}
                                                separator={'to'}
                                                asSingle={this.state.toPresent}
                                                useRange={!this.state.toPresent}
                                            />
                                            <div className='flex flex flex-row items-center gap-1 w-[120px]'>
                                                <div
                                                    onClick={() => this.setState({toPresent: !this.state.toPresent})}
                                                    className={`${this.state.toPresent ? 'checkbox-active' : 'checkbox border-neutral-200'} cursor-pointer`}>
                                                    {this.state.toPresent &&
                                                        <SvgCheck className={'w-4 h-4 base-white-100'}/>
                                                    }
                                                </div>
                                                <p className='body4 text-slate-400'>
                                                    To Present
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-plain w-full">
                                <p className="body3-bold text-neutral-400 mb-1">
                                    About
                                </p>
                                <textarea
                                    value={this.state.description}
                                    onChange={() => null}
                                    placeholder={'A little more about what you have done'}
                                    className='input-light px-3 body2 base-black-50 w-full pt-2 min-h-[82px]'
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEducationModal)
