import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={48}
            viewBox="0 -960 960 960"
            width={48}
            {...props}
        >
            <path fill={'currentColor'} d="M791-222l-45-45q28-24 50.5-53t41.5-62q-90-8-173-41.5T518-520q-63-63-96-145.5T381-837q-31.579 18.051-60.789 41.026Q291-773 267-746l-45-45q44-49 101.5-82.5T450-920q-18 98 11 192.5T561-562q71 71 165.5 100.5T920-450q-13 69-46.5 126.5T791-222zm6 178L687-154q-38 17-78.711 25.5T524-120q-84 0-157.5-32t-128-86.5Q184-293 152-366.5T120-524q0-43.578 8.5-84.289T154-687L45-796l43-43L840-87l-43 43zM524-180q30 0 58.814-5.5Q611.627-191 639-202L202-639q-11 27-16.5 56.085Q180-553.831 180-524q0 143.333 100.333 243.667Q380.667-180 524-180zM420-421zm86-86z" />
        </svg>
    )
}

export default SvgComponent
