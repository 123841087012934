import React from "react";
import { Button } from "../components/atoms/Button";
import ModalComponent from "../components/atoms/modals/ModalComponent";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { createList } from "../api/recruiter/lists";
import { connect } from "react-redux";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

function RecruiterBulkCreateList({ studentIds, originalName, authUser }) {
    const [isOpen, setIsOpen] = useState(false);

    const [name, setName] = useState();

    useEffect(() => {
        if (originalName && isOpen) {
            setName(`${originalName} list`);
        }
    }, [originalName, isOpen]);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const handleCreateList = async () => {
        toggleModal();
        toast.promise(
            createList(authUser, {
                name: name,
                type: 'user',
                ids: studentIds,
            }),
            {
                pending: "Creating list...",
                success: "List created successfully",
                error: "Failed to create list",
            }
        );
    };

    return (
        <>
            <Button
                variant={"secondary"}
                onClick={toggleModal}
                disabled={studentIds.length === 0}
            >
                Create List
            </Button>
            <ModalComponent
                isOpen={isOpen}
                backgroundColor={"white"}
                header={`Create List for ${studentIds.length} students`}
                headerToggle
                size={"md"}
                toggle={toggleModal}
                FooterComponent={
                    <div className="flex flex-row items-center gap-3">
                        <Button variant={"secondary"} onClick={toggleModal}>
                            Cancel
                        </Button>

                        <Button onClick={handleCreateList} disabled={!name}>
                            Create List
                        </Button>
                    </div>
                }
                footerAlignment={"right"}
            >
                <div className="flex flex-col gap-5 w-full">
                    <div className="col-plain w-full">
                        <p className="body3-bold text-neutral-400 mb-1">
                            List Name
                        </p>
                        <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={"Enter a name for this list"}
                            className="input-light w-full px-3 body2 base-black-50"
                        />
                    </div>
                </div>
            </ModalComponent>
        </>
    );
}

export default connect(mapStateToProps)(RecruiterBulkCreateList);
