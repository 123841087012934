import React, {Component} from "react";
import RecruiterNavbar from "./components/RecruiterNavbar";
import {Redirect, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import RecruiterDashboard from "./components-recruiter/RecruiterDashboard";
import RecruiterJobListingPage from "./components-recruiter/RecruiterJobListingPage";
import RecruiterJobsPage from "./components-recruiter/RecruiterJobsPage";
import RecruiterEventsPage from "./components-recruiter/RecruiterEventsPage";
import CreateJobModal from "./components-recruiter/modals/CreateJobModal";
import RecruiterCompanyPage from "./components-recruiter/RecruiterCompanyPage";
import CreateEventModal from "./components-recruiter/modals/CreateEventModal";
import RecruiterStudentPeek from "./components-recruiter/peeks/RecruiterStudentPeek";
import RecruiterEnrichPage from "./components-recruiter/RecruiterEnrichPage";
import RecruiterFavoritesPage from "./components-recruiter/RecruiterFavoritesPage";
import RecruiterSearchPage from "./components-recruiter/RecruiterSearchPage";
import RecruiterEngagePage from "./components-recruiter/RecruiterEngagePage";
import ToastComponent from "./components/atoms/ToastComponent";
import RecruiterEventListingPage from "./components-recruiter/RecruiterEventListingPage";
import RecruiterClubPeek from "./components-recruiter/peeks/RecruiterClubPeek";
import MessagesComingSoon from "./components-recruiter/atoms/MessagesComingSoon";
import RecruiterSettingsPage from "./components-recruiter/RecruiterSettingsPage";
import ImageViewerModal from "./components/modals/ImageViewerModal";
import RecruiterClubPage from "./components-recruiter/RecruiterClubPage";
import RecruiterSavedClubsPage from "./components-recruiter/RecruiterSavedClubsPage";
import RecruiterEmailPage from "./components-recruiter/RecruiterEmailPage";
import RecruiterStudentProfilePage from "./components-recruiter/RecruiterStudentProfilePage";
import RecruiterFullListPage from "./components-recruiter/RecruiterFullListPage";
import RecruiterJobPage from "./components-recruiter/RecruiterJobPage";
import RecruiterEventPage from "./components-recruiter/RecruiterEventPage";
import RecruiterBulkEmailPage from "./components-recruiter/RecruiterBulkEmailPage";
import RecruiterEmailHistoryPage from "./components-recruiter/RecruiterEmailHistoryPage";
import RecruiterSavedSearchesPage from "./components-recruiter/RecruiterSavedSearchesPage";

function mapDispatchToProps(dispatch) {
    return {
        toggleCreateJobModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_JOB_MODAL",
            })
        },
        toggleCreateEventModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_EVENT_MODAL",
            })
        },
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        closeStudentPeek: () => {
            dispatch({
                type: "CLOSE_STUDENT_PEEK",
            })
        },
        closeClubPeek: () => {
            dispatch({
                type: "CLOSE_CLUB_PEEK",
            })
        },
        toggleClubPeek: () => {
            dispatch({
                type: "TOGGLE_CLUB_PEEK",
            })
        }
    }
}

function mapStateToProps(state) {
    return {
        createJobModalOpen: state.userReducer.createJobModalOpen,
        createEventModalOpen: state.userReducer.createEventModalOpen,
        studentPeekOpen: state.userReducer.studentPeekOpen,
        studentPeekStudent: state.userReducer.studentPeekStudent,
        clubPeekOpen: state.userReducer.clubPeekOpen,
        clubPeekClub: state.userReducer.clubPeekClub,
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        tier: state.userReducer.tier,
        currentClubs: state.userReducer.currentClubs,
        constants: state.userReducer.constants
    }
}

class RecruiterSideApp extends Component {
    state = {
        studentDrawerOpen: false
    }

    render() {
        return (
            <div className='w-full h-full flex flex-col relative'>
                <RecruiterNavbar/>
                <div className='flex flex-col flex-1 mt-[80px] bg-white overflow-y-scroll hide-scrollbar'>
                    <Switch>
                        <Route exact path="/"
                               render={(routeProps) =>
                                   <RecruiterDashboard {...routeProps}/>
                               }/>
                        <Route exact path="/engage"
                               render={(routeProps) =>
                                   <RecruiterEngagePage {...routeProps}/>
                               }/>
                        <Route exact path="/enrich"
                               render={(routeProps) =>
                                   <RecruiterEnrichPage {...routeProps}/>
                               }/>
                        <Route exact path="/jobs"
                               render={(routeProps) =>
                                   <RecruiterJobsPage {...routeProps}/>
                               }/>
                        <Route exact path="/events"
                               render={(routeProps) =>
                                   <RecruiterEventsPage {...routeProps}/>
                               }/>
                        <Route exact path="/job/:jobId"
                               render={(routeProps) =>
                                   <RecruiterJobPage {...routeProps}/>
                               }/>
                        <Route exact path="/event/:eventId"
                               render={(routeProps) =>
                                   <RecruiterEventPage {...routeProps}/>
                               }/>
                        <Route path="/job-listing"
                               render={(routeProps) =>
                                   <RecruiterJobListingPage {...routeProps}/>
                               }/>
                        <Route path="/event-listing"
                               render={(routeProps) =>
                                   <RecruiterEventListingPage {...routeProps}/>
                               }/>
                        <Route exact path="/candidates"
                               render={(routeProps) =>
                                   <RecruiterFavoritesPage {...routeProps}/>
                               }/>
                        <Route exact path="/settings"
                               render={(routeProps) =>
                                   <RecruiterSettingsPage {...routeProps}/>
                               }/>
                        <Route path="/club"
                               render={(routeProps) =>
                                   <RecruiterClubPage {...routeProps}/>
                               }/>
                        <Route exact path="/company-page"
                               render={(routeProps) =>
                                   <RecruiterCompanyPage {...routeProps}/>
                               }/>
                        <Route exact path="/messages"
                               render={(routeProps) =>
                                   <MessagesComingSoon {...routeProps}/>
                               }/>
                        <Route path="/search"
                               render={(routeProps) =>
                                   <RecruiterSearchPage {...routeProps}/>
                               }/>
                        <Route exact path="/saved-lists"
                               render={(routeProps) =>
                                   <RecruiterSavedClubsPage {...routeProps}/>
                               }/>
                        <Route exact path="/email"
                               render={(routeProps) =>
                                   <RecruiterEmailPage {...routeProps}/>
                               }/>
                        <Route exact path="/email-history"
                               render={(routeProps) =>
                                   <RecruiterEmailHistoryPage {...routeProps}/>
                               }/>
                        <Route exact path="/saved-searches"
                               render={(routeProps) =>
                                   <RecruiterSavedSearchesPage {...routeProps}/>
                               }/>
                        <Route exact path="/bulk-email"
                               render={(routeProps) =>
                                   <RecruiterBulkEmailPage {...routeProps}/>
                               }/>
                        <Route exact path="/list/:type/:listId"
                               render={(routeProps) =>
                                   <RecruiterFullListPage {...routeProps}/>
                               }/>
                        {/*<Route exact path="/studentsearch"*/}
                        {/*       render={(routeProps) =>*/}
                        {/*           <RecruiterStudentSearch {...routeProps}/>*/}
                        {/*       }/>*/}
                        <Route exact path="/profile/:profileId"
                               render={(routeProps) =>
                                   <RecruiterStudentProfilePage {...routeProps}/>
                               }/>
                        <Redirect to="/"/>
                    </Switch>
                </div>
                <CreateJobModal
                    isOpen={this.props.createJobModalOpen}
                    closeModal={this.props.toggleCreateJobModal}
                    authUser={this.props.authUser}
                    tier={this.props.tier}
                    currentClubs={this.props.currentClubs}
                    constants={this.props.constants}
                />
                <CreateEventModal
                    isOpen={this.props.createEventModalOpen}
                    closeModal={this.props.toggleCreateEventModal}
                    authUser={this.props.authUser}
                    recruiter
                    user={this.props.user}
                    tier={this.props.tier}
                    currentClubs={this.props.currentClubs}
                    constants={this.props.constants}
                />
                <RecruiterStudentPeek
                    authUser={this.props.authUser}
                    student={this.props.studentPeekStudent}
                    close={this.props.closeStudentPeek}
                    open={this.props.studentPeekOpen}
                />
                <RecruiterClubPeek
                    authUser={this.props.authUser}
                    club={this.props.clubPeekClub}
                    close={this.props.closeClubPeek}
                    open={this.props.clubPeekOpen}
                />
                <ImageViewerModal/>
                <ToastComponent/>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterSideApp);
