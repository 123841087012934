import React, {Component} from "react";
import {connect} from "react-redux";
import SvgMail from "../components/icons/SvgMail";
import json from "../img/lottie/check.json";
import {emailUsers} from "../api/recruiter/companies";
import {Button} from "../components/atoms/Button";
import ModalComponent from "../components/atoms/modals/ModalComponent";
import WrappedTextInput from "../components/atoms/WrappedTextInput";
import LottieWrapper from "../components/atoms/LottieWrapper";
import RichTextEditor from "../components/atoms/inputs/RichTextEditor";
import {EditorState} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import SvgSearchFeather from "../components/icons/SvgSearchFeather";
import SvgCalendar from "../components/icons/SvgCalendar";
import CollapseComponent from "../components/atoms/CollapseComponent";
import SvgList from "../components/icons/SvgList";
import SvgBriefcase from "../components/icons/SvgBriefcase";
import HorizontalDivider from "../components/atoms/HorizontalDivider";
import SvgCheck from "../components/icons/SvgCheck";
import { filteredUserSearch } from "../utils/typesense";
import { recruiterEventSearch, recruiterJobSearch, searchClubLists, searchSmartLists, searchUserLists } from "../utils/recruiter_typesense";
import { DateTime } from 'luxon';


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        recruiter: state.userReducer.user,
    };
}

const ZERO_STATE = {
    page: 1,
    search: '',
    subject: "",
    body: "",
    category: "",
    date: "",
    coverPhoto: "",
    processing: false,
    successModalOpen: false,
    smartLists: [],
    userLists: [],
    clubLists: [],
    events: [],
    jobs: [],
    selectedSmartLists: [],
    selectedClubLists: [],
    selectedUserLists: [],
    selectedJobs: [],
    selectedEvents: [],
    editorState: EditorState.createEmpty(),
};

class RecruiterBulkEmailPage extends Component {
    state = {
        ...ZERO_STATE,
        showMoreLists: false,
        isDropdownOpen: false,
    };

    setEditorState = (editorState) => {
        this.setState({editorState});
    };

    closeAllModals = () => {
        this.setState({successModalOpen: false});
        this.setState(ZERO_STATE);
        this.props.history.goBack();
    };

    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    closeAllModals = () => {
        this.setState({successModalOpen: false});
        this.setState(ZERO_STATE);
        this.props.history.goBack();
    }


    save = async () => {
        this.setState({processing: true});
        const {
            selectedJobs,
            selectedEvents,
            selectedSmartLists,
            selectedClubLists,
            selectedUserLists,
        } = this.state;
        
        const contentState = this.state.editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);

        const smartListUserIds = await this.getSmartListUserIds(selectedSmartLists);
        await emailUsers(this.props.authUser, {
            "subject": this.state.subject,
            "text": htmlContent,
            "smart_list_ids": selectedSmartLists.map(list => list.id),
            "club_list_ids": selectedClubLists.map(list => list.id),
            "user_list_ids": selectedUserLists.map(list => list.id),
            "job_ids": selectedJobs.map(job => job.id),
            "event_ids": selectedEvents.map(event => event.id),
            "user_ids": smartListUserIds
        })
        this.setState({successModalOpen: true, processing: false})
    }

    getSmartListUserIds = async (selectedSmartLists) => {
        let userIds = [];
        for (const list of selectedSmartLists) {
            let i = 0;
            let total = 10; // Doesn't matter just number to get into the first loop
            while (userIds.length <= total && i < 20) {
                const res = await filteredUserSearch("", i, null, list.grades, list.majors, list.colleges, list.gpa, list.gender, list.race, list.veteran, list.lgbtq, list.disabled, list.authorized, list.industries || []);

                if (!res || !res.students || !Array.isArray(res.students) || !res.students.length) {
                    break;
                }
                userIds = userIds.concat(res.students.map(student => student.id));
                total = res.total;
                if (typeof total !== "number" || total < 0) {
                    break;
                }
                console.log(i, userIds)
                i += 1
            }
            
        }
        return userIds
    }

    handleSearchChange = (event) => {
        const text = event.target.value;
        this.setState({ search: text });
        if (text) {
            this.setState({ isDropdownOpen: true });
        } else {
            this.setState({ isDropdownOpen: false });
        }
        this.search(text);
    };

    search = async (text) => {
        const recruiter = this.props.recruiter;
        const smartListRes = await searchSmartLists(text, this.state.page, recruiter.company_id);
        const clubListRes = await searchClubLists(text, this.state.page, recruiter.company_id);
        const userListRes = await searchUserLists(text, this.state.page, recruiter.company_id);
        const jobsRes = await recruiterJobSearch(text, recruiter.company_id);
        const eventsRes = await recruiterEventSearch(text, recruiter.company_id);

        this.setState({
            jobs: jobsRes,
            events: eventsRes,
            smartLists: smartListRes,
            userLists: userListRes,
            clubLists: clubListRes,
            loading: false,
        });
    }

    toggleShowMore = (type) => {
        this.setState((prevState) => ({
            [type]: !prevState[type],
        }));
    };


    toggleSelection = (type, item) => {
        const selectedKey = `selected${type.charAt(0).toUpperCase() + type.slice(1)}`;
        const isSelected = this.state[selectedKey].some((selectedItem) => selectedItem.id === item.id);

        if (isSelected) {
            this.setState({
                [selectedKey]: this.state[selectedKey].filter((selectedItem) => selectedItem.id !== item.id),
            });
        } else {
            this.setState({
                [selectedKey]: [...this.state[selectedKey], item],
            });
        }
    };

    getSelectedOptions = () => {
        const { selectedSmartLists, selectedJobs, selectedEvents, selectedClubLists, selectedUserLists} = this.state;
        return (
            selectedSmartLists.length +
            selectedJobs.length +
            selectedEvents.length +
            selectedUserLists.length + 
            selectedClubLists.length
        );
    };

    getFormattedRecipients = () => {
        const { selectedSmartLists, selectedJobs, selectedEvents, selectedClubLists, selectedUserLists } = this.state;
        let recipients = [];
        selectedJobs.forEach((job) => recipients.push(`${job.name} applicants`));
        selectedEvents.forEach((event) => recipients.push(`${event.name} RSVPs`));
        // selectedStudents.forEach((student) => recipients.push(`${student.first_name} ${student.last_name}`));
        // selectedClubs.forEach((club) => recipients.push(club.name));
        selectedSmartLists.forEach((list) => recipients.push(`${list.name} Smart List`));
        selectedUserLists.forEach((list) => recipients.push(`${list.name} Student List`));
        selectedClubLists.forEach((list) => recipients.push(`${list.name} Club List`));
        return recipients.length > 0 ? `Recipients: ${recipients.join(', ')}` : '';
    };

    closeDropdown = () => {
        this.setState({ isDropdownOpen: false , search:''});
    };

    render() {
        const {
            showMoreLists,
            selectedJobs,
            selectedEvents,
            selectedSmartLists,
            selectedClubLists,
            selectedUserLists,
            isDropdownOpen,
            smartLists,
            clubLists,
            userLists,
            jobs,
            events,
        } = this.state;

        // const studentsToShow = showMoreUserLists ? this.state.students : this.state.userLists.slice(0, 2);
        // const clubsToShow = showMoreClubLists ? this.state.clubs : this.state.clubLists.slice(0, 2);
        // const smartListsToShow = showMoreLists ? this.state.lists : this.state.lists.slice(0, 2);

        const renderSelectedIcon = (isSelected, className) => {
            return isSelected ?
                <div
                    className={`w-5 h-5 rounded-full flex flex-col items-center justify-center bg-primary ${className || 'ml-auto'}`}>
                    <SvgCheck className="w-4 h-4 text-white"/>
                </div>
                : null;
        };

        const selectedCount = this.getSelectedOptions();
        const formattedRecipients = this.getFormattedRecipients();
        return (

            <div className={`flex flex-col  overflow-y-scroll flex-1 p-5 gap-3 w-full max-w-[700px] mx-auto`}>
                <div className='flex flex-row items-center justify-between border-b border-slate-200 pb-5'>
                    <div>
                        <p className='text-slate-800 text-2xl font-semibold'>
                            Send an email
                        </p>
                    </div>
                    <div className="gap-2 flex flex-row gap-3">
                        <Button variant={'secondary'} onClick={this.props.history.goBack}>
                            Cancel
                        </Button>
                        <Button
                            icon={SvgMail}
                            onClick={this.save}
                            loading={this.state.processing}
                            disabled={selectedCount === 0}
                        >
                            Send
                        </Button>
                    </div>
                </div>
                <div>
                    <div className='flex flex-col gap-5'>
                        <div className='flex flex-col gap-1'>
                            <div className='text-xs font-medium text-slate-500'>
                                Recipients
                            </div>
                            <div className='relative'>
                                <div className={'relative'}>
                                    <WrappedTextInput
                                        type="text"
                                        placeholder='Search your jobs, events, and lists'
                                        className='pl-44 input-light body2 w-full'
                                        value={this.state.search}
                                        onChange={this.handleSearchChange}
                                        icon={<SvgSearchFeather width={20} height={20}
                                                                className={'absolute text-slate-500'}
                                                                style={{left: 12, top: 10}}/>}
                                    />
                                    <div
                                        className='absolute right-2 top-0 bottom-0 flex flex-col items-center justify-center'>
                                        {isDropdownOpen && (
                                            <div className="absolute w-36 right-2 top-0 bottom-0 flex flex-col items-end justify-center">
                                                {selectedCount > 0 ? (
                                                    <Button size={"xs"} onClick={this.closeDropdown}>
                                                        Confirm recipients
                                                    </Button>
                                                ) : (
                                                    <Button size={"xs"} variant={'secondary'} onClick={this.closeDropdown}>
                                                        Close search
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='absolute top-12 left-0 right-0 z-[99]'>
                                    <CollapseComponent
                                        isOpen={isDropdownOpen}
                                        className='bg-white shadow-lg border shadow-xl border-neutral-200 rounded-lg p-2 max-h-[45vh] overflow-y-scroll'>
                                        <div>
                                            {/* Smart Lists Section */}
                                            <p className='text-sm mb-1 mt-2 font-medium'>Smart Lists</p>
                                            {smartLists.map((res) => {
                                                const isSelected = selectedSmartLists.some((selected) => selected.id === res.id);
                                                return (
                                                    <div
                                                        key={res.id}
                                                        className={`flex flex-row items-center gap-3 py-2 cursor-pointer ${!isSelected && 'hover:bg-slate-100'} rounded-xl px-2`}
                                                        onClick={() => this.toggleSelection('smartLists', res)}
                                                    >
                                                        <div
                                                            className="w-8 h-8 flex flex-col items-center justify-center rounded-lg object-cover border border-slate-200">
                                                            <SvgList className={'w-5 h-5 text-slate-700'}/>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <p className="text-sm text-slate-800 line-clamp-2">{res.name}</p>
                                                            <p className="text-xs text-slate-500 line-clamp-2">
                                                                Smart List - Created by {res.creator_name}
                                                            </p>
                                                        </div>
                                                        {renderSelectedIcon(isSelected)}
                                                    </div>
                                                );
                                            })}
                                            {this.state.smartLists.length === 0 && (
                                                <div className='flex flex-col items-center mt-1'>
                                                    {/* <Button variant={'secondary'} size={'xs'}
                                                            onClick={() => this.toggleShowMore('showMoreLists')}>
                                                        {showMoreLists ? "Show Less" : "Show More"}
                                                    </Button> */}
                                                    <p className="text-sm text-slate-800 line-clamp-2">No smart lists for this search</p>
                                                </div>
                                            )}
                                        </div>
                                        <HorizontalDivider className='bg-slate-200 my-3'/>
                                        <div>
                                            {/* Student Lists Section */}
                                            <p className='text-sm mb-1 mt-2 font-medium'>Student Lists</p>
                                            {userLists.map((res) => {
                                                const isSelected = selectedUserLists.some((selected) => selected.id === res.id);
                                                return (
                                                    <div
                                                        key={res.id}
                                                        className={`flex flex-row items-center gap-3 py-2 cursor-pointer ${!isSelected && 'hover:bg-slate-100'} rounded-xl px-2`}
                                                        onClick={() => this.toggleSelection('userLists', res)}
                                                    >
                                                        <div
                                                            className="w-8 h-8 flex flex-col items-center justify-center rounded-lg object-cover border border-slate-200">
                                                            <SvgList className={'w-5 h-5 text-slate-700'}/>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <p className="text-sm text-slate-800 line-clamp-2">{res.name}</p>
                                                            <p className="text-xs text-slate-500 line-clamp-2">
                                                                Student List - Created by {res.creator_name}
                                                            </p>
                                                        </div>
                                                        {renderSelectedIcon(isSelected)}
                                                    </div>
                                                );
                                            })}
                                            {this.state.userLists.length === 0 && (
                                                <div className='flex flex-col items-center mt-1'>
                                                    {/* <Button variant={'secondary'} size={'xs'}
                                                            onClick={() => this.toggleShowMore('showMoreLists')}>
                                                        {showMoreLists ? "Show Less" : "Show More"}
                                                    </Button> */}
                                                    <p className="text-sm text-slate-800 line-clamp-2">No student lists for this search</p>

                                                </div>
                                            )}
                                        </div>
                                        <HorizontalDivider className='bg-slate-200 my-3'/>
                                        <div>
                                            {/* Club Lists Section */}
                                            <p className='text-sm mb-1 mt-2 font-medium'>Club Lists</p>
                                            {clubLists.map((res) => {
                                                const isSelected = selectedClubLists.some((selected) => selected.id === res.id);
                                                return (
                                                    <div
                                                        key={res.id}
                                                        className={`flex flex-row items-center gap-3 py-2 cursor-pointer ${!isSelected && 'hover:bg-slate-100'} rounded-xl px-2`}
                                                        onClick={() => this.toggleSelection('clubLists', res)}
                                                    >
                                                        <div
                                                            className="w-8 h-8 flex flex-col items-center justify-center rounded-lg object-cover border border-slate-200">
                                                            <SvgList className={'w-5 h-5 text-slate-700'}/>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <p className="text-sm text-slate-800 line-clamp-2">{res.name}</p>
                                                            <p className="text-xs text-slate-500 line-clamp-2">
                                                                Club List - Created by {res.creator_name}
                                                            </p>
                                                        </div>
                                                        {renderSelectedIcon(isSelected)}
                                                    </div>
                                                );
                                            })}
                                            {this.state.clubLists.length === 0 && (
                                                <div className='flex flex-col items-center mt-1'>
                                                    {/* <Button variant={'secondary'} size={'xs'}
                                                            onClick={() => this.toggleShowMore('showMoreLists')}>
                                                        {showMoreLists ? "Show Less" : "Show More"}
                                                    </Button> */}
                                                    <p className="text-sm text-slate-800 line-clamp-2">No club lists for this search</p>

                                                </div>
                                            )}
                                        </div>
                                        <HorizontalDivider className='bg-slate-200 my-3'/>
                                        <div>
                                            <div className='flex flex-row items-center gap-1'>
                                                <p className='text-sm font-medium'>
                                                    RSVPs to specific events
                                                </p>
                                                {/*<SvgArrow className={'w-4 h-4'}/>*/}
                                            </div>
                                            <div>
                                                {events.map((event) => {
                                                    const isSelected = selectedEvents.some((selected) => selected.id === event.id);
                                                    return (
                                                        // <div
                                                        //     key={event.id}
                                                        //     className={`text-xs px-3 py-1 rounded-lg flex flex-row items-center cursor-pointer ${
                                                        //         isSelected ? 'bg-primary/30' : 'bg-slate-200 hover:bg-slate-300'
                                                        //     }`}
                                                        //     onClick={() => this.toggleSelection('events', event)}
                                                        // >
                                                        //     {event.name}
                                                        //     {renderSelectedIcon(isSelected, 'ml-2')}
                                                        // </div>
                                                        <div
                                                            key={event.id}
                                                            className={`flex flex-row items-center gap-3 py-2 cursor-pointer ${!isSelected && 'hover:bg-slate-100'} rounded-xl px-2`}
                                                            onClick={() => this.toggleSelection('events', event)}
                                                        >
                                                            <div
                                                                className="w-8 h-8 flex flex-col items-center justify-center rounded-lg object-cover border border-slate-200">
                                                                <SvgCalendar className={'w-5 h-5 text-slate-700'}/>
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <p className="text-sm text-slate-800 line-clamp-2">{event.name}</p>
                                                                <p className="text-xs text-slate-500 line-clamp-2">
                                                                    {event.location} - {DateTime.fromISO(event.event_date).toFormat("MMM d, yyyy")}
                                                                </p>
                                                            </div>
                                                            {renderSelectedIcon(isSelected)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {this.state.events.length === 0 && (
                                                <div className='flex flex-col items-center mt-1'>
                                                    <p className="text-sm text-slate-800 line-clamp-2">No events for this search</p>
                                                </div>
                                            )}
                                        </div>
                                        <HorizontalDivider className='bg-slate-200 my-3'/>
                                        <div className="mb-2">
                                            <div className='flex flex-row items-center gap-1 pt-1'>
                                                <p className='text-sm font-medium'>
                                                    Applicants of a specific jobs
                                                </p>
                                                {/*<SvgArrow className={'w-4 h-4'}/>*/}
                                            </div>
                                            <div>
                                                {jobs.map((job) => {
                                                    const isSelected = selectedJobs.some((selected) => selected.id === job.id);
                                                    return (
                                                        // <div
                                                        //     key={job.id}
                                                        //     className={`text-xs px-3 py-1 rounded-lg cursor-pointer flex flex-row items-center ${
                                                        //         isSelected ? 'bg-primary/30' : 'bg-slate-200 hover:bg-slate-300'
                                                        //     }`}
                                                        //     onClick={() => this.toggleSelection('jobs', job)}
                                                        // >
                                                        //     {job.name}
                                                        //     {renderSelectedIcon(isSelected, 'ml-2')}
                                                        // </div>
                                                        <div
                                                            key={job.id}
                                                            className={`flex flex-row items-center gap-3 py-2 cursor-pointer ${!isSelected && 'hover:bg-slate-100'} rounded-xl px-2`}
                                                            onClick={() => this.toggleSelection('jobs', job)}
                                                        >
                                                            <div
                                                                className="w-8 h-8 flex flex-col items-center justify-center rounded-lg object-cover border border-slate-200">
                                                                <SvgBriefcase className={'w-5 h-5 text-slate-700'}/>
                                                            </div>
                                                            <div className="flex flex-col">
                                                                <p className="text-sm text-slate-800 line-clamp-2">{job.name}</p>
                                                                <p className="text-xs text-slate-500 line-clamp-2">
                                                                    {job.type} - {job.industry}
                                                                </p>
                                                            </div>
                                                            {renderSelectedIcon(isSelected)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {this.state.jobs.length === 0 && (
                                                <div className='flex flex-col items-center mt-1'>
                                                    <p className="text-sm text-slate-800 line-clamp-2">No jobs for this search</p>
                                                </div>
                                            )}
                                        </div>
                                        {/* <HorizontalDivider className='bg-slate-200 my-3'/> */}
                                        {/* Students Section */}
                                        {/* <p className='text-sm mb-1 font-medium'>Students</p>
                                        {studentsToShow.map((student) => {
                                            const isSelected = selectedStudents.some((selected) => selected.id === student.id);
                                            return (
                                                <div
                                                    key={student.id}
                                                    className={`flex flex-row items-center gap-3 py-2 cursor-pointer rounded-xl px-2 ${isSelected ? '' : 'hover:bg-slate-100'}`}
                                                    onClick={() => this.toggleSelection('students', student)}
                                                >
                                                    <img
                                                        src={student?.profile_pic_url}
                                                        alt='profile pic'
                                                        className='w-8 h-8 rounded-full object-cover border border-slate-200'
                                                    />
                                                    <div className='flex flex-col'>
                                                        <p className='text-sm text-slate-800'>{student.first_name} {student.last_name}</p>
                                                        <p className='text-xs text-slate-500'>{student.college} - Class
                                                            of {student.grade}</p>
                                                    </div>
                                                    {renderSelectedIcon(isSelected)}
                                                </div>
                                            );
                                        })}
                                        {this.state.students.length > 2 && (
                                            <div className='flex flex-col items-center mt-1'>
                                                <Button variant={'secondary'} size={'xs'}
                                                        onClick={() => this.toggleShowMore('showMoreStudents')}>
                                                    {showMoreStudents ? "Show Less" : "Show More"}
                                                </Button>
                                            </div>
                                        )} */}

                                        {/* Clubs Section */}
                                        {/* <HorizontalDivider className='bg-slate-200 my-3'/>
                                        <p className='text-sm mb-1 mt-2 font-medium'>Clubs</p>
                                        {clubsToShow.map((res) => {
                                            const isSelected = selectedClubs.some((selected) => selected.id === res.id);
                                            return (
                                                <div
                                                    key={res.id}
                                                    className={`flex flex-row items-center gap-3 py-2 cursor-pointer ${!isSelected && 'hover:bg-slate-100'} rounded-xl px-2`}
                                                    onClick={() => this.toggleSelection('clubs', res)}
                                                >
                                                    <img
                                                        src={res?.logo_url}
                                                        alt={'club image'}
                                                        className="w-8 h-8 rounded-lg object-cover border border-slate-200"
                                                    />
                                                    <div className="flex flex-col">
                                                        <p className="text-sm text-slate-800 line-clamp-2">{res.name}</p>
                                                        <p className="text-xs text-slate-500 line-clamp-2">
                                                            {res.college} - {res.num_members_range} members
                                                        </p>
                                                    </div>
                                                    {renderSelectedIcon(isSelected)}
                                                </div>
                                            );
                                        })}
                                        {this.state.clubs.length > 2 && (
                                            <div className='flex flex-col items-center mt-1'>
                                                <Button variant={'secondary'} size={'xs'}
                                                        onClick={() => this.toggleShowMore('showMoreClubs')}>
                                                    {showMoreClubs ? "Show Less" : "Show More"}
                                                </Button>
                                            </div>
                                        )} */}

                                    </CollapseComponent>
                                </div>
                            </div>
                        </div>
                        <p className='text-slate-700 text-sm'>
                            From: <span
                            className='font-medium'>{this.props.recruiter.first_name}</span> {this.props.recruiter.last_name} ({this.props.recruiter.email})
                        </p>
                        {formattedRecipients && (
                            <p className="text-slate-700 text-sm mt-2">
                                {formattedRecipients}
                            </p>
                        )}
                        <WrappedTextInput
                            type="text"
                            outerLabel={'Subject'}
                            placeholder={'Add a subject'}
                            className='input-light px-3 w-full'
                            value={this.state.subject}
                            onChange={(e) => this.handleInputChange(e, 'subject')}
                        />
                        <div className='flex flex-col gap-1.5'>
                            <div className='text-xs font-medium text-slate-500'>
                                Body
                            </div>
                            <div>
                                <RichTextEditor
                                    initialHtml={this.state.body}
                                    editorState={this.state.editorState}
                                    setEditorState={this.setEditorState}
                                />
                            </div>
                        </div>
                    </div>
                    <ModalComponent
                        isOpen={this.state.successModalOpen}
                        backgroundColor={"white"}
                        header={"Email Sent!"}
                        headerToggle
                        size={"sm"}
                        toggle={this.closeAllModals}
                        FooterComponent={
                            <div className="flex flex-row w-full justify-center items-center gap-3">
                                <Button variant={"secondary"} onClick={this.closeAllModals}>
                                    Close
                                </Button>
                            </div>
                        }
                    >
                        <div className="hue-rotate-[120deg]">
                            <LottieWrapper
                                loop={false}
                                json={json}
                                width={100}
                                height={100}
                            />
                        </div>
                    </ModalComponent>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(RecruiterBulkEmailPage);
