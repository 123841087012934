import React, {createRef, PureComponent} from "react";
import SvgAddCircle from "../icons/SvgAddCircle";
import SvgInfo from "../icons/SvgInfo";
import SvgSearch from "../icons/SvgSearch";
import WrappedTextInput from "../atoms/WrappedTextInput";
import GetRosterHelpModal from "../atoms/modals/GetRosterHelpModal";
import AddJobModal from "../atoms/modals/AddJobModal";
import EditPositionModal from "../atoms/modals/EditPositionModal";
import PaginationComponent from "../atoms/PaginationComponent";
import {filteredPersonSearch, userClubSearch} from "../../utils/typesense";
import CreatePostModal from "../atoms/modals/CreatePostModal";
import ScrapedNetworkingPeek from "../networkingpage/ScrapedNetworkingPeek";
import {page_h_padding_class} from "../../utils/constants";
import AddRecentPlacementModal from "../atoms/modals/AddRecentPlacementModal";
import ClubFeedTab from "./tabs/ClubFeedTab";
import ClubMembersTab from "./tabs/ClubMembersTab";
import ClubAlumniTab from "./tabs/ClubAlumniTab";
import ClubAboutTab from "./tabs/ClubAboutTab";
import ClubAdminTab from "./tabs/ClubAdminTab";
import EditPostModal from "../atoms/modals/EditPostModal";
import InviteExecutivesCta from "../atoms/InviteExecutivesCta";

const START = 1;
const END = 20;

class ClubPageTableWidget extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            addClubJobModalOpen: false,
            editAlumniModalOpen: false,
            viewAlumniModalOpen: false,
            createPostModalOpen: false,
            recentPlacementModalOpen: false,
            networkingPeekOpen: false,
            selectedPerson: {},
            selectedPersonId: null,
            positionModalOpen: false,
            eventModalOpen: false,
            selectedEvent: null,
            grid: true,
            search: "",
            alumni: [],
            members: [],
            classYear: "",
            numAlumni: 0,
            numMembers: 0,
            start: START,
            end: END,
            page: 1,
            loading: false,

        }
        this.containerRef = createRef();
    }

    componentDidMount() {
        this.search();
        this.memberSearch();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.club.id !== this.props.club.id || this.props.activeTab !== prevProps.activeTab) {
            this.search();
            this.memberSearch();
        }
    }

    handleSearchChange = (event) => {
        const text = event.target.value;
        this.setState({search: text});

        this.search(text, this.state.classYear);
    }

    handleMemberSearchChange = (event) => {
        const text = event.target.value;
        this.setState({memberSearch: text});

        this.memberSearch(text, this.state.classYear);
    }

    handleGradChange = (event) => {
        const text = event.target.value;
        const year = String(text)

        if (year.length <= 4) this.setState({classYear: text});
        if (year.length === 4 || year.length === 2 || year.length === 0) {
            if (this.props.activeTab === "Alumni") this.search(this.state.search, year);
            else if (this.props.activeTab === "Members") this.memberSearch(this.state.search, year);
        }
    }


    search = (text="", class_year=null, page=1) => {
        this.setState({loading: true})
        filteredPersonSearch(text, [this.props.club.id], page, class_year).then(peopleResults => {
            this.setState({alumni: peopleResults.results,  numAlumni: peopleResults.total, loading: false, page});
        })
    }

    memberSearch = (text="", class_year=null, page=1) => {
        this.setState({loading: true})
        userClubSearch(text, this.props.club.id, class_year, null, page).then(results => {
            this.setState({members: results.results, numMembers: results.total, loading: false, page});
            if (text==="" && class_year===null && page===1) this.props.setNumMembers(results.total)
        })
    }

    setBounds = (start, end) => {
        if (this.props.activeTab === "Alumni") {
            if (start > this.state.end) {
                this.search(this.state.search, this.state.classYear, this.state.page+1);
            } else if (this.state.alumni.length) {
                this.search(this.state.search, this.state.classYear, this.state.page-1);
            }
        } else if (this.props.activeTab === "Members") {
            if (start > this.state.end) {
                this.memberSearch(this.state.search, this.state.classYear, this.state.page+1);
            } else if (this.state.alumni.length) {
                this.memberSearch(this.state.search, this.state.classYear, this.state.page-1);
            }
        }
        
        this.setState({start, end});
    }

    openAddClubJobModal = () => {
        this.setState({addClubJobModalOpen: true})
    }

    closeAddClubJobModal = () => {
        this.setState({addClubJobModalOpen: false})
    }

    openNetworkingPeek = (selectedPersonId, selectedPerson) => {
        this.setState({networkingPeekOpen: true, selectedPerson, selectedPersonId})
    }


    openPositionModal = (selectedPerson) => {
        this.setState({positionModalOpen: true, selectedPerson})
    }

    closePositionModal = () => {
        this.setState({positionModalOpen: false})
    }

    openEventPage = (selectedEvent) => {
        this.props.history.push(`/event/${selectedEvent.id}`);
    }

    openCreatePostModal = () => {
        this.setState({createPostModalOpen: true});
    }

    closeCreatePostModal = () => {
        this.setState({createPostModalOpen: false})
    }

    openRecentPlacementModal = () => {
        this.setState({recentPlacementModalOpen: true});
    }

    closeRecentPlacementModal = () => {
        this.setState({recentPlacementModalOpen: false})
    }

    openEditPostModal = (post) => {
        this.setState({currentPost: post, editPostModalOpen: true})
    }

    closeEditPostModal = () => {
        this.setState({editPostModalOpen: false})
    }

    render() {
        const user = this.props.user || {};
        const adminIds = user.admin.map(club => club.id);
        const club = this.props.club || {};
        const unclaimed = !club.admin.length && club.from_master_list;
        const TABS = ['Members', 'Alumni', 'Feed'];
        const members = this.state.members || [];
        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                <div
                    className={`flex flex-row justify-between border-b border-slate-200 bg-white ${page_h_padding_class}`}>
                    <div className='flex flex-col gap-2 mt-1'>
                        <div className='flex flex-row items-center gap-8'>
                            {TABS.map(tab =>
                                <div className={`${this.props.activeTab !== tab && 'cursor-pointer'}`} key={tab}>
                                    <div
                                        className={`flex flex-row items-center gap-1 mb-1 ${this.props.activeTab === tab ? 'primary' : 'text-neutral-400 hover:text-neutral-600'}`}>
                                        <h3
                                            onClick={() => this.props.changeTab(tab)}
                                            className={`mt-0 body1-bold`}>
                                            {tab}
                                        </h3>
                                    </div>
                                    <div
                                        className={`${this.props.activeTab === tab ? 'bg-primary' : 'bg-transparent'} w-full h-1 mt-2`}/>
                                </div>
                            )}
                        </div>
                        {this.props.activeTab === "Alumni" ?
                            <div className='flex flex-row items-center justify-between w-full mb-4'>
                                <div className='flex flex-row items-center gap-2 flex-wrap'>
                                    <WrappedTextInput
                                        type="text"
                                        placeholder={"Search alumni name, company name..."}
                                        className='pl-44 input-light body2 w-[240px] lg:w-[330px]'
                                        value={this.state.search}
                                        onChange={this.handleSearchChange}
                                        icon={<SvgSearch width={24} height={24} className={'absolute base-black-50'}
                                                         style={{left: 12, top: 10}}/>}
                                    />
                                    <WrappedTextInput
                                        type="number"
                                        placeholder={"Grad year..."}
                                        className='pl-3 input-light body2 w-full'
                                        value={this.state.classYear}
                                        onChange={this.handleGradChange}
                                    />
                                </div>
                            </div>
                            :
                            null}
                        {this.props.activeTab === "Members" ?
                            <div className='flex flex-row items-center justify-between w-full mb-4'>
                                <div className='flex flex-row items-center gap-2 flex-wrap'>
                                    <WrappedTextInput
                                        type="text"
                                        placeholder={"Search member name..."}
                                        className='pl-44 input-light body2 w-[240px] lg:w-[330px]'
                                        value={this.state.memberSearch}
                                        onChange={this.handleMemberSearchChange}
                                        icon={<SvgSearch width={24} height={24} className={'absolute base-black-50'}
                                                         style={{left: 12, top: 10}}/>}
                                    />
                                </div>
                            </div>
                            :
                            null}
                    </div>
                    <div className='flex flex-row gap-5 mb-2'>
                        {!this.props.loading && adminIds.includes(club.id) ?
                            <GetRosterHelpModal club={club}>
                                <button className='secondary-pill-sm'>
                                    <SvgInfo className={'w-4 h-4 mr-2'}/>
                                    <span className='body3-bold'>
                                    Get help
                                </span>
                                </button>
                            </GetRosterHelpModal>
                            :
                            null}
                    </div>
                </div>
                {this.props.activeTab === 'Members' &&
                    <ClubMembersTab
                        loading={this.props.loading}
                        members={members}
                        club={this.props.club}
                        leadership={this.props.leadership}
                        authUser={this.props.authUser}
                        user={this.props.user}
                        adminIds={[]}
                        />
                }
                {unclaimed && this.props.activeTab !== "Members" ?
                    <div>
                        <InviteExecutivesCta title="Invite the Executives" club={club}/>
                    </div>
                    :
                    <>
                        {this.props.activeTab === 'Feed' &&
                           <div className='max-w-[940px] w-full mx-auto overflow-y-scroll'>
                               <ClubFeedTab
                                   loading={this.props.loading}
                                   posts={this.props.posts}
                                   club={this.props.club}
                                   getClubInfo={this.props.getClubInfo}
                                   openEventPage={this.openEventPage}
                                   editPost={this.openEditPostModal}/>
                           </div>
                        }
                        {this.props.activeTab === 'About' &&
                            <ClubAboutTab
                                club={this.props.club}
                                loading={this.props.loading}
                                user={this.props.user}
                                getClubInfo={this.props.getClubInfo}
                                numMembers={this.state.numMembers}
                                openRecentPlacementModal={this.openRecentPlacementModal}
                            />
                        }
                        {this.props.activeTab === 'Admin' &&
                            <ClubAdminTab
                                club={this.props.club}
                                loading={this.props.loading}
                                user={this.props.user}
                                authUser={this.props.authUser}
                                statistics={this.props.statistics}
                                getClubInfo={this.props.getClubInfo}
                            />
                        }
                        {this.props.activeTab === 'Alumni' &&
                            <ClubAlumniTab
                                loading={this.props.loading}
                                alumni={this.state.alumni}
                                club={this.props.club}
                                isAdmin={false}
                                search={this.state.search}
                                classYear={this.state.classYear}
                                openPeek={this.openNetworkingPeek}
                                updateNetwork={this.props.updateNetwork}/>
                        }
                    </>
                }
                {(this.props.activeTab === "Members" || this.props.activeTab === 'Alumni') && (this.props.activeTab === "Members" ? this.state.numMembers > 0 : this.state.numAlumni > 0) && !this.state.search && !this.state.classYear ?
                    <PaginationComponent
                        className={'px-5 bg-white'}
                        numResults={this.props.activeTab === "Members" ? this.state.numMembers : this.state.numAlumni}
                        setBounds={this.setBounds} pageLength={20} loading={this.state.loading}/>
                    : null
                }
                <AddJobModal
                    isOpen={this.state.addClubJobModalOpen}
                    closeModal={this.closeAddClubJobModal}
                    club={club}
                    getClubInfo={this.props.getClubInfo}
                />
                <CreatePostModal
                    isOpen={this.state.createPostModalOpen}
                    closeModal={this.closeCreatePostModal}
                    club={club}
                    getClubInfo={this.props.getClubInfo}
                />
                <EditPostModal
                    isOpen={this.state.editPostModalOpen}
                    closeModal={this.closeEditPostModal}
                    club={club}
                    post={this.state.currentPost}
                    getClubInfo={this.props.getClubInfo}
                />
                <AddRecentPlacementModal
                    isOpen={this.state.recentPlacementModalOpen}
                    closeModal={this.closeRecentPlacementModal}
                    club={club}
                    getClubInfo={this.props.getClubInfo}
                />
                <EditPositionModal
                    isOpen={this.state.positionModalOpen}
                    closeModal={this.closePositionModal}
                    selectedPerson={this.state.selectedPerson}
                    getClubInfo={this.props.getClubInfo}
                    club={club}
                />
                <ScrapedNetworkingPeek
                    open={this.state.networkingPeekOpen}
                    network={this.props.networks[this.state.selectedPersonId]}
                    isSaved={(this.state.selectedPersonId in this.props.networks)}
                    person={this.state.selectedPerson}
                    close={() => this.setState({networkingPeekOpen: false})}
                    deleteNetwork={this.props.deleteNetwork}
                    updateNetwork={this.props.updateNetwork}
                    adminPortal
                    clubPage
                />
            </div>
        )
    }
}

export default ClubPageTableWidget
