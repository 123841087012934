import React, { PureComponent } from "react";
import { numToMonth } from "../../utils/constants";
import SvgEdit from "../icons/SvgEdit";
import DropdownComponent from "../atoms/DropdownComponent";
import SvgRemove from "../icons/SvgRemove";
import SvgMoreVertical from "../icons/SvgMoreVertical";

class ExperienceListItem extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            finalLogoUrl: this.getInitialLogoUrl(props.experience),
            imageError: false
        };
    }

    getInitialLogoUrl = (experience) => {
        let logoUrl = experience.logo_url ? experience.logo_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fcover_image%2FdefaultCompanyLogo.jpg?alt=media";

        if (logoUrl.includes("replaceme:")) {
            logoUrl = logoUrl.replace("replaceme:", "");
        }

        return logoUrl;
    }

    handleImageError = () => {
        this.setState({
            finalLogoUrl: "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fcover_image%2FdefaultCompanyLogo.jpg?alt=media",
            imageError: true
        });
    }

    render() {
        const experience = this.props.experience || {};
        const startsAt = experience.starts_at || {};
        const endsAt = experience.ends_at || {};
        const ending = Object.keys(endsAt).length === 0 ? "Present" : numToMonth[endsAt.month] + " " + endsAt.year;

        return (
            <>
                <div className='flex flex-row items-center justify-between py-4'>
                    <div className='flex flex-row'>
                        <img
                            src={this.state.finalLogoUrl}
                            onError={this.handleImageError}
                            className={`${this.props.sm ? 'w-12 h-12' : 'w-16 h-16'} rounded-md border-neutral-200 border bg-white object-contain`}
                        />
                        <div className='ml-3 flex flex-col flex-1'>
                            <p className={`${this.props.sm ? 'body2-bold' : 'body1-bold'} text-neutral-900`}>
                                {experience?.title}
                            </p>
                            <p className={`${this.props.sm ? 'body3' : 'body2'}  text-neutral-500 ${this.props.sm ? 'mb-0.5' : 'mb-2'} mt-[-4px]`}>
                                {experience?.company}&nbsp;&nbsp;
                            </p>
                            <p className={`${this.props.sm ? 'body3' : 'body2'} text-neutral-400`}>
                                {numToMonth[startsAt.month]} {startsAt.year} - {ending}&nbsp;&nbsp;
                            </p>
                            <p className='body3 text-neutral-400'>
                                {experience.location}
                            </p>
                        </div>
                    </div>
                    {this.props.studentProfilePage || this.props.recruiter ? null :
                        <DropdownComponent
                            ToggleComponent={
                                <div
                                    className='flex flex-col items-center mt-[8px] justify-center text-slate-500 hover:text-slate-800 cursor-pointer'>
                                    <SvgMoreVertical className={'w-4 h-4'}/>
                                </div>
                            }
                            value={null}
                            isControlled={false}
                            setValue={() => null}
                            options={
                                [
                                    {
                                        title: 'Edit experience',
                                        icon: SvgEdit,
                                        onClick: () => this.props.openEditExperienceModal('edit', this.props.i),
                                    },
                                    {
                                        title: 'Remove experience',
                                        icon: SvgRemove,
                                        onClick: () => this.props.removeExperience(this.props.i),
                                        color: 'red'
                                    },
                                ]
                            }
                            direction="bottom"
                            light
                        />
                    }
                </div>
                {!this.props.last && <div className='border-b border-neutral-100 w-full'/>}
            </>
        );
    }
}

export default ExperienceListItem;
