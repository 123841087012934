import React, {PureComponent} from "react";
import SvgMagic from "../icons/SvgMagic";
import SvgDocument from "../icons/SvgDocument";
import SvgSeeking from "../icons/SvgSeeking";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import ExperienceListItem from "./ExperienceListItem";
import EducationListItem from "./EducationListItem";
import SvgAddCircle from "../icons/SvgAddCircle";

class ProfilePageExperienceWidget extends PureComponent {

    render() {
        return (
            <div className={!this.props.inPeek && 'card'}>
                {!this.props.inPeek &&
                    <div className='flex flex-row items-center justify-between'>
                        <h3 className={`mt-0 ${this.props.inPeek ? 'body1-bold' : 'title2'} text-neutral-900 mb-2`}>
                            Education
                        </h3>
                        {this.props.studentProfilePage || this.props.recruiter ? null :
                            <button className='secondary-pill-sm'>
                                <SvgAddCircle className={'w-4 h-4 mr-2'}/>
                                <span className='body3-bold' onClick={() => this.props.openEditEducationModal('add')}>
                                            Add education
                                        </span>
                            </button>
                        }
                    </div>
                }
                {this.props.education?.map((education, i) =>
                    <EducationListItem
                        key={education}
                        education={education}
                        last={i === this.props.education.length - 1}
                        sm={this.props.inPeek}
                        removeEducation={this.props.removeEducation}
                        openEditEducationModal={this.props.openEditEducationModal}
                        i={i}
                        studentProfilePage={this.props.studentProfilePage}
                        recruiter={this.props.recruiter}/>
                )}
            </div>
        )
    }
}

export default ProfilePageExperienceWidget
