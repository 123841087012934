import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import CreateJobDetailsStep from "./create-job/CreateJobDetailsStep";
import ProgressStepper from "../atoms/ProgressStepper";
import CreateJobDateLocationStep from "./create-job/CreateJobDateLocationStep";
import CreateJobQualificationStep from "./create-job/CreateJobQualificationStep";
import {editJob} from "../../api/recruiter/jobs";
import SectionTabs from "../../components/atoms/SectionTabs";

const ZERO_STATE = {
    activeTab: 'Job Details',
    jobTitle: '',
    jobDescription: '',
    industry: null,
    jobType: null,
    workEnvironment: "On-site",
    city: '',
    state: '',
    directOnRecruit: true,
    postToday: true,
    postOn: null,
    hasDeadline: false,
    deadline: null,
    classYears: [],
    qualifiedOnly: false,
    majors: [],
    clubs: [],
    schools: [],
    openings: null,
    gender: null,
    race: [],
    veteran: false,
    lgbtq: false,
    disabled: false,
    authorized: false,
};

class RecruiterEditJobModal extends Component {
    state = ZERO_STATE;

    submit = async () => {
        const params = {
            name: this.state.jobTitle,
            description: this.state.jobDescription,
            type: this.state.jobType,
            city: !!this.state.city ? [this.state.city] : [],
            state: !!this.state.state ? [this.state.state] : [],
            work_model: this.state.workEnvironment,
            class_years: this.state.classYears,
            link: this.state.url,
            industry: this.state.industry,
            apply_direct: this.state.directOnRecruit,
            deadline: this.state.hasDeadline ? this.state.deadline : "",
            schools: this.state.schools,
            clubs: this.state.clubs.map(club => club.id),
            majors: this.state.majors,
            gpa: this.state.gpa,
            qualified_only: this.state.qualifiedOnly,
            openings: this.state.openings,
            diversity: {
                gender: this.state.gender,
                race: this.state.race,
                veteran: this.state.veteran,
                lgbtq: this.state.lgbtq,
                disabled: this.state.disabled,
                authorized: this.state.authorized,
            }
        }
        await editJob(this.props.authUser, this.props.job.id, params);
        window.location.reload();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.job !== this.props.job) {
            this.setInfo();
        }
    }

    componentDidMount = () => {
        this.setInfo();
    }

    setInfo = () => {
        const job = this.props.job || {};
        this.setState({
            jobTitle: job.name,
            jobDescription: job.description,
            jobType: job.type,
            city: !!job.city ? job.city[0] : "",
            state: !!job.state ? job.state[0] : "",
            workEnvironment: job.work_model,
            classYears: job.class_years,
            url: job.link,
            industry: job.industry,
            directOnRecruit: job.apply_direct,
            hasDeadline: !!job.deadline,
            deadline: !!job.deadline ? new Date(job.deadline) : null,
            schools: job.schools,
            clubs: job.clubs,
            majors: job.majors,
            gpa: job.gpa,
            qualifiedOnly: job.qualified_only,
            openings: job.openings,
            gender: job.diversity?.gender || null,
            race: job.diversity?.race || [],
            veteran: job.diversity?.veteran || false,
            lgbtq: job.diversity?.lgbtq || false,
            disabled: job.diversity?.disabled || false,
            authorized: job.diversity?.authorized || false,
        })
    }

    nextStep = () => {
        if (this.state.step === 3) {
            this.submit();
            return;
        }
        this.setState({step: this.state.step + 1})
    }

    prevStep = () => {
        if (this.state.step === 1) {
            this.props.closeModal();
            return;
        }
        this.setState({step: this.state.step - 1})
    }


    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    handleWorkEnvironmentChange = (event) => {
        this.setState({
            workEnvironment: event.target.value,
        });
    };

    handleQualifiedOnlyChange = (boolean) => {
        this.setState({
            qualifiedOnly: boolean,
        });
    };

    toggleInput = (stateKey) => {
        this.setState((prevState) => ({
            [stateKey]: !prevState[stateKey],
        }));
    }

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };

    updateClubs = (club) => {
        if (!club) {
            this.setState({clubs: []})
            return
        }

        let newClubs = [...this.state.clubs];
        if (this.state.clubs.includes(club)) {
            const index = this.state.clubs.indexOf(club);
            newClubs.splice(index, 1);
        } else {
            newClubs.push(club);
        }
        this.setState({clubs: newClubs});
    }

    updateSchools = (school) => {
        if (!school) {
            this.setState({schools: []})
            return
        }

        let newSchools = [...this.state.schools];
        if (this.state.schools.includes(school)) {
            const index = this.state.schools.indexOf(school);
            newSchools.splice(index, 1);
        } else {
            newSchools.push(school);
        }
        this.setState({schools: newSchools});
    }

    updateClassYears = (year) => {
        if (!year) {
            this.setState({classYears: []})
            return
        }

        let newClassYears = [...this.state.classYears];
        if (this.state.classYears.includes(year)) {
            const index = this.state.classYears.indexOf(year);
            newClassYears.splice(index, 1);
        } else {
            newClassYears.push(year);
        }
        this.setState({classYears: newClassYears});
    }

    updateMajors = (major) => {
        if (!major) {
            this.setState({major: []})
            return
        }
        let newMajor = [...this.state.majors];
        if (this.state.majors.includes(major)) {
            const index = this.state.majors.indexOf(major);
            newMajor.splice(index, 1);
        } else {
            newMajor.push(major);
        }
        this.setState({majors: newMajor});
    }
    updateGender = (gender) => {
        this.setState({gender})
    }

    updateRace = (race) => {
        if (!race) {
            this.setState({race: []})
            return
        }

        let newRace = [...this.state.race];
        if (this.state.race.includes(race)) {
            const index = this.state.race.indexOf(race);
            newRace.splice(index, 1);
        } else {
            newRace.push(race);
        }
        this.setState({race: newRace});
    }

    handleQualificationCheckbox = (inputName) => {
        this.setState({[inputName]: !this.state[inputName]});
    }


    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    render() {
        const numSteps = 3
        const TABS = ['Job Details', 'Date and Location', 'Candidate Qualification'];
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit This Job'}
                headerToggle
                size={'lg'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div onClick={this.props.closeModal} className='flex flex-row items-center gap-3'>
                        <div className='secondary-button body1-bold'>
                            Cancel
                        </div>
                        <div onClick={this.submit} className='primary-button body1-bold'>
                            Save Changes
                        </div>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div id={'add-joblisting-modal'}>
                    <div className='mb-5'>
                        <SectionTabs
                            activeTab={this.state.activeTab}
                            changeTab={this.changeTab}
                            tabs={TABS}
                        />
                    </div>
                    {this.state.activeTab === TABS[0] &&
                        <CreateJobDetailsStep
                            hideTutorial
                            jobTitle={this.state.jobTitle}
                            jobDescription={this.state.jobDescription}
                            jobType={this.state.jobType}
                            industry={this.state.industry}
                            openings={this.state.openings}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            constants={this.props.constants}
                        />
                    }
                    {this.state.activeTab === TABS[1] &&
                        <CreateJobDateLocationStep
                            hideTutorial
                            jobTitle={this.state.jobTitle}
                            jobDescription={this.state.jobDescription}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            workEnvironment={this.state.workEnvironment}
                            city={this.state.city}
                            state={this.state.state}
                            toggleInput={this.toggleInput}
                            directOnRecruit={this.state.directOnRecruit}
                            postToday={this.state.postToday}
                            postOn={this.state.postOn}
                            hasDeadline={this.state.hasDeadline}
                            deadline={this.state.deadline}
                            handleDateChange={this.handleDateChange}
                            handleWorkEnvironmentChange={this.handleWorkEnvironmentChange}
                        />
                    }
                    {this.state.activeTab === TABS[2] &&
                        <CreateJobQualificationStep
                            hideTutorial
                            majors={this.state.majors}
                            updateMajors={this.updateMajors}
                            handleSelectChange={this.handleSelectChange}
                            toggleInput={this.toggleInput}
                            qualifiedOnly={this.state.qualifiedOnly}
                            handleQualifiedOnlyChange={this.handleQualifiedOnlyChange}
                            handleInputChange={this.handleInputChange}
                            clubs={this.state.clubs}
                            gpa={this.state.gpa}
                            schools={this.state.schools}
                            updateClubs={this.updateClubs}
                            updateSchools={this.updateSchools}
                            recruiter
                            classYears={this.state.classYears}
                            updateClassYears={this.updateClassYears}
                            gender={this.state.gender}
                            race={this.state.race}
                            veteran={this.state.veteran}
                            lgbtq={this.state.lgbtq}
                            disabled={this.state.disabled}
                            authorized={this.state.authorized}
                            handleQualificationCheckbox={this.handleQualificationCheckbox}
                            updateRace={this.updateRace}
                            updateGender={this.updateGender}
                        />
                    }
                </div>
            </ModalComponent>
        )
    }
}

export default RecruiterEditJobModal
