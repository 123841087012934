import React from 'react';

function InputWrapper({ type, name, placeholder, value, onChange, label , className, disabled, inputClassName}) {
    return (
        <div className={`flex flex-col gap-1 ${className}`}>
            {label && <label className='text-sm text-slate-500 font-semibold'>{label}</label>}
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
                className={`input-wrapper ${inputClassName}`}
            />
        </div>
    );
}

export default InputWrapper;
