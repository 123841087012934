import React, {PureComponent} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import SvgClose from "../icons/SvgClose";
import JobBoardLoading from "../loading/JobBoardLoading";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import CompanyEventItem from "../jobboard/CompanyEventItem";
import {searchEvents} from "../../utils/typesense";
import {NUM_EVENTS_SHOWN} from "../../utils/constants";
import EventPeek from './EventPeek';
import {Button} from "../atoms/Button";
import FadeInOnScroll from "../atoms/FadeInOnScroll";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
    };
}

class DiscoverEvents extends PureComponent {
    constructor(props) {
        super(props);
        this.headingRef = React.createRef(); // Create a ref to access the component's DOM node
        this.state = {
            listingPreview: {},
            search: '',
            companyId: null,
            companyName: null,
            events: [],
            originalEvents: [],
            eventsLoading: false,
            moreEventsLoading: false,
            listingPreviewOpen: false,
            grid: false,
            page: 1,
            hideMoreEvents: false,
            industry: [],
            eventModalOpen: false,
            selectedEvent: null
        };
    }

    openListingPreview = (listing) => {
        this.setState({listingPreview: listing, listingPreviewOpen: true, selectedEvent: listing})
        // this.props.history.push(`/event/${listing?.id}`);
    }

    closeListingPreview = () => {
        this.setState({listingPreviewOpen: false})
    }

    handleSearchChange = (event) => {
        const text = event.target.value;
        this.setState({search: text});

        this.search(text);
    }


    search = (text, companyId = this.state.companyId) => {
        searchEvents(text, NUM_EVENTS_SHOWN, 1, companyId, false).then(results => {
            this.setState({events: results, loading: false});
        })
    }

    getNextEventInfo = async () => {
        this.setState({moreEventsLoading: true});
        const newEvents = await searchEvents(this.state.search, NUM_EVENTS_SHOWN, this.state.page + 1);
        this.setState({
            events: [...this.state.events, ...newEvents],
            moreEventsLoading: false,
            page: this.state.page + 1,
            hideMoreEvents: newEvents.length < NUM_EVENTS_SHOWN,
        });
    }

    componentDidMount = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const companyId = queryParams.get("companyId")
        const companyName = queryParams.get("companyName")
        if (!!companyId) {
            this.setState({companyId, companyName})
            this.search("", companyId);
        } else {
            this.search("");
        }
    }

    clearFilters = () => {
        this.setState({
            page: 1,
            companyId: null,
            companyName: null,
        });
        this.search("")
    }

    openEventPage = (selectedEvent) => {
        this.props.history.push(`/event/${selectedEvent.id}`);
    }

    toggleGridView = (type) => {
        this.setState({grid: type === 'grid'})
    }

    setCompany = (result) => {
        if (!result.id) {
            this.setState({page: 1})
        }
        this.setState({
            companyId: result.id,
            companyName: result.name,
        })
        this.search(this.state.search, result.id);
    }


    render() {
        const clearFiltersVisible = this.state.location || this.state.industry?.length >= 1 || this.state.companyId;
        return (
            <>
                <PageHeaderWrapper
                    className={'pt-2'}
                    // search={{
                    //     placeholder: 'Search events...',
                    //     value: this.state.search,
                    //     onChange: this.handleSearchChange,
                    // }}
                    setCompany={this.setCompany}
                    company={{id: this.state.companyId, name: this.state.companyName}}
                    clearFilters={clearFiltersVisible &&
                        <div
                            onClick={this.clearFilters}
                            className='body2 base-white-30 hover:text-neutral-50 flex flex-row items-center ml-3 cursor-pointer'>
                            <SvgClose className={'w-5 h-5 mr-1'}/> Clear
                        </div>

                    }
                />
                <div className='overflow-hidden flex-1'>
                    <div className={'overflow-y-scroll hide-scrollbar w-full flex-1 pb-5 px-5'}>
                        {this.state.eventsLoading ?
                            <JobBoardLoading/>
                            :
                            this.state.events.length === 0 ?
                                <div className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                    <SvgSearch/>
                                    <p className='body2 mt-2 mb-6'>
                                        No events found...
                                    </p>
                                </div>
                                :
                                <div
                                    className={`flex flex-col gap-3 ${this.state.grid && 'grid grid-cols-3 xl:grid-cols-4 gap-4'}`}>
                                    {this.state.events.map((item, index) =>
                                        <FadeInOnScroll key={item.id} triggerImmediately
                                                        delay={(index * 50) + 10}>
                                            <CompanyEventItem key={item.id} item={item} list={!this.state.grid}
                                                              openEventPage={this.openListingPreview}/>
                                        </FadeInOnScroll>
                                    )}
                                </div>
                        }

                        {(!this.state.hideMoreEvents && (!this.state.eventsLoading && this.state.events.length !== 0)) &&
                            <div className='flex flex-row items-center justify-center py-8 body2-bold'
                                 onClick={this.getNextEventInfo}>
                                <Button loading={this.state.moreEventsLoading} variant={'ghost'}>
                                    Load more events
                                </Button>
                            </div>
                        }
                    </div>
                    <EventPeek listing={this.state.listingPreview}
                               open={this.state.listingPreviewOpen}
                               close={this.closeListingPreview}
                               authUser={this.props.authUser} event={this.state.selectedEvent}/>
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps)(DiscoverEvents);
