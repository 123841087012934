import React, {PureComponent, createContext} from "react";
import {firebaseAuth} from "../api/firebase";
import {analytics} from "../utils/june";
import {getUser} from '../api/student/users';

export const UserContext = createContext({user: null});

class UserProvider extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            user: 0,
        }
    }

    componentDidMount() {
        firebaseAuth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                userAuth.getIdTokenResult().then(async idTokenResult => {
                    if (userAuth.userType === "student") {
                        const profile = (await getUser(userAuth, userAuth.uid))?.user;
                        analytics.identify(userAuth.uid, {
                            firstName: this.props.userData?.first_name,
                            lastName: this.props.userData?.last_name,
                            name: `${this.props.userData?.first_name} ${this.props.userData?.last_name}`,
                            email: userAuth.email,
                            clubs: profile?.clubs.map(club => ({
                                name: club.name,
                                isExec: Object.keys(club.leadership).includes(userAuth.uid),
                                isPointOfContact: club?.points_of_contact.map(poc => poc.email).includes(userAuth.email),
                            })),
                            classYear: profile?.grade || "",
                            school: profile?.college || "",
                            major: profile?.major
                        });
                        analytics.track('Signed In',
                            {browser: 'chrome'});
                    }
                   
                    const claims = idTokenResult.claims;
                    const userType = !!claims.recruiter ? "recruiter" : "student";
                    userAuth.userType = userType;
                    this.setState({user: userAuth})
                })
            } else {
                this.setState({user: null})
            }
        })
    }

    render() {
        return (
            <UserContext.Provider value={this.state.user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default UserProvider;
