import React, {Component} from "react";
import Tooltip from "../components/atoms/modals/Tooltip";
import {truncateString} from "../utils/strings";
import {connect} from "react-redux";
import SvgArrow from "../components/icons/SvgArrow";
import {withRouter} from "react-router-dom";
import SchoolLogo from "./atoms/SchoolLogo";
import ClubLogo from "./atoms/ClubLogo";

function mapDispatchToProps(dispatch) {
    return {
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        setStudentPeekStudent: (user) => {
            dispatch({
                type: "SET_STUDENT_PEEK_STUDENT",
                user,
            })
        },
    }
}

class RecruiterDashboardTableItem extends Component {
    state = {
        profileError: false,
    }

    openStudentPeek = () => {
        this.props.toggleStudentPeek()
        this.props.setStudentPeekStudent(this.props.item)
    }

    openStudentPage = (profileId) => {
        this.props.history.push(`/profile/${profileId}`);
    }

    render() {
        const columns = this.props.columns;
        const user = this.props.item;
        const clubs = user.clubs || [];
        const education = user.education || [];
        return (
            <div
                onClick={() => this.openStudentPage(user.id)}
                className='flex flex-row items-center px-2 py-2 mx-[-8px] cursor-pointer hover:bg-slate-100 rounded-lg text-slate-500 gap-3'>
                <div
                    className={'flex flex-row items-center gap-2'}
                    style={{flex: columns[0].flex}}>
                    <img onError={() => this.setState({profileError: true})}
                         src={!!user.profile_pic_url && !this.state.profileError ? user.profile_pic_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8"}
                         className='w-12 w-12 aspect-square object-cover rounded-full border border-slate-200'/>
                    <div className={'flex flex-col gap-0.5'}>
                        <p className=' text-slate-800 font-semibold text-md'>
                            {user.first_name} {user.last_name}
                        </p>
                        {user.tag &&
                            <div
                                className={`text-sm text-${user.tag.color}-500 bg-${user.tag.color}-100 px-2 rounded-full`}>
                                {user.tag.label}
                            </div>
                        }
                    </div>
                </div>
                <div style={{flex: columns[1].flex}} className='text-primary font-semibold'>
                    <div className='flex flex-1 flex-row items-center hover:underline mr-3'>
                        <p className='text-sm'>
                            {user.event_name || user.position_name}
                            <SvgArrow className={'w-3.5 h-3.5 mb-0.5 ml-0.5  inline'}/>
                        </p>
                    </div>
                </div>
                <div style={{flex: columns[2].flex}}>
                    {education.length ?
                        <Tooltip
                            toggleComponent={
                                // <img src={education[0].logo_url}
                                //      className='w-12 w-12 rounded-full border border-slate-200 object-contain'/>
                                <SchoolLogo image={education[0].logo_url}
                                className={'border border-slate-200'}
                                />
                            }
                            label={!!education[0] ? truncateString(education[0].school, 30) : "College"}
                        />
                        : null}
                </div>
                <div style={{flex: columns[3].flex}} className='flex flex-row items-center'>
                    {clubs.length === 0 ?
                        <div
                            className='w-10 w-10 text-xs text-slate-300 text-center flex flex-col items-center justify-center aspect-square border-slate-100 bg-slate-50 border rounded-xl'
                        >
                            <p>
                                N/A
                            </p>
                        </div>
                        :
                        <div className='flex flex-row items-center relative'>
                            {clubs.slice(0, 2).map((club, index) =>
                                <ClubLogo clubId={club} className={`${index !== 0 && '-ml-5'} border border-slate-200`}/>
                            )}
                            {clubs.length > 2 &&
                                <div
                                    className='absolute bg-primary bottom-[-4px] right-[-4px] rounded-full px-1 cursor-pointer'>
                                    <p className='text-xs font-bold text-white'>
                                        +{clubs.length - 2}
                                    </p>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div style={{flex: columns[4].flex}}>
                    <p className='text-sm'>
                        {user.major.join(', ')}
                    </p>
                </div>
                <div style={{flex: columns[5].flex}}>
                    <p className='text-sm'>
                        {user.gpa}
                    </p>
                </div>
                <div style={{flex: columns[6].flex}}>
                    <p className='text-sm'>
                        {user.grade}
                    </p>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(withRouter(RecruiterDashboardTableItem));
